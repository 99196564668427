<template>
  <div class="data-table scrollbar">
    <v-data-table
      :headers="headers"
      :items="allInstances"
      :items-per-page="-1"
      fixed-header
      density="comfortable"
    >
      <template #headers="{ columns, getSortIcon, toggleSort }">
        <tr>
          <template v-for="column in columns" :key="column.key">
            <th v-if="column.key !== 'select'" class="th2">
              <template v-if="sortableHeaders.includes(column.key)">
                <span class="mr-2 cursor-pointer" @click="() => toggleSort(column)">{{ column.title }} <v-icon size="16px" :icon="getSortIcon(column)" /></span>
              </template>
              <template v-else>
                <span class="mr-2 cursor-pointer">{{ column.title }}</span>
              </template>
            </th>
            <th v-else class="th2">
              <div class="select-all checkbox">
                <v-checkbox
                  v-model="selectAllInstances"
                  :hide-details="true"
                  density="compact"
                />
              </div>
            </th>
          </template>
        </tr>
      </template>

      <template v-for="(header, i) in headers" :key="i" #[`item.${header.key}`]="{ item }">
        <template v-if="header.key ==='launch'">
          {{ formatTime(item[header.key]) }}
        </template>
        <template v-else-if="header.key ==='select'">
          <div class="select-all checkbox">
            <v-checkbox
              v-model="selectedInstances"
              :hide-details="true"
              density="compact"
              :value="item"
            />
          </div>
        </template>
        <template v-else>
          {{ item[header.key] }}
        </template>
      </template>
      <template #bottom />
    </v-data-table>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

const emit = defineEmits([
  'update:total-instance-count',
  'update:selected-instances',
]);

const dataConnect = new DatastoreConnect();
const headers = ref([
  {
    title: 'selection', key: 'select', width: '1%', align: 'start',
  },
  {
    title: 'Name', key: 'name', width: '39%', align: 'start',
  },
  {
    title: 'IP Address', key: 'ip', width: '15%', align: 'start',
  },
  {
    title: 'Type', key: 'type', width: '15%', align: 'start',
  },
  {
    title: 'Status', key: 'state', width: '15%', align: 'start',
  },
  {
    title: 'Start Date', key: 'launch', width: '15%', align: 'start',
  },
]);
const sortableHeaders = ref(['launch', 'state', 'name']);
const allInstances = ref([]);
const loading = ref(false);
const selectedInstances = ref([]);
const selectAllInstances = ref(false);

async function getInstances() {
  loading.value = true;
  selectedInstances.value = [];
  await dataConnect.listCloudInstances({ filter: "" })
    .then((data) => {
      if (data.result && !data.error) {
        emit('update:total-instance-count', data.result.length);
        allInstances.value = data.result;
      }
    })
    .catch((error) => {
      console.log(error);
      return '';
    });
  loading.value = false;
}

function formatTime(str) {
  return `${new Date(str).toLocaleTimeString('default', {
    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true,
  })}`;
}

watch(selectAllInstances, () => {
  if (selectAllInstances.value) {
    selectedInstances.value = allInstances.value;
  } else {
    selectedInstances.value = [];
  }
});

watch(selectedInstances, (newInstances) => {
  emit('update:selected-instances', newInstances);
}, { deep: true });

onBeforeMount(() => {
  getInstances();
});

defineExpose({
  getInstances,
});

</script>

<style lang="scss" scoped>
.data-table {
    width: calc(100% - 40px);
    height: 100%;
    padding: 0 15px 15px 15px;
    border-radius: 8px;
    margin: 0 20px 20px 20px;
    overflow-y: auto;
}

:deep(.v-table) {
  background: transparent;
  border-radius: 0;
}

.th2 {
  background: transparent !important;
  span {
    font-size: 1.0rem;
    font-weight: 600;
  }
}

.select-all {
  display: flex;
  flex-direction: row;
  width: 15px;
}
</style>
