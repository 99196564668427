<template>
  <div id="aigt3dSidebar" class="aigt-3d-sidebar">
    <!-- Server -->
    <div class="aigt-3d-sidebar__section">
      <h2 class="aigt-3d-sidebar__title">Server</h2>
      <div v-if="fetchingCloudInstances" id="loading-servers" class="servers__loading">
        <div class="loader-ring"><div /><div /><div /><div /></div>
        <span>Loading Servers</span>
      </div>
      <div v-if="servers && servers.length === 0" class="servers__not-found">
        <span>No AIGT Servers Found</span>
      </div>
      <div v-if="servers && servers.length > 0" id="servers" class="form__control-group">
        <select
          id="servers-select"
          v-model="aigtServer"
          class="select"
          required
        >
          <option :key="'servers-null'" :value="''">Select Server</option>
          <option v-for="option in servers" :key="`servers-${option.id}`" :value="option.ip">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>

    <template v-if="aigtServer">
      <!-- Generate -->
      <div class="aigt-3d-sidebar__section">
        <h2 class="aigt-3d-sidebar__title">3D Annotations</h2>
        <button
          class="button button-sm aigt-3d-sidebar__button"
          :class="{'button-spinner': isGenerating3dAnnotations}"
          :disabled="isGenerating3dAnnotations || !currentAnimationSample.imageObj.id"
          :title="`Generate 3D Annotations`"
          @click="handleGenerate3dAnnotations"
        >
          Generate 3D Annotations
        </button>
      </div>

      <!-- Finish -->
      <div class="aigt-3d-sidebar__section">
        <h2 class="aigt-3d-sidebar__title">Finish</h2>
        <button
          class="button button-sm aigt-3d-sidebar__button"
          :class="{'button-spinner': isSaving3dAnnotations}"
          :disabled="isSaving3dAnnotations || aigt3dAnnotations.length <= 0"
          :title="`Save Annotations`"
          @click="handleSaveAIGT3DAnnotations"
        >
          Save Annotations
        </button>
      </div>
    </template>
  </div>
</template>

<script setup>
import {
  ref, watch, computed, onMounted, onUnmounted,
} from 'vue';
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import { useAIGTStore } from '@/stores/useAIGTStore.js';
import { storeToRefs } from 'pinia';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import { useEditorControlsStore } from '@/stores/useEditorControlsStore.js';
import { v4 as uuidv4 } from 'uuid';
import { useEditorStore } from '@/stores/useEditorStore.js';

// Props
const props = defineProps({
  destinationAnnotationSet: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits([]);

const aigtStore = useAIGTStore();
const {
  aigtServer,
  isGenerating3dAnnotations,
  isSaving3dAnnotations,
  aigt3dAnnotations,
} = storeToRefs(aigtStore);

const visualizationStore = useViewerVisualizationsStore();
const {
  animationImageCache,
  currentAnimationSample,
  samples,
  frame,
} = storeToRefs(visualizationStore);
const { addNewAnnotationsArrayToCache } = visualizationStore;

const editorStore = useEditorStore();
const {
  labels,
} = storeToRefs(editorStore);

// const framePcdAnnotations = computed(() => internalAnnotations.value.filter((anno) => anno.type === "pcd"));
// const frameDepthMapAnnotations = computed(() => internalAnnotations.value.filter((anno) => anno.type === "depthmap"));

const { selectedLabel } = storeToRefs(useEditorControlsStore());

const servers = ref(null);
const fetchingCloudInstances = ref(false);
onMounted(async () => {
  servers.value = await listCloudInstances();
  if (servers.value.length > 0) {
    aigtServer.value = servers.value[0].ip;
  }
});

onUnmounted(async () => {

});

async function listCloudInstances() {
  fetchingCloudInstances.value = true;
  const dataConnect = new DatastoreConnect();
  return dataConnect.listCloudInstances({ filter: "" })
    .then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error);
      }
      fetchingCloudInstances.value = false;
      return resp.result;
    })
    .catch((error) => {
      fetchingCloudInstances.value = false;
      throw error;
    });
}

async function handleGenerate3dAnnotations() {
  isGenerating3dAnnotations.value = true;

  const requestData = {
    image_id: currentAnimationSample.value.imageObj.id,
    annotation_set_id: props.destinationAnnotationSet?.id,
    cam_fx: 1240.31,
    cam_fy: 1232.88,
  };

  const boxes3d = await fetchGenerate3dBoxes(requestData)
    .catch((err) => {
      alert("Failed to generate 3D annotations");
      isGenerating3dAnnotations.value = false;
      return [];
    });

  console.log(boxes3d);
  const newAnnotations = [];
  boxes3d.forEach((box) => {
    const new3DBox = {
      id: uuidv4(),
      label_name: selectedLabel.value.name,
      label_index: selectedLabel.value.index,
      label_id: box.label_id,
      type: "3dbox",
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      score: 1,
      data_json: {
        x: box.box3d[0],
        y: box.box3d[1],
        z: box.box3d[2],
        dx: box.box3d[3],
        dy: box.box3d[4],
        dz: box.box3d[5],
      },
      image_id: box.image_id,
      annotation_set_id: box.annotation_set_id,
    };
    newAnnotations.push(new3DBox);
  });

  // Set label_index and label_name for new annotations
  // TODO: remove the necessity to do this
  newAnnotations.forEach((anno) => {
    const label = labels.value.find((e) => e.id === anno.label_id);
    if (label) {
      anno.label_index = label.index;
      anno.label_name = label.name;
    }
  });

  aigt3dAnnotations.value = newAnnotations;

  isGenerating3dAnnotations.value = false;
}

async function fetchGenerate3dBoxes(params) {
  // Sending a POST request
  return fetch(`aigt/get_3dbox_for_frame?ip=${aigtServer.value}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error generating 3d annotations`);
      }
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw new Error(`Error generating 3d annotations`);
    });
}

async function handleSaveAIGT3DAnnotations() {
  isSaving3dAnnotations.value = true;
  const dataConnect = new DatastoreConnect();
  return dataConnect.addAnnotationsBulk({ annotations: aigt3dAnnotations.value })
    .then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error);
      }

      return resp.result;
    })
    .then((newAnnotations) => {
      console.log(newAnnotations);
      // Set label_index and label_name for new annotations
      // TODO: remove the necessity to do this
      newAnnotations.forEach((anno) => {
        const label = labels.value.find((e) => e.id === anno.label_id);
        anno.label_index = label.index;
        anno.label_name = label.name;
      });

      // Update cache with new annotations
      addNewAnnotationsArrayToCache(newAnnotations);

      // Reset SAM2 annotations
      aigt3dAnnotations.value = [];

      isSaving3dAnnotations.value = false;
    })
    .catch((error) => {
      isSaving3dAnnotations.value = false;
      throw error;
    });
}

</script>

<style lang="scss" scoped>
#servers-select {
  border-radius: 3px;
  height: 28px;
}

.servers {
  &__loading {
    display: flex;
    align-items: center;
    font-style: italic;
    height: 100%;

    span {
      margin-left: 8px;
    }
  }

  &__not-found {
    display: flex;
    align-items: center;
    font-style: italic;
  }
}

.aigt-3d-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  min-height: 0;

  &__section {
    display: flex;
    flex-direction: column; /* Stack title and list vertically */
    // margin-bottom: 8px;
    border-bottom: 1px solid #ced4da;
    width: 100%;
    padding: 12px 12px 12px 12px;
    flex: 0 1 auto;
    min-height: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    color: var(--body-text-color-secondary);
    padding-bottom: 12px;
    letter-spacing: 0.025em;
    gap: 6px;
  }

  &__button {
    border-radius: 4px !important;

    &._secondary {
      color: inherit;
      background: none;
      border: none;
      box-shadow: none;

      &:hover:not(:disabled), &.active:not(:disabled) {
        background-color: var(--icon-hover-color);
        // box-shadow: 0 0 0 4px rgba(140, 140, 140, 0.2);
      }

      &:disabled {
        box-shadow: none !important;
        background-color: transparent !important;
        cursor: default;
        color: var(--color-disabled) !important;
      }
    }
  }
}

</style>
