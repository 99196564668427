<template>
  <div v-if="show" id="samToolbar" class="sam-toolbar">
    <!-- Servers -->
    <div class="sam-toolbar__group">
      <div v-if="fetchingCloudInstances" id="loading-servers" class="servers__loading">
        <div class="loader-ring"><div /><div /><div /><div /></div>
        <span>Loading Servers</span>
      </div>
      <div v-if="servers && servers.length === 0" class="servers__not-found">
        <span>No AIGT Servers Found</span>
      </div>
      <div v-if="servers && servers.length > 0" id="servers" class="sam-toolbar__group form__control-group">
        <!-- <label>Group</label> -->
        <select
          id="servers-select"
          v-model="sam2Server"
          class="select"
          required
        >
          <option :key="'servers-null'" :value="''">Select Server</option>
          <option v-for="option in servers" :key="`servers-${option.id}`" :value="option.ip">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="samOptions?.ip" class="sam-toolbar__group">
      <span v-if="!hasSAMImageEmbedding" class="sam-loader" />

      <div v-else class="sam-ready">
        <span class="sam-ready__icon" />
      </div>
    </div>
    <div v-if="samOptions?.ip" class="sam-toolbar__group">
      <div class="sam-toolbar__button-container">
        <IconButton
          id="SAMhoverAndClickToolAddBtn"
          aria-label="SAM Hover and Click (Add)"
          class="sam-toolbar__button"
          :class="{active: pointType === 'add'}"
          :icon="'cursor'"
          :showHover="false"
          :type="''"
          :width="20"
          :height="20"
          :title="'SAM Hover and Click (Left Click: positive point, Right Click: negative point)'"
          :disabled="!hasSAMImageEmbedding"
          @click="handleSAMHoverAndClickAdd"
        />
        <SVGIcon
          id="SAMhoverAndClickToolBtn"
          class="sam-toolbar__button-pointer-overlay"
          :iconName="'add_box_outlined'"
        />
      </div>
      <div class="sam-toolbar__button-container">
        <IconButton
          id="SAMhoverAndClickToolMinusBtn"
          aria-label="SAM Hover and Click (Minus)"
          class="sam-toolbar__button"
          :class="{active: pointType === 'minus'}"
          :icon="'cursor'"
          :showHover="false"
          :type="''"
          :width="20"
          :height="20"
          :title="'SAM Hover and Click (Minus)'"
          :disabled="!hasSAMImageEmbedding"
          @click="handleSAMHoverAndClickMinus"
        />
        <SVGIcon
          id="SAMhoverAndClickToolBtn"
          class="sam-toolbar__button-pointer-overlay"
          :iconName="'minus_box_outlined'"
        />
      </div>
      <IconButton
        id="SAMhoverAndClickToolBtn"
        aria-label="SAM Box Tool"
        class="sam-toolbar__button"
        :class="{active: pointType === 'box'}"
        :icon="'highlight_alt'"
        :showHover="false"
        :type="''"
        :title="'SAM Box Tool'"
        :width="20"
        :height="20"
        :disabled="!hasSAMImageEmbedding"
        @click="handleSAMAddBox"
      />
    </div>
    <div v-if="samOptions?.ip" class="sam-toolbar__group">
      <button class="button button-sm sam-toolbar__button _secondary" :disabled="!hasSAMImageEmbedding" @click="handleSAMReset">Reset</button>
      <button
        class="button button-sm sam-toolbar__button"
        :class="{'button-spinner': isCreatingPolygon}"
        :disabled="!hasSAMImageEmbedding || isCreatingPolygon"
        :title="`Create Annotation (c)`"
        @click="handleCreatePolygon"
      >
        Create Annotation
      </button>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import useSAMCanvas from '@/composables/canvas/useSAMCanvas.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'SAMToolbar',
  components: {
    IconButton,
    SVGIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    canUndo: {
      type: Boolean,
      default: false,
    },
    hasSAMImageEmbedding: {
      type: Boolean,
      default: false,
    },
    samOptions: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'undo',
    'sam-add-point',
    'sam-minus-point',
    'sam-add-box',
    'sam-create-polygon',
    'update:samOptions',
  ],
  setup(props, ctx) {
    const { SAMEventBus, handleCreateSAMPolygon, isCreatingPolygon } = useSAMCanvas();
    return { SAMEventBus, handleCreateSAMPolygon, isCreatingPolygon };
  },
  data() {
    return {
      servers: null,
      fetchingCloudInstances: true,
      sam2Server: '',
    };
  },
  computed: {
    pointType() {
      return this.samOptions.params.pointType;
    },
  },
  watch: {
    sam2Server(selectedServer) {
      const samOptions = this.samOptions;
      samOptions.ip = selectedServer;
      this.$emit('update:samOptions', samOptions);
    },
  },
  async mounted() {
    if (this.mode) {
      this.internalMode = this.mode;
    }

    this.servers = await this.listCloudInstances();
    if (this.servers.length > 0) {
      this.sam2Server = this.servers[0].ip;
      const samOptions = this.samOptions;
      samOptions.ip = this.sam2Server;
      this.$emit('update:samOptions', samOptions);
    }
  },
  methods: {
    handleUndo() {
      this.$emit('undo');
    },
    handleSAMReset() {
      const samOptions = this.samOptions;
      samOptions.params.clicks = [];
      samOptions.params.box = [];
      this.$emit('update:samOptions', samOptions);
      this.SAMEventBus.emit('reset');
    },
    handleSAMHoverAndClickAdd() {
      const samOptions = this.samOptions;
      if (samOptions.mode === 'hover_and_click' && samOptions.params.pointType === 'add') {
        samOptions.mode = null;
        samOptions.params.pointType = null;
      } else {
        samOptions.mode = 'hover_and_click';
        samOptions.params.pointType = 'add';
        this.$emit('sam-add-point');
      }
      this.$emit('update:samOptions', samOptions);
    },
    handleSAMHoverAndClickMinus() {
      const samOptions = this.samOptions;
      if (samOptions.mode === 'hover_and_click' && samOptions.params.pointType === 'minus') {
        samOptions.mode = null;
        samOptions.params.pointType = null;
      } else {
        samOptions.mode = 'hover_and_click';
        samOptions.params.pointType = 'minus';
        this.$emit('sam-minus-point');
      }
      this.$emit('update:samOptions', samOptions);
    },
    handleSAMAddBox() {
      const samOptions = this.samOptions;
      if (samOptions.mode === 'drag_box' && samOptions.params.pointType === 'box') {
        samOptions.mode = null;
        samOptions.params.pointType = null;
      } else {
        samOptions.mode = 'drag_box';
        samOptions.params.pointType = 'box';
        this.$emit('sam-add-box');
      }
      this.$emit('update:samOptions', samOptions);
    },
    async handleCreatePolygon() {
      await this.handleCreateSAMPolygon();
      this.SAMEventBus.emit('reset');
    },
    async listCloudInstances() {
      this.fetchingCloudInstances = true;
      const dataConnect = new DatastoreConnect();
      return dataConnect.listCloudInstances({ filter: "" })
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error);
          }
          this.fetchingCloudInstances = false;
          return resp.result;
        })
        .catch((error) => {
          this.fetchingCloudInstances = false;
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sam-toolbar {
  display: flex;
  flex-direction: row;

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__group + &__group {
    margin-left: 15px;
  }

  &__vr {
    display: flex;
    width: 1px;
    height: 60%;
    margin: 0;
  }

  &__title {
    font-weight: 700;
    font-size: 0.9rem;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__button-container {
    position: relative;
  }

  &__button {
    border-radius: 4px !important;

    &._secondary {
      color: inherit;
      background: none;
      border: none;
      box-shadow: none;

      &:hover:not(:disabled), &.active:not(:disabled) {
        background-color: var(--icon-hover-color);
      }

      &:disabled {
        box-shadow: none !important;
        background-color: transparent !important;
        cursor: default;
        color: var(--color-disabled) !important;
      }
    }

  }

  &__button-pointer-overlay {
    display: flex;
    position: absolute;
    width: 14px;
    height: 14px;
    top: -3px;
    right: -2px;
    pointer-events: none;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__labeled_button {
    width: auto;
  }
}

.sam-loader {
  animation: rotation 1s linear infinite;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  border-right: 3px solid transparent;
  @include themify() {
    border-top: 3px solid themed('color-accent-500');
  }

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-left: 3px solid transparent;
    @include themify() {
      border-bottom: 3px solid themed('color-primary-500');
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.sam-ready {
  box-sizing: border-box;
  width: 18px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    @include themify() {
      background: themed('color-success');
    }
  }
}

#servers-select {
  border-radius: 3px;
  height: 28px;
}

.servers {
  &__loading {
    display: flex;
    align-items: center;
    font-style: italic;
    height: 100%;

    span {
      margin-left: 8px;
    }
  }

  &__not-found {
    display: flex;
    align-items: center;
    font-style: italic;
  }
}

</style>
