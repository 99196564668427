import { defineStore } from 'pinia';
import {
  ref, watch, computed, onUnmounted,
} from 'vue';

export const useEditorStore = defineStore('editor', () => {
  const internalImageObj = ref(null);
  const internalAnnotations = ref([]);
  const sequences = ref([]);
  const labels = ref([]);

  const sequenceId = computed(() => {
    if (internalImageObj.value.id) {
      return internalImageObj.value.sequence_id;
    }
    return null;
  });

  function $reset() {
    internalImageObj.value = null;
    internalAnnotations.value = [];
    sequences.value = [];
    labels.value = [];
  }

  onUnmounted(() => {
    $reset();
  });

  return {
    internalImageObj,
    internalAnnotations,
    sequences,
    sequenceId,
    labels,
    $reset,
  };
});
