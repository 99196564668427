<template>
  <Modal
    ref="feedbackModal"
    :title="'Send a Request'"
    @closed="clearData"
  >
    <template #modal-main>
      <div class="settings container">
        <div class="row mb-3">
          <div class="col-6 settings__control-group">
            <label class="required">Issue Type</label>
            <select v-model="type" class="select" required>
              <option disabled :value="null">Select issue type</option>
              <option :value="'Feature Request'">Feature Request</option>
              <option :value="'Bug Report'">Bug Report</option>
              <option :value="'Other Feedback'">Other Feedback</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label>Subject</label>
            <input
              v-model="subject"
              placeholder="Add a subject"
              class="select"
              type="text"
            >
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 settings__control-group">
            <label class="required">Body</label>
            <textarea
              v-model="body"
              class="select feedback-body"
              placeholder="Description of your feedback."
              rows="10"
              cols="50"
            />
          </div>
        </div>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result" :class="[success ? 'success' : 'error']">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button class="button button-sm" @click="send">{{ 'Send' }}</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'FeedbackModal',
  components: {
    Modal,
  },
  data() {
    return {
      dataConnect: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
      type: null,
      subject: '',
      body: '',
      message: '',
      success: false,
    };
  },
  methods: {
    showModal() {
      this.$refs.feedbackModal.showModal();
    },
    clearData() {
      this.type = null;
      this.subject = "";
      this.body = "";
      this.message = "";
      this.success = false;
    },
    closeModal() {
      this.clearData();
      this.$refs.feedbackModal.closeModal();
    },
    send() {
      if (!this.type || this.type === '') {
        this.message = "Missing issue type";
        this.success = false;
        return;
      }
      if (!this.body || this.body === '') {
        this.message = "Missing issue body";
        this.success = false;
        return;
      }

      this.dataConnect.sendFeedback({
        issue_type: this.type,
        subject: this.subject,
        body: this.body,
      }).then((response) => {
        if (response.error) {
          this.message = response.error;
          this.success = false;
        } else {
          this.message = response.result;
          this.success = true;
        }
      }).catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 550px;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

.feedback-body {
  height: 150px;
  min-height: 150px;
}

.result {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight:600;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.success {
  @include themify() {
    background: rgba(115, 206, 115, 0.5) ;
    color: themed('color-success');
  }
}

.error {
  @include themify() {
    background: rgba(182, 94, 94, 0.5) ;
    color: themed('color-error')
  }
}
</style>
