<template>
  <div class="main-page-div">
    <main>
      <div
        id="annotation-viewer-modal"
      >
        <v-layout :full-height="true" style="z-index: 9;" class="flex-column">
          <div
            ref="modalDisplay"
            class="display"
            :tabindex="selectedAnnotationsToEdit.length > 0"
            @keyup.esc.stop="handleEscape"
          >
            <BaseSidebar :collapsible="false">
              <div class="annotation-viewer-modal__sidebar">
                <div class="data">
                  <JsonRawViewer
                    v-model:displayLabelTags="displayLabelTags"
                    v-model:selectedAnnotationSets="annotationDisplaySettings.displayAnnotationSets.annotation_sets"
                    v-model:selectedLabels="annotationDisplaySettings.displayLabels.labels"
                    v-model:colorAnnotationsBy="annotationDisplaySettings.colorAnnotationsBy.type"
                    :labels="labels"
                    :imageObj="displayImageObj"
                    :annotations="displayImageObj?.annotations"
                    :annotationSets="annotationSets"
                    :groupedAnnotations="groupedAnnotations"
                    :hideAddTopics="true"
                    :isEditing="false"
                    @edit-for-annotation-set="handleEditAnnotations"
                    @new-color="(params)=>updateLabelColor(params, { dataset_id: internalImageObj.dataset_id })"
                  >
                    <template #utilities-slot>
                      <AddVisualizationMenu @layout-selected="(layout) => {handleAddVisualization(layout); toggleVisibility(layout)}" />
                      <SelectLayoutMenu @layout-selected="handleLayoutSelected" />
                    </template>
                  </JsonRawViewer>
                </div>
              </div>
            </BaseSidebar>

            <div class="visualization">
              <template v-if="!showEditorModal">
                <VisualizationDisplays
                  ref="visualizationDisplays"
                  v-model:layout="layout"
                  :loading="isInitializingAnimation || isLoadingSample"
                  :failed="isLoadingSampleFailed"
                  @drop="handleVisualizationContainerDrop"
                  @visualization-selected="handleVisualizationSelected"
                />
              </template>
              <div class="visualization__horizontal-divider" />
              <AnimationControls
                v-if="internalImageObj"
                ref="animationControls"
                :imagesParams="imagesParams"
                :currentImage="internalImageObj"
                :isTask="false"
                :labels="labels"
                :selectedAnnotationSets="annotationDisplaySettings.displayAnnotationSets.annotation_sets"
              />
            </div>
          </div>
        </v-layout>
      </div>
    </main>
  </div>

  <template v-if="!showEditorModal">
    <VisualizationPane v-if="visualizationLocations?.canvas" :paneType="'canvas'" :to="getLocationId(visualizationLocations.canvas)">
      <AnnotationToolViewingModeControls
        :zoom="zoom"
        :hasSelection="selectedAnnotationIdentifiers.length > 0"
        :annotationSets="annotationSets"
        :labels="labels"
        :annotationDisplaySettings="annotationDisplaySettings"
        :imageObj="displayImageObj"
        @zoom-to-fit="handleZoomToFit"
        @zoom-to-selection="handleZoomToSelection"
      />
      <div
        ref="annotationTool"
        class="annotation-tool"
      >
        <AnnotationCanvas
          v-if="displayImageObj"
          ref="annotationCanvas"
          v-model:scale="annotationToolScale"
          v-model:selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
          :selectedAnnotationSets="annotationDisplaySettings.displayAnnotationSets.annotation_sets"
          :selectedLabels="annotationDisplaySettings.displayLabels.labels"
          :annotations="displayAnnotations"
          :annotationSets="annotationSets"
          :imageObj="displayImageObj"
          :labels="labels"
          :colorBy="colorBy"
          :showAnnotations="showAnnotations"
          :displayLabelTags="displayLabelTags"
          :editingEnabled="false"
          :useImageCacheOnly="true"
          :imageCacheElem="currentAnimationSample?.image"
          :show3DTo2DProjection="show3DTo2DProjection"
          :showTypes="jsonViewerShowTypes"
          :showPointerAxes="enableDepthMap"
        />
      </div>
      <VideoControls v-if="isVideo" />
    </VisualizationPane>

    <VisualizationPane v-if="visualizationLocations?.['3d-canvas']" :to="getLocationId(visualizationLocations['3d-canvas'])">
      <PointCloudViewer
        v-model:show3DTo2DProjection="show3DTo2DProjection"
        :imageObj="displayImageObj"
        :annotations="displayAnnotations"
        :labels="labels"
        :points="currentAnimationSample?.pcd?.[0]"
        :showTypes="jsonViewerShowTypes"
      />
    </VisualizationPane>

    <VisualizationPane v-if="visualizationLocations?.['depth-map']" :to="getLocationId(visualizationLocations['depth-map'])">
      <DepthMapViewer
        :showDepthMap="enableDepthMap"
      />
    </VisualizationPane>
  </template>

  <AnnotationEditorModal
    v-if="showEditorModal"
    ref="annotationEditorModal"
    :initialLabels="labels"
    :reviewSettings="reviewSettings"
    :initialAuditorType="reviewSettings?.initialAuditorType"
    :editAsSequence="showSequences"
    @closed="handleExitEditor(reviewSettings.reviewTask)"
    @new-color="(params) => updateLabelColor(params, reviewSettings.reviewTask)"
  />
</template>

<script>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import BaseSidebar from '@/components/BaseSidebar.vue';
import AnnotationCanvas from '@/components/DatasetComponent/AnnotationTool/AnnotationCanvas.vue';
import AnnotationToolViewingModeControls from '@/components/DatasetComponent/AnnotationTool/AnnotationToolViewingModeControls.vue';
import AnnotationEditorModal from '@/components/DatasetComponent/AnnotationTool/AnnotationEditorModal.vue';
import { roundNumber } from '@/assets/js/utils.js';
import {
  ref, onUnmounted, computed,
} from 'vue';
import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import { auzone_logo } from "@/assets/js/icons";
import VideoControls from '@/components/DatasetComponent/AnnotationTool/VideoControls/VideoControls.vue';
import AnimationControls from '@/components/DatasetComponent/AnnotationTool/AnimationControlsV2.vue';
import useViewerVisualizations from '@/composables/useViewerVisualizations.js';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import { storeToRefs } from 'pinia';
import PointCloudViewer from '@/components/DatasetComponent/AnnotationTool/PointCloudViewer.vue';
import DepthMapViewer from '@/components/DatasetComponent/AnnotationTool/DepthMapViewer.vue';
import usePCD from '@/composables/annotationTool/usePCD.js';
import useDepthMap from '@/composables/annotationTool/useDepthMap.js';
import JsonRawViewer from '@/components/DatasetComponent/AnnotationTool/JsonRawViewer.vue';
import useGallery from '@/composables/annotationTool/useGallery.js';
import useVisualizationDisplays from '@/composables/annotationTool/useVisualizationDisplays.js';
import VisualizationDisplays from '@/components/DatasetComponent/AnnotationTool/VisualizationDisplays.vue';
import VisualizationPane from '@/components/DatasetComponent/AnnotationTool/VisualizationPane.vue';
import SelectLayoutMenu from '@/components/DatasetComponent/AnnotationTool/SelectLayoutMenu.vue';
import AddVisualizationMenu from '@/components/DatasetComponent/AnnotationTool/AddVisualizationMenu.vue';
import useAnnotationType from '@/composables/useAnnotationType.js';
import { onKeyStroke, useDebounceFn } from '@vueuse/core';

export default {
  name: 'AnnotationViewer',
  components: {
    AnnotationCanvas,
    AnnotationToolViewingModeControls,
    BaseSidebar,
    VideoControls,
    AnimationControls,
    PointCloudViewer,
    DepthMapViewer,
    JsonRawViewer,
    AnnotationEditorModal,
    VisualizationDisplays,
    VisualizationPane,
    SelectLayoutMenu,
    AddVisualizationMenu,
  },
  emits: [
    'update:updatedAnnotations', 'get-next-image', 'get-previous-image', 'closed',
  ],
  setup() {
    const {
      imageList,
      datasetFilters,
      getImages,
      getImagesParams,
      setImageParams,
      setDatasetFilters,
      currentDatasetID,
    } = useGallery();
    const imagesParams = computed(() => getImagesParams());

    const {
      jsonViewerShowTypes,
    } = useAnnotationType();

    const initialAnnotationDisplaySettings = ref(
      {
        displayAnnotationSets: { annotation_sets: [] },
        displayLabels: { labels: [] },
        colorAnnotationsBy: { type: "label" },
      },
    );
    const annotationSets = ref([]);
    const internalImageObj = ref(null);
    const internalAnnotations = ref([]);
    const selectedAnnotationIdentifiers = ref([]);
    const displayLabelTags = ref({
      box: true,
    });
    const annotationDisplaySettings = ref({
      displayAnnotationSets: { annotation_sets: initialAnnotationDisplaySettings.value.displayAnnotationSets.annotation_sets },
      displayLabels: { labels: initialAnnotationDisplaySettings.value.displayLabels.labels },
      colorAnnotationsBy: { type: initialAnnotationDisplaySettings.value.colorAnnotationsBy.type },
    });
    const showSequences = ref(true);

    const labels = ref([]);
    const labelColorMap = ref({});

    // Visualizations
    const {
      currentAnimationSample, topics, isInitializingAnimation, frame, mouse, samples,
    } = storeToRefs(useViewerVisualizationsStore());
    const { $reset } = useViewerVisualizationsStore();
    const { abortCaching } = useViewerVisualizations();
    onUnmounted(() => {
      $reset();
    });

    const animationControls = ref(null);
    onKeyStroke('ArrowRight', useDebounceFn(async (e) => {
      if (samples.value && samples.value.length > 0) {
        animationControls.value.handleSkipNext();
      }
    }, 20));

    onKeyStroke('ArrowLeft', useDebounceFn(async (e) => {
      if (samples.value && samples.value.length > 0) {
        animationControls.value.handleSkipPrevious();
      }
    }, 20));

    const dataConnect = new DatastoreConnect();

    const { enablePCD } = usePCD(internalImageObj);

    const { enableDepthMap } = useDepthMap(internalImageObj);
    const show3DTo2DProjection = ref(false);

    const {
      handleAddVisualization,
      handleVisualizationSelected,
      handleVisualizationContainerDrop,
      handleLayoutSelected,
      visualizationLocations,
      getLocationId,
      layout,
    } = useVisualizationDisplays();

    return {
      imageList,
      getImages,
      imagesParams,
      datasetFilters,
      setImageParams,
      setDatasetFilters,
      currentDatasetID,
      labels,
      annotationSets,
      internalImageObj,
      internalAnnotations,
      selectedAnnotationIdentifiers,
      labelColorMap,
      currentAnimationSample,
      topics,
      enablePCD,
      jsonViewerShowTypes,
      displayLabelTags,
      annotationDisplaySettings,
      showSequences,
      dataConnect,
      enableDepthMap,
      isInitializingAnimation,
      show3DTo2DProjection,
      handleAddVisualization,
      handleVisualizationSelected,
      handleVisualizationContainerDrop,
      handleLayoutSelected,
      visualizationLocations,
      getLocationId,
      layout,
      samples,
      mouse,
      animationControls,
      abortCaching,
    };
  },
  data() {
    return {
      auzone_logo,
      showAnnotations: true,
      annotationToolScale: 1,
      tab: 'image',
      tabs: ['image', 'visualization'],
      showEditorModal: false,
      reviewSettings: null,
    };
  },
  computed: {
    title() {
      return this.internalImageObj?.name ? this.internalImageObj.name : '';
    },
    zoom() {
      return roundNumber(this.annotationToolScale * 100, 0);
    },
    annotationIdentifierMap() {
      const annotationIdentifierMap = {};
      this.internalAnnotations.forEach((anno, i) => {
        annotationIdentifierMap[anno.id] = anno;
      });
      return annotationIdentifierMap;
    },
    selectedAnnotationInfo() {
      if (this.selectedAnnotationIdentifiers.length > 0) {
        return this.annotationIdentifierMap[this.selectedAnnotationIdentifiers[0]];
      }
      return null;
    },
    selectedAnnotationsToEdit() {
      return this.selectedAnnotationIdentifiers.map((identifier) => this.annotationIdentifierMap[identifier]);
    },
    colorBy() {
      return this.annotationDisplaySettings?.colorAnnotationsBy?.type;
    },
    datasets() {
      return this.$store.state.datasets.datasetList;
    },
    isVideo() {
      return this.internalImageObj?.type === 'video';
    },
    isSequence() {
      return this.showSequences && Boolean(this.internalImageObj?.sequence_id);
    },
    displayImageObj() {
      if (this.currentAnimationSample) {
        return this.currentAnimationSample.imageObj;
      }
      return this.internalImageObj;
    },
    displayAnnotations() {
      if (this.currentAnimationSample) {
        return this.filterAnnotations(this.currentAnimationSample?.annotations);
      }
      return this.internalAnnotations;
    },
    combined() {
      return {
        internalImageObj: this.internalImageObj,
        internalAnnotations: this.internalAnnotations,
        selectedAnnotationIdentifiers: this.selectedAnnotationIdentifiers,
        labelColorMap: this.labelColorMap,
        currentAnimationSample: this.currentAnimationSample,
        enablePCD: this.enablePCD,
        jsonViewerShowTypes: this.jsonViewerShowTypes,
        annotationDisplaySettings: this.annotationDisplaySettings,
        enableDepthMap: this.enableDepthMap,
        imagesParams: this.imagesParams,
        datasetFilters: this.datasetFilters,
        currentDatasetID: this.currentDatasetID,
      };
    },
    groupedAnnotations() {
      if (this.currentAnimationSample) {
        // For animation
        const groupedData = {};
        this.topics.forEach((key) => {
          groupedData[key] = [];
        });
        const filteredAnnotations = this.filterAnnotationsBySet(this.currentAnimationSample?.annotations, this.annotationDisplaySettings.displayAnnotationSets.annotation_sets);
        filteredAnnotations.forEach((item) => {
          const itemType = item.type;
          if (!groupedData[itemType]) {
            groupedData[itemType] = [];
          }
          groupedData[itemType].push(item);
        });
        return groupedData;
      } else {
        // For non-animation
        if (!this.displayImageObj?.annotations) return {};

        const groupedData = {};
        const filteredAnnotations = this.filterAnnotationsBySet(this.displayImageObj?.annotations, this.annotationDisplaySettings.displayAnnotationSets.annotation_sets);
        filteredAnnotations.forEach((item) => {
          const itemType = item.type;
          if (!groupedData[itemType]) {
            groupedData[itemType] = [];
          }
          groupedData[itemType].push(item);
        });
        return groupedData;
      }
    },
    isLoadingSample() {
      if (this.currentAnimationSample) {
        return !this.currentAnimationSample.loaded;
      } else if (this.isSequence) {
        return true;
      }
      return false;
    },
  },
  watch: {
    combined: {
      deep: true,
      handler() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            image_id: encodeURI(JSON.stringify(this.displayImageObj?.id)),
            // image: encodeURI(JSON.stringify(this.internalImageObj)),
            // selectedAnnotationIdentifiers: encodeURI(JSON.stringify(this.selectedAnnotationIdentifiers)),
            // labelColorMap: encodeURI(JSON.stringify(this.labelColorMap)),
            // currentAnimationSample: encodeURI(JSON.stringify(this.currentAnimationSample)),
            // enablePCD: encodeURI(JSON.stringify(this.enablePCD)),
            // showTypes: encodeURI(JSON.stringify(this.showTypes)),
            imagesParams: encodeURI(JSON.stringify(this.imagesParams)),
            datasetFilters: encodeURI(JSON.stringify(this.datasetFilters)),
            annotationDisplaySettings: encodeURI(JSON.stringify(this.annotationDisplaySettings)),
            showSequences: encodeURI(this.showSequences),
            dataset_id: JSON.stringify(this.currentDatasetID),
            // enableDepthMap: encodeURI(JSON.stringify(this.enableDepthMap)),
          },
        });
      },
    },
    internalImageObj() {
      this.internalAnnotations = this.filterAnnotations(this.internalImageObj?.annotations);
    },
    internalAnnotations: {
      deep: true,
      handler(newAnnotations) {
        this.$emit('update:updatedAnnotations', newAnnotations || []);
      },
    },
    annotationDisplaySettings: {
      deep: true,
      handler() {
        this.internalAnnotations = this.filterAnnotations(this.internalImageObj?.annotations);
      },
    },
    jsonViewerShowTypes: {
      deep: true,
      handler() {
        this.internalAnnotations = this.filterAnnotations(this.internalImageObj?.annotations);
        if (this.jsonViewerShowTypes.pcd || this.jsonViewerShowTypes['3dbox']) {
          this.enablePCD = true;
          this.handleAddVisualization("3d-canvas");
        } else {
          this.enablePCD = false;
        }
        if (this.jsonViewerShowTypes.depthmap) {
          this.enableDepthMap = true;
          this.handleAddVisualization("depth-map");
        } else {
          this.enableDepthMap = false;
        }
      },
    },
  },
  async created() {
    if (this.$route.query.annotationDisplaySettings) {
      const settings = JSON.parse(decodeURI(this.$route.query.annotationDisplaySettings));
      this.annotationDisplaySettings = settings;
    }
    if (this.$route.query.showSequences) {
      this.showSequences = JSON.parse(this.$route.query.showSequences);
    }
    if (this.$route.query.annotationDisplaySettings) {
      const settings = JSON.parse(decodeURI(this.$route.query.annotationDisplaySettings));
      this.annotationDisplaySettings = settings;
    }
    if (this.$route.query.selectedAnnotationIdentifiers) {
      this.selectedAnnotationIdentifiers = JSON.parse(decodeURI(this.$route.query.selectedAnnotationIdentifiers));
    }
    if (this.$route.query.labelColorMap) {
      this.labelColorMap = JSON.parse(decodeURI(this.$route.query.labelColorMap));
    }
    if (this.$route.query.currentAnimationSample) {
      this.currentAnimationSample = JSON.parse(decodeURI(this.$route.query.currentAnimationSample));
    }
    if (this.$route.query.enablePCD) {
      this.enablePCD = JSON.parse(decodeURI(this.$route.query.enablePCD));
    }
    if (this.$route.query.dataset_id) {
      this.currentDatasetID = JSON.parse(decodeURI(this.$route.query.dataset_id));
    }
    if (this.$route.query.imagesParams) {
      this.setImageParams(JSON.parse(decodeURI(this.$route.query.imagesParams)));
    }
    if (this.$route.query.datasetFilters) {
      this.setDatasetFilters(JSON.parse(decodeURI(this.$route.query.datasetFilters)));
    }
  },
  async mounted() {
    const datastore = new DatastoreConnect();
    const params = {
      id: parseInt(this.$route.query.image_id),
      get_annotations: true,
    };
    const imageResult = await datastore.getImage(params)
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch((error) => {
        console.error(error);
        alert(`Failed to get image\n${error.message}`);
      });
    this.internalImageObj = imageResult;
    this.refreshAnnotationSets(imageResult.dataset_id);
    this.refreshLabels(imageResult.dataset_id);
    this.topics = await this.getTopics();
  },
  methods: {
    async getTopics() {
      const dataConnect = new DatastoreConnect();
      const topicsResp = await dataConnect.getDatasetTopics({ dataset_id: this.internalImageObj.dataset_id });
      if (!topicsResp.result || topicsResp.error) {
        return null;
      }
      return topicsResp.result;
    },
    filterAnnotations(annotations) {
      if (!annotations) {
        return [];
      }
      let filteredAnnotations = JSON.parse(JSON.stringify(annotations));
      filteredAnnotations = this.filterAnnotationsBySet(filteredAnnotations, this.annotationDisplaySettings.displayAnnotationSets.annotation_sets);
      return filteredAnnotations;
    },
    filterAnnotationsBySet(annotations, sets) {
      if (sets) {
        return annotations.filter((anno) => sets.includes(anno.annotation_set_id));
      }
      return annotations;
    },
    filterAnnotationsByLabelIndexes(annotations, labelIndexes) {
      if (labelIndexes) {
        return annotations.filter((anno) => labelIndexes.includes(anno.label_index));
      }
      return annotations;
    },
    handleAnnotationListItemClicked(annotation, index) {
      if (this.selectedAnnotationsToEdit.includes(annotation)) {
        // Remove selected annotation identifier from the array
        const arrayIndex = this.selectedAnnotationIdentifiers.indexOf(annotation.id);
        if (arrayIndex > -1) {
          this.selectedAnnotationIdentifiers.splice(arrayIndex, 1);
        }
      } else {
        this.selectedAnnotationIdentifiers.push(annotation.id);
      }

      this.$nextTick(() => {
        this.$refs.imageModal.focus();
      });
    },
    handleLabelListItemClicked(label) {
      // Nothing for viewer so far
    },
    handleEscape() {
      this.selectedAnnotationIdentifiers = [];
      this.$nextTick(() => {
        this.$refs.imageModal.focus();
      });
    },
    handleModalClosed() {
      this.selectedAnnotationIdentifiers = [];
      this.$emit('closed');
    },
    handleZoomToFit() {
      this.$refs.annotationCanvas.zoomToFitImage();
    },
    handleZoomToSelection() {
      this.$refs.annotationCanvas.zoomToSelection();
    },
    handleZKey() {
      if (this.selectedAnnotationIdentifiers.length > 0) {
        this.$refs.annotationCanvas.zoomToSelection();
      } else {
        this.$refs.annotationCanvas.zoomToFitImage();
      }
    },
    async refreshAnnotationSets(dataset_id) {
      this.annotationSets = await this.getAnnotationSetsForDataset(dataset_id);
    },
    async refreshLabels(dataset_id) {
      this.labels = await this.getLabelListForDataset(dataset_id);
      this.labelColorMap = useAnnotationColorMap({ items: ref(this.labels), key: 'index' });
    },
    async getAnnotationSetsForDataset(dataset_id) {
      const resp = await this.dataConnect.getAnnotationSets({
        dataset_id,
        get_num_annotations: true,
        get_num_images: true,
        get_labels: true,
      })
        .catch((error) => {
          console.log('Failed to retrieve sets:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    async getLabelListForDataset(dataset_id) {
      const resp = await this.dataConnect.getLabelList({ dataset_id })
        .catch((error) => {
          console.log('Failed to retrieve labels:', error);
        });
      if (!resp || resp.error || !resp.result) {
        return [];
      }
      return resp.result;
    },
    handleEditAnnotations(annotationSet) {
      this.reviewSettings = {
        reviewSessionMethod: "review_annotation_editor",
        batchSize: 10,
        samplingMethod: "random",
        reviewSession: null,
        reviewTask: {
          id: null,
          type: "audit",
          created_at: null,
          source_annotation_set_id: annotationSet.id,
          dest_annotation_set_id: annotationSet.id,
          image_id: parseInt(this.$route.query.image_id),
          dataset_id: annotationSet.dataset_id,
          name: "",
          description: "",
          permission_type: "default",
          organization_id: 5,
          remainingCount: null,
        },
        initialAuditorType: "image",
      };
      this.showEditorModal = true;
    },
    async handleExitEditor() {
      // Update image list
      this.getImages().then((list) => {
        this.imageList = list;
      });

      // Update internal image
      const datastore = new DatastoreConnect();
      const params = {
        id: parseInt(this.$route.query.image_id),
        get_annotations: true,
      };
      const imageResult = await datastore.getImage(params)
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error.message);
          }
          return resp.result;
        })
        .catch((error) => {
          console.error(error);
          alert(`Failed to get updated image\n${error.message}`);
        });

      this.showEditorModal = false;

      this.$nextTick(() => {
        this.internalImageObj = imageResult;
      });
      this.reviewSettings = null;

      if (this.isSequence) {
        this.$refs.animationControls.handleClearCache();
      }
    },
    async updateLabelColor(params, obj) {
      params.dataset_id = obj.dataset_id;
      await this.dataConnect.updateLabel(params)
        .then(async (data) => {
          if (!data.error) {
            this.labels = await this.getLabelListForDataset(obj.dataset_id);
          }
        })
        .catch((e) => console.log(e));
    },
    toggleVisibility(layout) {
      if (layout === "depth-map") {
        if (!this.jsonViewerShowTypes["depthmap"]) {
          this.jsonViewerShowTypes["depthmap"] = true;
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>

main {
  width: 100%;
  height: 100%;
}
.display {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
}
.visualization {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  min-width: 0;

  &__displays {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    min-height: 0;
  }

  &__pane {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 0 50%;
    min-width: 0;
    height: 100%;
  }

  &__json-raw-pane {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20%;
  }

  &__horizontal-divider {
    outline: solid 2px gray;
    z-index: 2;
  }

  &__vertical-divider {
    outline: solid 2px gray;
    z-index: 2;
  }
}

#annotation-viewer-modal {
  width: 100%;
  height: 100%;
}

.annotation-viewer-modal {
  width: 100%;
  height: 100%;

  &__sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 300px;
    min-width: 300px;
    padding: 0;
    z-index: 2;
    @include themify() {
      background: themed('color-white-100');
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 40px;
      padding: 4px 8px;
      margin: 0;
      text-align: left;
      box-shadow: 0px 1px 3px grey;
      @include themify() {
        background: themed('color-white-900');
      }
    }
  }
}

.annotation-tool {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  @include themify() {
    background: themed('image-background-fill');
  }
}

.label-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  padding: 8px 0 8px 0;
  margin: 0;
  overflow-y: auto;
  list-style-type: none;

  li {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: unset;
    height: 30px;
    padding: 4px 8px;
    font-size: 0.875rem;
    border: 1px solid transparent;

    button {
      @include themify() {
        color: themed('color-primary');
      }
    }

    span {
      margin-right: auto;
    }

    &._active {
      background: rgba(140,140,140,0.2);
      cursor: pointer;

      @include themify() {
        border: 1px solid black;
        border-left: 4px solid themed('color-primary');
      }
    }
  }

  li._hover {
    &:hover {
      background: rgba(140,140,140,0.2);
      cursor: pointer;
    }
  }

  &__symbol {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__box-symbol {
    border: 2px solid;
  }
}

.modal-prepend-header {
  &__logo {
    width: 100px;
    height: 36px;
  }
}

#no-image {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-primary);
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 8px 12px 16px;
}
:deep(.v-expansion-panel-title__overlay) {
  pointer-events: none;
}

.v-window {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}

.v-window-item {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid rgb(132, 130, 130);

  .v-tabs {
    background: transparent;
    color: var(--color-primary);
  }

  .v-tab {
    flex: 1 1 1px;
    font-size: 0.625rem;
    min-width: fit-content;
    padding: 0 12px;
  }
}

.data {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}

.button-page {
  background: none;
  height: 23px;
  color: var(--body-text-color);
  padding: 10px 6px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: none;
  margin: 4px;
  border-radius: 4px;
  text-transform: capitalize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: var(--color-primary-200);
  }
}

</style>
