<template>
  <div class="animation-controls">
    <div class="timeline">
      <Scrubber
        v-model="frame"
        :min="1"
        :max="frameCount"
        :secondary="buffered"
        :step="1"
        @scrubbing-end="doneScrubbing"
      >
        <template #default="{ position, pendingValue }">
          <div
            class="scrubber-popup"
            :style="{ left: position }"
          >
            {{ formatScrubberValue(pendingValue) }}
          </div>
          <div
            class="scrubber-thumb"
            :style="{ left: position }"
          />
        </template>
      </Scrubber>
    </div>
    <div class="controls">
      <div class="controls__start">
        <span v-if="frame && frameCount">{{ frame }} / {{ frameCount }}</span>
        <SVGIcon
          v-if="isLoadingSampleFailed"
          class="warning-icon ms-1"
          :iconName="'warning'"
          :width="'20px'"
          :height="'20px'"
        />
      </div>
      <div class="controls__buttons">
        <IconButton
          :icon="'skip_previous'"
          :width="24"
          :height="24"
          :type="'click'"
          @click="handleSkipPrevious"
        />
        <IconButton
          v-model="playing"
          aria-label="Play/Pause"
          :icon="'play'"
          :iconActive="'pause'"
          :width="32"
          :height="32"
          :type="'toggle-icon'"
          :showHover="true"
          @click="handlePlayButtonClick"
        />
        <IconButton
          :icon="'skip_next'"
          :width="24"
          :height="24"
          :type="'click'"
          @click="handleSkipNext"
        />
      </div>
      <div class="controls__end">
        <!-- <div class="annotation-search">
          <AnnotationExpandingSearch
            :labels="labels"
            @previous="handlePreviousAnnotationClicked"
            @next="handleNextAnnotationClicked"
          />
        </div> -->
        <BaseMenu
          :placement="'bottom-start'"
          :appendToBody="true"
          @closed="show_playback_menu = false"
        >
          <IconButton
            v-model="show_playback_menu"
            title="Playback Speed"
            :icon="'playback_speed'"
            :width="24"
            :height="24"
            class="ms-auto"
          />
          <template #menu>
            <div class="input-buttons scrollbar">
              <template v-for="(option, index) in playback_options" :key="index">
                <label
                  :for="`menuItem-${option.value}`"
                  class="input-buttons__row input-buttons__row-button"
                >
                  <div class="input-buttons__row-button-check me-2">
                    <SVGIcon
                      v-if="fps === option.value"
                      :iconName="'check'"
                      :height="'20px'"
                      :width="'20px'"
                    />
                  </div>
                  <input
                    :id="`menuItem-${option.value}`"
                    v-model="fps"
                    type="radio"
                    :value="option.value"
                  >
                  <span>{{ option.name }}</span>
                </label>
              </template>
            </div>
          </template>
        </BaseMenu>
        <SVGIcon
          v-if="currentAnimationSample && currentAnimationSample?.imageObj?.review_status == 'Done'"
          class="verified_badge"
          :iconName="'verified_filled'"
          :width="'24px'"
          :height="'24px'"
        />
      </div>
    </div>
    <div>
      <KeyframeTimeline2
        v-if="doKeyframesExist"
        v-model:frame="frame"
        v-model:data="keyframes"
        :frameCount="frameCount"
        class="mt-2 mb-2"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref, onMounted, onUnmounted, watch, toRefs, computed,
} from 'vue';
import useImagesFetch from '@/composables/useImagesFetch.js';
import IconButton from '@/components/IconButton.vue';
import Scrubber from '@/components/DatasetComponent/AnnotationTool/VideoControls/Scrubber.vue';
import KeyframeTimeline2 from '@/components/DatasetComponent/AnnotationTool/KeyframeTimeline2.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import useViewerVisualizations from '@/composables/useViewerVisualizations.js';
import useGallery from '@/composables/annotationTool/useGallery.js';

const props = defineProps({
  imagesParams: {
    type: Object,
    default: null,
  },
  currentImage: {
    type: Object,
    default: null,
  },
  isTask: {
    type: Boolean,
    default: false,
  },
  reviewSettings: {
    type: Object,
    default: null,
  },
  labels: {
    type: Array,
    default: () => [],
  },
  selectedAnnotationSets: {
    type: Array,
    default: () => [],
  },
});
const {
  imagesParams,
  currentImage,
  selectedAnnotationSets,
} = toRefs(props);

const emit = defineEmits(['frame-count', 'samples-loaded']);
defineExpose({ handleSkipPrevious, handleSkipNext, handleClearCache });

const show_playback_menu = ref(false);
const playback_options = ref([
  {
    value: 1,
    name: '1 FPS',
  },
  {
    value: 5,
    name: '5 FPS',
  },
  {
    value: 10,
    name: '10 FPS',
  },
  {
    value: 20,
    name: '20 FPS',
  },
  {
    value: 100,
    name: '100 FPS',
  },
]);

const {
  abortSignalForCaching,
  samples,
  animationImageCache,
  frame,
  playing,
  buffered,
  currentAnimationSample,
  keyframes,
  doKeyframesExist,
  frameCount,
  fps,
  isInitializingAnimation,
  topics,

  cacheImages,
  loadSample,
  loadSampleToCache,
  abortCaching,

  startAnimation,
  pauseAnimation,
  addNewSampleToCache,
  clearSample,
  updateSample,
  throttledUpdateSample,
  resetForNewSequence,
  $reset,
} = useViewerVisualizations(selectedAnnotationSets.value);

const {
  getImagesFilterParams,
} = useGallery();

const { controller, getImages, getLabellingSequenceFrames } = useImagesFetch();

const imagesFilterParams = computed(() => getImagesFilterParams());

async function doneScrubbing() {
  abortCaching();
  playing.value = false;
  if (!isInitializingAnimation.value && frameCount.value > 0) {
    if (!animationImageCache.value[frame.value - 1] || !animationImageCache.value[frame.value - 1].loaded) {
      addNewSampleToCache(frame.value - 1);
      clearSample();
      loadSampleToCache(samples.value[frame.value - 1], frame.value - 1, abortSignalForCaching.value).then(() => { updateSample(); });
    }
  }
  abortCaching();
  cacheImages(abortSignalForCaching.value);
}

onMounted(async () => {
  abortCaching();
  resetForNewSequence();

  loadFirstImage();
});

onUnmounted(async () => {
  abortCaching();
  $reset();
});

async function loadFirstImage() {
  if (controller.value) {
    controller.value.abort();
  }
  controller.value = new AbortController();
  const currentSignal = controller.value.signal;

  isInitializingAnimation.value = true;
  abortCaching();

  addNewSampleToCache(0);
  currentAnimationSample.value = animationImageCache.value[0];
  await loadSample(currentImage.value.id, frame.value - 1, abortSignalForCaching.value).then(() => { updateSample(); });

  isInitializingAnimation.value = false;

  // let images = [];
  if (props.isTask) {
    const filters = {
      images_filter: {
        sequence_id: currentImage.value.sequence_id,
      },
      image_files_filter: {},
    };
    filters.images_filter.dataset_id = props.reviewSettings.reviewTask.dataset_id;
    filters.aggregate_annotations_filter = { ...filters.aggregate_annotations_filter, aggregate_annotation_set_ids: [props.reviewSettings.reviewTask.dest_annotation_set_id] };
    const options = {
      sort_by: 'id',
      reverse: false,
      offset: 0,
      source_annotation_set_id: props.reviewSettings.reviewTask.source_annotation_set_id,
      annotations_filter: null,
      aggregate_annotations_filter: { aggregate_annotation_set_ids: [props.reviewSettings.reviewTask.dest_annotation_set_id] },
      review_task_id: props.reviewSettings.reviewTask.id,
      only_ids: true,
    };
    samples.value = await getLabellingSequenceFrames(filters, options, currentSignal)
      .catch((err) => {
        console.log(err);
        throw Error(err);
      });
  } else {
    const filters = {
      ...imagesFilterParams.value,
    };
    filters.images_filter.dataset_id = currentImage.value.dataset_id;
    filters.images_filter.sequence_id = currentImage.value.sequence_id;
    const params = {
      ...imagesParams.value,
      combine_sequence_frames: true,
      get_annotations: false,
      limit: 0,
      offset: 0,
      only_ids: true,
    };
    // const options = {
    //   get_annotations: false,
    //   sort_by: 'id',
    //   reverse: false,
    //   offset: 0,
    // };
    samples.value = await getImages(filters, params, currentSignal)
      .catch((err) => {
        throw Error(err);
      });
  }

  emit('samples-loaded', samples.value);

  const offset = samples.value.findIndex((e) => e === currentImage.value.id);

  frameCount.value = samples.value.length;
  if (offset) {
    frame.value = offset + 1;
  } else {
    frame.value = 1;
  }

  if (!currentSignal.aborted) {
    // animationImageCache.value[frame.value - 1] = currentAnimationSample.value;
    // animationImageCache.value[frame.value - 1] = {
    //   image: null,
    //   imageObj: null,
    //   annotations: [],
    //   loaded: false,
    //   ...Object.fromEntries(topics.value.map((key) => [key, []])),
    // };
    // await loadSampleToCache(samples.value, frame.value - 1, abortSignalForCaching.value).then(() => { updateSample(); });
    cacheImages(abortSignalForCaching.value);
  }
}

watch(frame, () => {
  if (samples.value.length > 0) {
    if (!playing.value) {
      throttledUpdateSample();
    }
  }
});

function formatScrubberValue(v) {
  return v;
}

function handlePlayButtonClick() {
  playing.value ? pauseAnimation() : startAnimation();
}

function handleSkipPrevious() {
  if (frame.value > 1) {
    frame.value -= 1;

    if (!animationImageCache.value[frame.value - 1]?.loaded) {
      addNewSampleToCache(frame.value - 1);
      clearSample();
      loadSampleToCache(samples.value[frame.value], frame.value - 1, abortSignalForCaching.value).then(() => { updateSample(); });
    }
    abortCaching();
    cacheImages(abortSignalForCaching.value);
  }
}

function handleSkipNext() {
  if (frame.value < samples.value.length) {
    frame.value += 1;
  } else {
    frame.value = 1;
  }
  abortCaching();
  cacheImages(abortSignalForCaching.value);
}

function handleClearCache() {
  animationImageCache.value = [];
  abortCaching();
}

watch(currentImage, async () => {
  abortCaching();
  resetForNewSequence();
  loadFirstImage();
});

const isLoadingSampleFailed = computed(() => {
  if (currentAnimationSample.value?.failed) {
    return true;
  }

  return false;
});

</script>

<style lang="scss" scoped>
.animation-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70px;
  padding: 0 8px;
  z-index: 10;
  border-left: 1px solid rgba(0,0,0,0.25);
  background: var(--body-color);
}

.timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20px;
  padding: 8px 0;
}

.controls {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;

  &__start {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 1px;
  }

  &__end {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 1px;
    gap: 8px;
  }

  &__input {
    max-width: 100px;
  }

  &__buttons {
    max-width: 100px;
  }
}

.scrubber-popup {
  position: absolute;
  transform: translateX(-50%);
  background-color: #000;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  bottom: 0;
  margin-bottom: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #fff;
  pointer-events: none;
}
.scrubber-thumb {
  position: absolute;
  transform: translate(-50%, 7px);
  background-color: var(--color-primary-400);
  height: 18px;
  width: 4px;
  bottom: 0;
}

.verified_badge {
  color: var(--color-success);
}

.fps-input {
  max-width: 100px;
}

.annotation-search {
  padding: 4px 0px;
  margin-left: auto;
  max-width: 300px;
}

.input-buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  text-transform: none;
  min-width: 100px;

  &.scrollbar {
    overflow: auto;
  }

  &__row {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 500px;
  }

  &__row-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    padding: 6px 8px;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
    &:last-of-type {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:hover {
      background: rgba(0,0,0,0.125);
    }
    &:focus-within {
      background: rgba(0,0,0,0.125);
    }

    &> * {
      pointer-events: none;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      opacity: 0;
      margin: 0;
    }

    span, label {
      font-size: 0.9rem;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__row-button-check {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    @include themify() {
      color: themed('filter-button-list-row-button-selected')
    }
  }
}

.warning-icon {
  color: var(--color-error)
}
</style>
