import { defineStore } from 'pinia';
import {
  ref, computed, onUnmounted,
} from 'vue';
import { useThrottleFn } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';

export const useViewerVisualizationsStore = defineStore('visualizations', () => {
  const mouse = ref({});
  const abortSignalForCaching = ref(null);
  const samples = ref([]);
  const animationImageCache = ref({});
  const currentAnimationSample = ref(null);
  const frame = ref(0);
  const playing = ref(false);
  const topics = ref([]);
  const fps = ref(10);
  const isInitializingAnimation = ref(false);
  const abortControllerForCaching = ref(null);

  const frameCount = ref(0);

  const buffered = computed(() => {
    if (frameCount.value === 0) {
      return 0;
    }

    let bufferedIndex = frame.value - 1;
    const lastFrame = Math.max(0, Math.max(...Object.keys(animationImageCache.value).map((e) => parseInt(e))));
    for (let i = frame.value - 1; i <= frame.value + lastFrame; i++) {
      if (animationImageCache.value[i] && animationImageCache.value[i].loaded) {
        bufferedIndex = i;
      } else {
        break;
      }
    }
    return bufferedIndex + 1;
  });

  function addNewSampleToCache(index) {
    animationImageCache.value[index] = {
      image: null,
      imageObj: null,
      annotations: [],
      loaded: false,
    };
  }

  function clearSample() {
    currentAnimationSample.value = {
      image: null,
      imageObj: null,
      annotations: [],
      loaded: false,
    };
  }

  function updateSample() {
    if (animationImageCache.value[frame.value - 1]) {
      currentAnimationSample.value = animationImageCache.value[frame.value - 1];
    }
  }

  const throttledUpdateSample = useThrottleFn(() => {
    if (animationImageCache.value[frame.value - 1]) {
      currentAnimationSample.value = animationImageCache.value[frame.value - 1];
    }
  }, 100);

  async function pauseAnimation() {
    console.log('pause animation');
    playing.value = false;
  }

  function getAnimationDelay(fps) {
    return 1000 / fps;
  }

  function $reset() {
    animationImageCache.value = {};
    currentAnimationSample.value = null;
    frame.value = 1;
    frameCount.value = 0;
    playing.value = false;
    topics.value = [];
    // keyframes.value = {};
    // isKeyframe.value = false;
    keyframes.value = [];
    mouse.value = {};
  }

  function resetForNewSequence() {
    animationImageCache.value = {};
    frame.value = 1;
    frameCount.value = 0;
    playing.value = false;
    topics.value = [];
    keyframes.value = [];
  }

  function removeKeyframe(selectedKeyframeObject) {
    keyframes.value = [...keyframes.value.filter((keyframeObject) => keyframeObject.id !== selectedKeyframeObject)];
  }

  const doKeyframesExist = computed(() => keyframes.value.length > 0);

  // const isKeyframe = computed(() => {
  //   if (keyframes.value[frame.value]) {
  //     return true;
  //   }
  //   return false;
  // });

  // const doKeyframesExist = computed(() => {
  //   if (Object.keys(keyframes.value).length > 0) {
  //     return true;
  //   }
  //   return false;
  // });

  // const keyframeRanges = computed(() => {
  //   const keys = Object.keys(keyframes.value).sort((a, b) => a - b);

  //   let currentRangeStart = null;
  //   const ranges = [];

  //   for (const key of keys) {
  //     const currentKey = parseInt(key);

  //     if (currentRangeStart === null) {
  //       currentRangeStart = currentKey;
  //     } else if (currentKey !== null) {
  //       ranges.push([currentRangeStart, currentKey]);

  //       if (keyframes.value[key].end) {
  //         currentRangeStart = null;
  //       } else {
  //         currentRangeStart = currentKey;
  //       }
  //     }
  //   }

  //   if (currentRangeStart && currentRangeStart !== totalFrames.value) {
  //     ranges.push([currentRangeStart, null]);
  //   }

  //   return ranges;
  // });

  onUnmounted(() => {
    $reset();
  });

  const keyframes = ref([]);

  function setKeyframeNew(frame, imageObj, annotation) {
    const existingAnnotationKeyframes = keyframes.value.find((d) => d.id === annotation.id);
    if (!existingAnnotationKeyframes) {
      keyframes.value.push({ id: annotation.id, tracking_id: uuidv4(), keyframes: { [frame]: { end: false, annotation } } });
    } else {
      existingAnnotationKeyframes.keyframes[frame] = { end: false, annotation };
    }
  }

  const keyframeAnnotations = computed(() => {
    function keyframeBelow(keyframes, frame) {
      let keyframeBelowVal = null;
      Object.keys(keyframes).forEach((key) => {
        if (Number(key) <= frame && Number(key) > keyframeBelowVal) {
          keyframeBelowVal = Number(key);
        }
      });
      if (keyframeBelowVal && keyframes[keyframeBelowVal].end && keyframeBelowVal < frame) {
        return null;
      }
      return keyframeBelowVal;
    }

    function keyframeAbove(keyframes, frame) {
      const aboveKeyframes = Object.keys(keyframes)
        .map((x) => Number(x))
        .filter((keyframe) => keyframe >= frame);

      if (aboveKeyframes.length === 0) {
        return null;
      }

      const keyframeAboveVal = Math.min(...aboveKeyframes);
      return keyframeAboveVal;
    }

    const keyframeAnnotationsArr = [];
    keyframes.value.forEach((d) => {
      const keyframeBelowVal = keyframeBelow(d.keyframes, frame.value);
      const keyframeAboveVal = keyframeAbove(d.keyframes, frame.value);
      if (keyframeBelowVal && !keyframeAboveVal) {
        keyframeAnnotationsArr.push(d.keyframes[keyframeBelowVal].annotation);
      } else if (keyframeBelowVal && keyframeAboveVal && keyframeBelowVal === keyframeAboveVal) {
        keyframeAnnotationsArr.push(d.keyframes[keyframeBelowVal].annotation);
      } else if (keyframeBelowVal && keyframeAboveVal && keyframeBelowVal < keyframeAboveVal) {
        const annotationBelow = d.keyframes[keyframeBelowVal].annotation;
        const annotationAbove = d.keyframes[keyframeAboveVal].annotation;

        const t = (frame.value - keyframeBelowVal) / (keyframeAboveVal - keyframeBelowVal);
        if (d.keyframes[keyframeBelowVal].annotation.type === 'box') {
          // Interpolate box
          const interpolatedAnnotation = {
            ...annotationBelow,
            x: annotationBelow.x + (annotationAbove.x - annotationBelow.x) * t,
            y: annotationBelow.y + (annotationAbove.y - annotationBelow.y) * t,
            w: annotationBelow.w + (annotationAbove.w - annotationBelow.w) * t,
            h: annotationBelow.h + (annotationAbove.h - annotationBelow.h) * t,
          };

          keyframeAnnotationsArr.push(interpolatedAnnotation);
        } else if (d.keyframes[keyframeBelowVal].annotation.type === '3dbox') {
          // Interpolate 3dbox
          const interpolatedAnnotation = {
            ...annotationBelow,
            data_json: {
              ...annotationBelow.data_json,
              x: annotationBelow.data_json.x + (annotationAbove.data_json.x - annotationBelow.data_json.x) * t,
              y: annotationBelow.data_json.y + (annotationAbove.data_json.y - annotationBelow.data_json.y) * t,
              z: annotationBelow.data_json.z + (annotationAbove.data_json.z - annotationBelow.data_json.z) * t,
              dx: annotationBelow.data_json.dx + (annotationAbove.data_json.dx - annotationBelow.data_json.dx) * t,
              dy: annotationBelow.data_json.dy + (annotationAbove.data_json.dy - annotationBelow.data_json.dy) * t,
              dz: annotationBelow.data_json.dz + (annotationAbove.data_json.dz - annotationBelow.data_json.dz) * t,
            },
          };

          keyframeAnnotationsArr.push(interpolatedAnnotation);
        }
      }
    });

    return keyframeAnnotationsArr;
  });

  function removeAnnotationsFromCache(annotations) {
    // Check each cache element for excluded annotations and remove them
    const excludeIds = annotations.map((anno) => anno.id);
    animationImageCache.value.forEach((imageCache, i) => {
      const excluded = imageCache.annotations.filter((annotation) => excludeIds.includes(annotation.id));
      if (excluded.length > 0) {
        // Clear the parsed annotations
        excluded.forEach((excludedAnno) => {
          animationImageCache.value[i][excludedAnno.type] = [];
        });
        // Clear annotation object
        animationImageCache.value[i].annotations = imageCache.annotations.filter((annotation) => !excludeIds.includes(annotation.id));
        animationImageCache.value[i].imageObj.annotations = imageCache.imageObj.annotations.filter((annotation) => !excludeIds.includes(annotation.id));
      }
    });
    updateSample();
  }

  // const keyframeRangesNew = computed(() => {
  //   console.log(Object.keys(testdata.value.keyframes));
  //   const keys = Object.keys(testdata.value.keyframes).sort((a, b) => a - b);
  //   console.log(keys);

  //   let currentRangeStart = null;
  //   const ranges = [];

  //   for (const key of keys) {
  //     const currentKey = parseInt(key);

  //     if (currentRangeStart === null) {
  //       currentRangeStart = currentKey;
  //     } else if (currentKey !== null) {
  //       ranges.push([currentRangeStart, currentKey]);

  //       if (keyframes.value[key].end) {
  //         currentRangeStart = null;
  //       } else {
  //         currentRangeStart = currentKey;
  //       }
  //     }
  //   }

  //   console.log(currentRangeStart);
  //   if (currentRangeStart && currentRangeStart !== totalFrames.value) {
  //     ranges.push([currentRangeStart, null]);
  //   }

  //   return ranges;
  // });

  function addNewAnnotationsArrayToCache(newAnnotations) {
    const groupedNewAnnotations = Object.groupBy(newAnnotations, ({ image_id }) => image_id);
    Object.values(animationImageCache.value).forEach((cacheFrame) => {
      if (cacheFrame.imageObj && groupedNewAnnotations[cacheFrame.imageObj.id]) {
        groupedNewAnnotations[cacheFrame.imageObj.id].forEach((anno) => { anno.reviewStatus = "verified"; });
        cacheFrame.annotations = [...cacheFrame.annotations, ...groupedNewAnnotations[cacheFrame.imageObj.id]];
        cacheFrame.imageObj.annotations = [...cacheFrame.imageObj.annotations, ...groupedNewAnnotations[cacheFrame.imageObj.id]];
      }
    });
    updateSample();
  }

  return {
    abortSignalForCaching,
    samples,
    animationImageCache,
    currentAnimationSample,
    frame,
    playing,
    buffered,
    topics,
    // isKeyframe,
    doKeyframesExist,
    frameCount,
    fps,
    isInitializingAnimation,
    abortControllerForCaching,
    // keyBelowFrame,
    // keyAboveFrame,
    // isWithinKeyframeRange,
    // isWithinPendingKeyframeRange,
    pauseAnimation,
    removeKeyframe,
    resetForNewSequence,
    $reset,
    keyframes,
    setKeyframeNew,
    addNewSampleToCache,
    clearSample,
    updateSample,
    throttledUpdateSample,
    removeAnnotationsFromCache,
    getAnimationDelay,
    addNewAnnotationsArrayToCache,
    // keyframeRangesNew,
    keyframeAnnotations,
    mouse,
  };
});
