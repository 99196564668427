<template>
  <v-rect
    v-for="box in boxes"
    :key="box.obj_id"
    ref="samBoxes"
    :config="{
      fill: 'transparent',
      stroke: 'white',
      strokeWidth: strokeWidth,
      visible: true,
      x: box.box[0],
      y: box.box[1],
      width: box.box[2] - box.box[0],
      height: box.box[3] - box.box[1],
    }"
  />
</template>

<script setup>
import {
  ref, computed,
} from 'vue';
import { useSAM2Store } from '@/stores/useSAM2Store.js';
import { storeToRefs } from 'pinia';

// Props
const props = defineProps({
  stageScale: {
    type: Object,
    default: () => ({ x: 1, y: 1 }),
  },
  imageId: {
    type: Number,
    default: null,
  },
});

// Composables
const sam2Store = useSAM2Store();
const {
  sam2Options,
} = storeToRefs(sam2Store);

// Computed properties
const strokeWidth = computed(() => 2 / props.stageScale.x);

const boxes = computed(() => {
  const allBoxes = sam2Options.value.params.boxes;
  const filteredBoxes = allBoxes.filter((b) => b.imageId && b.imageId === props.imageId);
  return filteredBoxes;
});

</script>

<style>

</style>
