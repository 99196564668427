import { defineStore } from 'pinia';
import {
  ref,
} from 'vue';

export const useAIGTStore = defineStore('aigt', () => {
  const aigtServer = ref('');
  const isGenerating3dAnnotations = ref(false);
  const isSaving3dAnnotations = ref(false);
  const aigt3dAnnotations = ref([]);

  function $reset() {

  }

  function getFrameAIGT3dAnnotations(frame_image_id) {
    if (!frame_image_id) return [];
    return aigt3dAnnotations.value.filter((anno) => anno.image_id === frame_image_id);
  }

  return {
    aigtServer,
    isGenerating3dAnnotations,
    isSaving3dAnnotations,
    aigt3dAnnotations,
    getFrameAIGT3dAnnotations,
    $reset,
  };
});
