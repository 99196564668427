import { defineStore } from 'pinia';
import {
  ref, computed,
} from 'vue';

export const useSAM2Store = defineStore('sam2', () => {
  const sam2Server = ref('');
  const sam2Options = ref({
    enabled: false,
    mode: null,
    params: {
      pointType: null,
      clicks: [],
      box: [],
      boxes: [],
    },
  });
  const sam2Annotations = ref([]);
  const sam2Active = computed(() => sam2Options.value?.enabled);
  const isSAM2ClickMode = computed(() => sam2Options.value?.mode === 'hover_and_click');
  const isSAM2BoxMode = computed(() => sam2Options.value?.mode === 'drag_box');

  const clicksByObjId = computed(() => Object.groupBy(sam2Options.value.params.clicks, ({ obj_id }) => obj_id));
  const boxByObjId = computed(() => Object.groupBy(sam2Options.value.params.box, ({ obj_id }) => obj_id));

  const isInitializingState = ref(false);
  const hasInitState = ref(false);
  const initStatus = ref('');
  const initDetail = ref('');
  const initProgress = ref(0);
  const initTotal = ref(0);
  const initError = ref('');
  const initStartFrame = ref(1);
  const initEndFrame = ref(2);
  const isPropagating = ref(false);
  const propagateStatus = ref('');
  const propagateDetail = ref('');
  const propagateProgress = ref(0);
  const propagateTotal = ref(0);
  const shouldClearNextPoints = ref(true);
  const isCreatingAnnotations = ref(false);
  const propagateLastAnnotationIndex = ref(0);
  const reversePropagateEnabled = ref(false);
  const objects = ref([]);
  const selectedObject = ref(null);

  function $reset() {

  }

  function handleSAM2ImageClick(e, x, y, imageId) {
    if (sam2Options.value.params.pointType !== 'add' && sam2Options.value.params.pointType !== 'minus') {
      return;
    }

    if (sam2Options.value.enabled && (e.evt.button === 0 || e.evt.button === 2)) {
      e.evt.preventDefault();
      let clickType;
      if (e.evt.button === 0) {
        if (sam2Options.value.params.pointType === 'add') {
          clickType = 1;
        } else if (sam2Options.value.params.pointType === 'minus') {
          clickType = 0;
        }
      } else {
        clickType = 0;
      }
      const newSAMOptions = { ...sam2Options.value };
      newSAMOptions.params.clicks.push({
        x, y, clickType, imageId, obj_id: selectedObject.value,
      });
    }
  }

  function getFrameSAM2Annotations(frame_image_id) {
    if (!frame_image_id) return [];
    return sam2Annotations.value.filter((anno) => anno.image_id === frame_image_id);
  }

  return {
    sam2Server,
    sam2Options,
    sam2Active,
    sam2Annotations,
    isSAM2ClickMode,
    isSAM2BoxMode,
    clicksByObjId,
    boxByObjId,
    isInitializingState,
    hasInitState,
    initStatus,
    initDetail,
    initProgress,
    initTotal,
    initError,
    initStartFrame,
    initEndFrame,
    isPropagating,
    propagateStatus,
    propagateDetail,
    propagateProgress,
    propagateTotal,
    propagateLastAnnotationIndex,
    shouldClearNextPoints,
    isCreatingAnnotations,
    reversePropagateEnabled,
    objects,
    selectedObject,
    handleSAM2ImageClick,
    getFrameSAM2Annotations,
    $reset,
  };
});
