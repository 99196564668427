<template>
  <div class="filters">
    <div class="filters__header">
      <div class="left">
        <span>{{ instanceCount && selectedInstances && selectedInstances.length > 0 ? `Instances (${selectedInstances.length} / ${instanceCount})` :`Instances (${instanceCount})` }}</span>
      </div>
      <div class="right">
        <button class="button" @click="handleRefreshClicked">
          <SVGIcon
            :iconName="'refresh'"
            :width="'18px'"
            :height="'18px'"
          />Refresh
        </button>
        <button class="button delete" :disabled="selectedInstances && selectedInstances.length === 0" @click="handleStopClicked">
          <SVGIcon
            :iconName="'stop'"
            :width="'18px'"
            :height="'18px'"
          />
          Stop
        </button>
        <button class="button highlight" @click="handleStartClicked">
          <SVGIcon
            :iconName="'play'"
            :width="'18px'"
            :height="'18px'"
          />Start
        </button>
      </div>
    </div>
    <div class="filters__footer">
      <div class="left" />
      <div class="right" />
    </div>
  </div>
</template>

<script setup>
import { toRefs, watch } from 'vue';
import SVGIcon from '@/components/SVGIcon.vue';

const props = defineProps({
  name: { type: String, default: 'null' },
  state: { type: String, default: 'null' },
  status: { type: String, default: 'null' },
  ip: { type: String, default: 'null' },
  type: { type: String, default: 'null' },
  date: { type: String, default: 'null' },
  instanceCount: { type: Number, default: 0 },
  selectedInstances: { type: Array, default: () => [] },
});

const emit = defineEmits([
  'stop-clicked',
  'refresh-clicked',
  'start-clicked',
]);

const {
  name, state, status, ip, type,
  date, instanceCount, selectedInstances,
} = toRefs(props);

function handleStopClicked() {
  emit('stop-clicked');
}

function handleRefreshClicked() {
  emit('refresh-clicked');
}

function handleStartClicked() {
  emit('start-clicked');
}

</script>

<style lang="scss" scoped>
.filters{
  display: flex;
  flex-direction: column;

  &__header{
    display: flex;
    flex-direction: row;
    .left{
      width: 40%;
      max-width: 40%;

      span{
        font-weight: bold;
        font-size: 1.15rem;
      }
    }
    .right{
      display: flex;
      flex-direction: row;
      width: 60%;
      max-width: 60%;
      gap: 12px;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__footer{
    display: flex;
    flex-direction: row;

    .left{
      width: 50%;
      max-width: 50%;
    }
    .right{
      width: 50%;
      max-width: 50%;
      justify-content: flex-end;
    }
  }
}

.button {
    background: white;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 26px;
    padding: 0;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 1);
    padding: 4px 8px;
    gap: 2px;
    width: fit-content;

    @include themify() {
      color: themed('body-text-color')
    }

    &.highlight {
      background: var(--button-color);
      color: var(--button-text-color);

      svg {
        margin-right: 5px;
      }
    }

    &:hover {
      background: rgba(var(--color-primary-100-rgb), 0.5);
    }

    &.delete:not([disabled]) {
      @include themify() {
        background: themed('button-delete');
        color: themed('button-delete-text');
      }
    }

    &.delete[disabled] {
      border: none;
    }

    &.delete:hover:not([disabled]) {
      @include themify() {
        background: $color-red-300;
        color: themed('button-delete-text');
      }
    }
  }
</style>
