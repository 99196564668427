import {
  ref, computed, watch, onMounted, onUnmounted, nextTick, onBeforeUnmount,
} from 'vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import useAnnotationDatabase from '@/composables/annotationTool/useAnnotationDatabase.js';
import useTaskHistory from '@/composables/annotationTool/useTaskHistory.js';
import { useStore } from 'vuex';
import { useEditorStore } from '@/stores/useEditorStore.js';
import usePCD from '@/composables/annotationTool/usePCD.js';
import { storeToRefs } from 'pinia';

let submitType = ref('submit-all-annotations');
let imageFilters = ref(null);
let activeImageIndex = ref(-1);
let currentTaskImage = ref(null);
let pendingTaskImages = ref([]);
let isTaskComplete = ref(false);
let reviewSettings = null;
let remainingImagesCount = ref(0);
let totalImagesCount = ref(0);
let auditorType = ref('image');
let isFetchingNewImage = ref(false);

export default function useTasks({ reviewSettings: newReviewSettings } = {}) {
  const datastore = new DatastoreConnect();
  const store = useStore();

  if (newReviewSettings) {
    reviewSettings = newReviewSettings;
  }

  const editorStore = useEditorStore();
  const {
    internalImageObj,
    internalAnnotations,
  } = storeToRefs(editorStore);

  const {
    startTaskHistory,
    doneImages,
    doneImageOpen,
    getTaskDoneImagesByUser,
    switchToTaskImage,
    switchToNextTaskImage,
    switchToPreviousTaskImage,
  } = useTaskHistory(reviewSettings, internalImageObj, internalAnnotations, currentTaskImage, activeImageIndex);

  const {
    pcdAnnotations, createPCDFile,
  } = usePCD(internalImageObj.value);

  function startTask() {
    if (reviewSettings.value?.initialAuditorType) {
      auditorType.value = reviewSettings.value?.initialAuditorType;
    }

    watch(isTaskComplete, (isComplete) => {
      if (isComplete) {
        currentTaskImage.value = null;
        pendingTaskImages.value = [];
        internalImageObj.value = null;
        internalAnnotations.value = [];
      }
    });

    watch(currentTaskImage, async () => {
      if (hasReviewTask.value) {
        await getRemainingCount()
          .then(({ total_images, remaining_images }) => {
            remainingImagesCount.value = remaining_images;
            totalImagesCount.value = total_images;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });

    watch(currentTaskImage, async (newImage) => {
      if (newImage) {
        internalImageObj.value = newImage;
      } else {
        internalImageObj.value = null;
      }
    });

    watch(auditorType, async () => {
      isFetchingNewImage.value = true;

      pendingTaskImages.value = await getImageForTask(currentTaskImage.value?.id);
      if (pendingTaskImages.value?.length > 0) {
        currentTaskImage.value = pendingTaskImages.value[0];
      } else {
        currentTaskImage.value = null;
      }

      isFetchingNewImage.value = false;
    });

    watch(currentTaskImage, async () => {
      if (hasReviewTask.value) {
        doneImages.value = await getTaskDoneImagesByUser();
      }
    });

    onBeforeUnmount(() => {
      // Clear values or perform cleanup
      submitType = ref('submit-all-annotations');
      imageFilters = ref(null);
      activeImageIndex = ref(-1);
      currentTaskImage = ref(null);
      pendingTaskImages = ref([]);
      isTaskComplete = ref(false);
      reviewSettings = null;
      remainingImagesCount = ref(0);
      totalImagesCount = ref(0);
      auditorType = ref('image');
      isFetchingNewImage = ref(false);
    });

    startTaskHistory();
  }

  const { getAnnotationsForDatabase } = useAnnotationDatabase(internalImageObj, internalAnnotations);

  // Computed

  const sourceDataset = computed(() => {
    const datasets = store.state.datasets.datasetList;
    const dataset_id = reviewSettings.value?.reviewTask?.dataset_id;
    const dataset = datasets.find((d) => d.id === dataset_id);
    return dataset;
  });

  const sourceAnnotationSet = computed(() => {
    const source_annotation_set_id = reviewSettings.value?.reviewTask?.source_annotation_set_id;
    const sourceAnnotationSet = sourceDataset.value.annotation_sets.find((as) => as.id === source_annotation_set_id);
    if (sourceAnnotationSet) {
      return sourceAnnotationSet;
    }
    return null;
  });

  const hasSourceAnnotationSet = computed(() => Boolean(sourceAnnotationSet.value));

  const destinationAnnotationSet = computed(() => {
    const dest_annotation_set_id = reviewSettings.value?.reviewTask?.dest_annotation_set_id;
    return sourceDataset.value.annotation_sets.find((as) => as.id === dest_annotation_set_id);
  });

  const groups = computed(() => {
    if (sourceDataset.value?.groups) {
      return sourceDataset.value.groups;
    }
    return [];
  });

  const hasReviewTask = computed(() => reviewSettings.value.reviewTask?.id);

  const isAuditMode = computed(() => hasSourceAnnotationSet.value && (sourceAnnotationSet.value.id !== destinationAnnotationSet.value.id));

  // Methods
  async function getImageForTask(image_id) {
    let imageResult;
    if (hasReviewTask.value) {
      const params = {
        source_annotation_set_id: reviewSettings.value.reviewTask.source_annotation_set_id,
        review_task_id: reviewSettings.value.reviewTask.id,
        limit: 3,
        images_filter: { dataset_id: reviewSettings.value.reviewTask.dataset_id },
        annotations_filter: {},
        aggregate_annotations_filter: {},
      };
      if (imageFilters.value && imageFilters.value.images_filter) {
        params.images_filter = imageFilters.value.images_filter;
      }
      if (imageFilters.value && imageFilters.value.image_files_filter) {
        params.image_files_filter = imageFilters.value.image_files_filter;
      }
      if (imageFilters.value && imageFilters.value.annotations_filter) {
        params.annotations_filter = imageFilters.value.annotations_filter;
      }

      if (reviewSettings.value?.reviewTask.source_annotation_set_id) {
        params.aggregate_annotations_filter.aggregate_annotation_set_ids = [reviewSettings.value.reviewTask.source_annotation_set_id];
      }

      if (auditorType.value === 'annotation') {
        params.annotations_filter.images_with_annotation_set_ids = [reviewSettings.value.reviewTask.source_annotation_set_id];
      }

      if (image_id) {
        params.image_id = image_id;
      } else if (internalImageObj.value?.id) {
        params.current_image_id = internalImageObj.value.id;
      }

      if (Object.keys(params.annotations_filter).length === 0) {
        delete params.annotations_filter;
      }

      imageResult = await datastore.getLabellingImages(params)
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error.message);
          }
          return resp.result;
        })
        .catch((error) => {
          console.error(error);
          alert(`Failed to get labelling images\n${error.message}`);
        });

      if (!imageResult) {
        isTaskComplete.value = true;
      }
    } else {
      const params = {
        id: reviewSettings.value.reviewTask.image_id,
        annotation_set_id: reviewSettings.value.reviewTask.source_annotation_set_id,
      };
      imageResult = await datastore.getImage(params)
        .then((resp) => {
          if (resp.error !== undefined) {
            throw Error(resp.error.message);
          }
          return resp.result;
        })
        .catch((error) => {
          console.error(error);
          alert(`Failed to get labelling image\n${error.message}`);
        });
      imageResult = [imageResult];
    }

    return imageResult;
  }

  async function getRemainingCount() {
    return datastore.getReviewTaskRemainingCount({
      review_task_id: reviewSettings.value.reviewTask.id,
      dataset_id: reviewSettings.value.reviewTask.dataset_id,
    })
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch((error) => {
        throw error;
      });
  }

  async function setImageReviewStatusDone({ review_task_id, image_id }) {
    const params = { review_task_id, image_id };
    const resp = await datastore.setImageReviewStatusDone(params)
      .catch((error) => {
        console.log(error);
        alert('Failed to set image review status');
      });
    if (!resp || resp.error || !resp.result) {
      if (resp.error) {
        console.error(resp.error);
        alert(`Failed to set image review status\n${resp.error.message}`);
      }
    }
    return resp;
  }

  async function setupTaskImages() {
    // Get new image for task
    pendingTaskImages.value = await getImageForTask();
    if (pendingTaskImages.value?.length > 0) {
      currentTaskImage.value = pendingTaskImages.value[0];
    } else {
      currentTaskImage.value = null;
    }
  }

  async function skipTaskImage() {
    if (hasReviewTask.value && doneImageOpen.value) {
      switchToNextTaskImage();
      getTaskDoneImagesByUser()
        .then((newDoneImages) => {
          doneImages.value = newDoneImages;
        });
      return;
    }

    await datastore.deleteImageReviewStatus({
      review_task_id: reviewSettings.value.reviewTask.id,
      image_id: currentTaskImage.value.id,
    })
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch((error) => {
        console.error(error.message);
        throw error;
      });
    if (pendingTaskImages.value?.length > 1) {
      currentTaskImage.value = pendingTaskImages.value[1];
    }
    pendingTaskImages.value = await getImageForTask();
  }

  async function skipTaskSequence() {
    if (hasReviewTask.value && doneImageOpen.value) {
      switchToNextTaskImage();
      getTaskDoneImagesByUser()
        .then((newDoneImages) => {
          doneImages.value = newDoneImages;
        });
      return;
    }

    await datastore.deleteImageReviewStatus({
      review_task_id: reviewSettings.value.reviewTask.id,
      sequence_id: currentTaskImage.value.sequence_id,
    })
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch((error) => {
        console.error(error.message);
        throw error;
      });
    if (pendingTaskImages.value?.length > 1) {
      currentTaskImage.value = pendingTaskImages.value[1];
    }
    pendingTaskImages.value = await getImageForTask();
  }

  async function completeTaskSequence() {
    if (hasReviewTask.value && doneImageOpen.value) {
      switchToNextTaskImage();
      getTaskDoneImagesByUser()
        .then((newDoneImages) => {
          doneImages.value = newDoneImages;
        });
      return;
    }

    await datastore.setImageReviewStatusDone({
      review_task_id: reviewSettings.value.reviewTask.id,
      sequence_id: currentTaskImage.value.sequence_id,
    })
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch((error) => {
        console.error(error.message);
        throw error;
      });
    if (pendingTaskImages.value?.length > 1) {
      currentTaskImage.value = pendingTaskImages.value[1];
    }
    pendingTaskImages.value = await getImageForTask();
  }

  async function updateTaskImages() {
    // Get new image for task
    if (pendingTaskImages.value?.length > 1) {
      currentTaskImage.value = pendingTaskImages.value[1];
    } else {
      currentTaskImage.value = null;
    }

    pendingTaskImages.value = await getImageForTask();
    if (currentTaskImage.value === null && pendingTaskImages.value?.length > 0) {
      // New pending images were found
      currentTaskImage.value = pendingTaskImages.value[0];
    }
  }

  async function switchToImageID(image_id) {
    // Get new images from the given image_id
    pendingTaskImages.value = await getImageForTask(image_id);
    if (pendingTaskImages.value?.length > 0) {
      currentTaskImage.value = pendingTaskImages.value[0];
    } else {
      currentTaskImage.value = null;
    }
  }

  function switchToDoneImage(imageObj) {
    activeImageIndex.value = null;
    internalImageObj.value = imageObj;
    const reviewedAnnotations = internalImageObj.value.annotations.filter((anno) => anno.annotation_set_id === reviewSettings.value.reviewTask.dest_annotation_set_id);
    nextTick(() => { internalAnnotations.value = reviewedAnnotations; });
  }

  async function updateTaskImageFilters(newFilters) {
    this.imageFilters = newFilters;
    // Get new images for task
    pendingTaskImages.value = await getImageForTask(currentTaskImage.value?.id);
    if (pendingTaskImages.value?.length > 0) {
      currentTaskImage.value = pendingTaskImages.value[0];
    } else {
      currentTaskImage.value = null;
    }
  }

  function handleImageGroupChanged(group_id) {
    internalImageObj.value.group_id = group_id;
  }

  async function deleteTaskImageFromDataset(image_id) {
    const param = {
      image_ids: [image_id],
      dataset_id: sourceDataset.value.id,
    };
    const deletedResponse = await datastore.deleteImageFromDataset(param)
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch(async (error) => {
        console.error(error.message);
        await updateTaskImages();
        throw error;
      });

    if (!doneImageOpen.value) {
      await updateTaskImages();
    } else {
      switchToNextTaskImage();
      await getTaskDoneImagesByUser()
        .then((newDoneImages) => {
          doneImages.value = newDoneImages;
        });
    }

    return deletedResponse;
  }

  // async function handleAuditSubmitAllAnnotations(initialEditingAnnotations) {
  //   // Create/Update/Delete annotations in the destination set
  //   const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
  //   const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
  //   submitAuditAnnotationsToDatabase(initialEditingAnnotations, internalAnnotations.value, source_annotation_set_id, dest_annotation_set_id)
  //     .then((submittedAnnotations) => {
  //     // Create Reviews in database
  //       const createReviewPromises = [];
  //       if (submittedAnnotations.length > 0) {
  //         submittedAnnotations.forEach((anno) => {
  //           const newReview = createAnnotationReviewInDatabase({ annotation_id: anno.id });
  //           createReviewPromises.push(newReview);
  //         });
  //       } else {
  //         const newReview = createAnnotationReviewInDatabase({ annotation_id: null });
  //         createReviewPromises.push(newReview);
  //       }
  //       Promise.all(createReviewPromises);
  //     });
  // }

  // async function handleAuditSubmitAcceptedAnnotations(initialEditingAnnotations) {
  //   // Create/Update/Delete annotations in the destination set
  //   const verifiedAnnotations = internalAnnotations.value.filter((anno) => anno.reviewStatus === 'verified');
  //   const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
  //   const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
  //   submitAuditAnnotationsToDatabase(initialEditingAnnotations, verifiedAnnotations, source_annotation_set_id, dest_annotation_set_id)
  //     .then((submittedAnnotations) => {
  //       // Create Reviews in database
  //       const createReviewPromises = [];
  //       if (submittedAnnotations.length > 0) {
  //         submittedAnnotations.forEach((anno) => {
  //           const newReview = createAnnotationReviewInDatabase({ annotation_id: anno.id });
  //           createReviewPromises.push(newReview);
  //         });
  //       } else {
  //         const newReview = createAnnotationReviewInDatabase({ annotation_id: null });
  //         createReviewPromises.push(newReview);
  //       }
  //       Promise.all(createReviewPromises);
  //     });
  // }

  async function handleAuditSubmitAllAnnotations() {
    // Submit image audit to the database
    const initialAnnotations = internalImageObj.value.annotations;
    const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
    const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
    const annotations = getAnnotationsForDatabase(initialAnnotations, internalAnnotations.value, source_annotation_set_id, dest_annotation_set_id, doneImageOpen.value);
    const response = await datastore.submitAuditManualAnnotationTask(
      {
        create_annotations: annotations.create_annotations,
        update_annotations: annotations.update_annotations,
        delete_annotations: annotations.delete_annotations,
        review_task_id: reviewSettings.value.reviewTask?.id,
        review_session_id: reviewSettings.value.reviewSession?.id,
        image_id: internalImageObj.value.id,
        annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
        group_id: internalImageObj.value.group_id,
      },
    );

    if (response.error) {
      alert("Failed to submit");
      throw Error("Failed to submit");
    }

    if (pcdAnnotations.value.length) {
      await submitPCDFile();
    }

    if (!hasReviewTask.value) {
      return;
    }

    // Next done image
    if (doneImageOpen.value) {
      switchToNextTaskImage();
      await getTaskDoneImagesByUser()
        .then((newDoneImages) => {
          doneImages.value = newDoneImages;
        });
      return;
    }

    // Next Pending Task Image
    await updateTaskImages();
  }

  async function handleAuditSubmitAcceptedAnnotations() {
    // Submit image audit to the database
    const initialAnnotations = internalImageObj.value.annotations;
    const verifiedAnnotations = internalAnnotations.value.filter((anno) => anno.reviewStatus === 'verified');
    const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
    const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
    const annotations = getAnnotationsForDatabase(initialAnnotations, verifiedAnnotations, source_annotation_set_id, dest_annotation_set_id, doneImageOpen.value);
    const response = await datastore.submitAuditManualAnnotationTask(
      {
        create_annotations: annotations.create_annotations,
        update_annotations: annotations.update_annotations,
        delete_annotations: annotations.delete_annotations,
        review_task_id: reviewSettings.value.reviewTask?.id,
        review_session_id: reviewSettings.value.reviewSession?.id,
        image_id: internalImageObj.value.id,
        annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
        group_id: internalImageObj.value.group_id,
      },
    );

    if (response.error) {
      alert("Failed to submit");
      throw Error("Failed to submit");
    }

    if (!hasReviewTask.value) {
      return;
    }

    // Next done image
    if (doneImageOpen.value) {
      switchToNextTaskImage();
      await getTaskDoneImagesByUser()
        .then((newDoneImages) => {
          doneImages.value = newDoneImages;
        });
      return;
    }

    // Next Pending Task Image
    await updateTaskImages();
  }

  async function handleFrameAuditSubmitAllAnnotations(imageObj) {
    // Submit image audit to the database
    const initialAnnotations = imageObj.annotations;
    const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
    const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
    const annotations = getAnnotationsForDatabase(initialAnnotations, internalAnnotations.value, source_annotation_set_id, dest_annotation_set_id, doneImageOpen.value);
    const response = await datastore.submitAuditManualAnnotationTask(
      {
        create_annotations: annotations.create_annotations,
        update_annotations: annotations.update_annotations,
        delete_annotations: annotations.delete_annotations,
        review_task_id: reviewSettings.value.reviewTask?.id,
        review_session_id: reviewSettings.value.reviewSession?.id,
        image_id: imageObj.id,
        annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
        group_id: imageObj.group_id,
        sequence_id: imageObj.sequence_id,
      },
    );

    if (response.error) {
      alert("Failed to submit");
      throw Error("Failed to submit");
    }

    if (pcdAnnotations.value.length) {
      await submitPCDFile();
    }

    // Next Pending Task Image
    // await updateTaskImages();
  }

  async function handleFrameAuditSubmitAcceptedAnnotations(imageObj) {
    // Submit image audit to the database
    const initialAnnotations = imageObj.annotations;
    const verifiedAnnotations = internalAnnotations.value.filter((anno) => anno.reviewStatus === 'verified');
    const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
    const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
    const annotations = getAnnotationsForDatabase(initialAnnotations, verifiedAnnotations, source_annotation_set_id, dest_annotation_set_id, doneImageOpen.value);
    const response = await datastore.submitAuditManualAnnotationTask(
      {
        create_annotations: annotations.create_annotations,
        update_annotations: annotations.update_annotations,
        delete_annotations: annotations.delete_annotations,
        review_task_id: reviewSettings.value.reviewTask?.id,
        review_session_id: reviewSettings.value.reviewSession?.id,
        image_id: imageObj.id,
        annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
        group_id: imageObj.group_id,
        sequence_id: imageObj.sequence_id,
      },
    );

    if (response.error) {
      alert("Failed to submit");
      throw Error("Failed to submit");
    }

    if (pcdAnnotations.value.length) {
      await submitPCDFile();
    }

    // Next Pending Task Image
    // await updateTaskImages();
  }

  async function handleLabellingSubmitAnnotations() {
    // Submit image labelling to the database
    const initialAnnotations = internalImageObj.value.annotations;
    const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
    const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
    const annotations = getAnnotationsForDatabase(initialAnnotations, internalAnnotations.value, source_annotation_set_id, dest_annotation_set_id, doneImageOpen.value);
    const response = await datastore.submitAuditManualAnnotationTask(
      {
        create_annotations: annotations.create_annotations,
        update_annotations: annotations.update_annotations,
        delete_annotations: annotations.delete_annotations,
        review_task_id: reviewSettings.value.reviewTask.id,
        review_session_id: reviewSettings.value.reviewSession?.id,
        image_id: internalImageObj.value.id,
        annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
        group_id: internalImageObj.value.group_id,
      },
    );

    if (response.error) {
      alert("Failed to submit");
      throw Error("Failed to submit");
    }

    // Next done image
    if (doneImageOpen.value) {
      switchToNextTaskImage();
      await getTaskDoneImagesByUser()
        .then((newDoneImages) => {
          doneImages.value = newDoneImages;
        });
      return;
    }

    // Next Pending Task Image
    await updateTaskImages();
  }

  async function handleFrameLabellingSubmitAnnotations(imageObj) {
    // Submit image frame labelling to the database
    const initialAnnotations = imageObj.annotations;
    const source_annotation_set_id = reviewSettings.value.reviewTask.source_annotation_set_id;
    const dest_annotation_set_id = reviewSettings.value.reviewTask.dest_annotation_set_id;
    const annotations = getAnnotationsForDatabase(initialAnnotations, internalAnnotations.value, source_annotation_set_id, dest_annotation_set_id, doneImageOpen.value);
    const response = await datastore.submitAuditManualAnnotationTask(
      {
        create_annotations: annotations.create_annotations,
        update_annotations: annotations.update_annotations,
        delete_annotations: annotations.delete_annotations,
        review_task_id: reviewSettings.value.reviewTask.id,
        review_session_id: reviewSettings.value.reviewSession?.id,
        image_id: imageObj.id,
        annotation_set_id: reviewSettings.value.reviewTask.dest_annotation_set_id,
        group_id: imageObj.group_id,
        sequence_id: imageObj.sequence_id,
      },
    );

    if (response.error) {
      alert("Failed to submit");
      throw Error("Failed to submit");
    }

    // Next Pending Task Image
    // await updateTaskImages();
  }

  async function submitPCDFile() {
    const annotation_id = pcdAnnotations.value[0].id;
    const newFile = createPCDFile();
    const dataConnect = new DatastoreConnect();
    await dataConnect.updateAnnotationFile(annotation_id, new Blob([newFile], { type: 'text/plain' }));
  }

  function acceptAllAnnotations() {
    internalAnnotations.value.forEach((anno) => {
      anno.reviewStatus = 'verified';
    });
  }

  async function getTaskSequences() {
    const dataConnect = new DatastoreConnect();
    return dataConnect.getFilteredImages({
      combine_sequence_frames: true,
      sort_by: "id",
      images_filter: {
        dataset_id: sourceDataset.value.id,
      },
      image_files_filter: {},
      annotations_filter: {},
      only_sequences: true,
      // dataset_id: sourceDataset.value.id,
    })
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error);
        }
        return resp.result;
      })
      .catch((error) => {
        throw error;
      });
  }

  return {
    startTask,
    submitType,
    internalImageObj,
    internalAnnotations,
    currentTaskImage,
    pendingTaskImages,
    isTaskComplete,
    sourceDataset,
    sourceAnnotationSet,
    hasSourceAnnotationSet,
    destinationAnnotationSet,
    isAuditMode,
    groups,
    remainingImagesCount,
    totalImagesCount,
    auditorType,
    isFetchingNewImage,
    imageFilters,
    hasReviewTask,
    setImageReviewStatusDone,
    setupTaskImages,
    skipTaskImage,
    skipTaskSequence,
    completeTaskSequence,
    updateTaskImages,
    updateTaskImageFilters,
    handleImageGroupChanged,
    deleteTaskImageFromDataset,
    handleAuditSubmitAllAnnotations,
    handleAuditSubmitAcceptedAnnotations,
    handleFrameAuditSubmitAllAnnotations,
    handleFrameAuditSubmitAcceptedAnnotations,
    handleLabellingSubmitAnnotations,
    handleFrameLabellingSubmitAnnotations,
    acceptAllAnnotations,
    switchToImageID,
    switchToDoneImage,
    getTaskSequences,
    // Task history
    activeImageIndex,
    doneImages,
    doneImageOpen,
    getTaskDoneImagesByUser,
    switchToTaskImage,
    switchToNextTaskImage,
    switchToPreviousTaskImage,
  };
}
