<template>
  <v-circle
    v-for="(c, index) in clicks"
    :key="index"
    :config="{
      x: c.x,
      y: c.y,
      radius: nodeRadius,
      fill: getNodeFill(c),
      stroke: nodeStroke,
      strokeWidth: nodeStrokeWidth,
      draggable: false,
    }"
    :opacity="1"
  />
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useSAM2Store } from '@/stores/useSAM2Store.js';

// Props
const props = defineProps({
  stageScale: {
    type: Object,
    default: () => ({ x: 1, y: 1 }),
  },
  imageId: {
    type: Number,
    default: null,
  },
});

const sam2Store = useSAM2Store();
const {
  sam2Options,
} = storeToRefs(sam2Store);

const clicks = computed(() => {
  const allClicks = sam2Options.value.params.clicks;
  const filteredClicks = allClicks.filter((c) => c.imageId && c.imageId === props.imageId);
  return filteredClicks;
});
const nodeRadius = computed(() => 5 / props.stageScale.x);
const nodeStroke = computed(() => 'blue');
const nodeStrokeWidth = computed(() => 1 / props.stageScale.x);

const getNodeFill = (click) => (click.clickType === 0 ? '#FF1744' : '#00E676');
</script>

<style>

</style>
