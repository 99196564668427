<template>
  <div id="samToolbar" class="sam-sidebar">
    <!-- Server -->
    <div class="sam-sidebar__section">
      <h2 class="sam-sidebar__title">Server</h2>
      <div v-if="fetchingCloudInstances" id="loading-servers" class="servers__loading">
        <div class="loader-ring"><div /><div /><div /><div /></div>
        <span>Loading Servers</span>
      </div>
      <div v-if="servers && servers.length === 0" class="servers__not-found">
        <span>No AIGT Servers Found</span>
      </div>
      <div v-if="servers && servers.length > 0" id="servers" class="form__control-group">
        <!-- <label>Group</label> -->
        <select
          id="servers-select"
          v-model="sam2Server"
          class="select"
          required
        >
          <option :key="'servers-null'" :value="''">Select Server</option>
          <option v-for="option in servers" :key="`servers-${option.id}`" :value="option.ip">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Initialize State -->
    <div v-if="servers && servers.length > 0" class="sam-sidebar__section init-state">
      <h2 class="sam-sidebar__title">
        Initialize State
        <div v-if="hasInitState" class="sam-ready">
          <span class="sam-ready__icon" />
        </div>
        <div v-else-if="initError" class="sam-error" :title="initError">
          <span class="sam-error__icon" />
        </div>
      </h2>
      <div class="init-state__inputs">
        <v-number-input
          v-model="initStartFrame"
          :min="1"
          :reverse="false"
          :height="24"
          :clientHeight="24"
          :width="120"
          controlVariant="stacked"
          label="Start Frame"
          density="compact"
          variant="outlined"
          :hide-details="true"
          :disabled="isInitializingState || hasInitState"
        />
        <v-number-input
          v-model="initEndFrame"
          :min="initStartFrame + 1"
          :max="numFrames"
          :reverse="false"
          :height="24"
          :clientHeight="24"
          :width="120"
          controlVariant="stacked"
          label="End Frame"
          density="compact"
          variant="outlined"
          :hide-details="true"
          :disabled="isInitializingState || hasInitState"
        />
      </div>
      <template v-if="isInitializingState || hasInitState">
        <button
          v-if="hasInitState"
          class="button button-sm sam-toolbar__button _secondary"
          :disabled="!hasInitState"
          @click="handleSAMInitReset"
        >
          Reset State
        </button>
      </template>
      <button
        v-if="!hasInitState"
        class="button button-sm sam-toolbar__button"
        :class="{'button-spinner': isInitializingState}"
        :disabled="!sam2Server || isInitializingState"
        :title="`Init State`"
        @click="handleInitState"
      >
        {{ isInitializingState ? initDetail : "Initialize State" }}
      </button>
    </div>

    <!-- Controls -->
    <!-- <div v-if="sam2Server && hasInitState" class="sam-sidebar__section controls">
      <h2 class="sam-sidebar__title">Controls</h2>
      <div class="controls__buttons">
        <div class="sam-toolbar__button-container">
          <IconButton
            id="SAMhoverAndClickToolAddBtn"
            aria-label="SAM Hover and Click (Add)"
            class="sam-toolbar__button"
            :class="{active: pointType === 'add'}"
            :icon="'cursor'"
            :showHover="true"
            :type="''"
            :width="20"
            :height="20"
            :title="'SAM Hover and Click (Left Click: positive point, Right Click: negative point)'"
            :disabled="!hasInitState"
            @click="handleSAMHoverAndClickAdd"
          />
          <SVGIcon
            id="SAMhoverAndClickToolBtn"
            class="sam-toolbar__button-pointer-overlay"
            :iconName="'add_box_outlined'"
          />
        </div>
        <div class="sam-toolbar__button-container">
          <IconButton
            id="SAMhoverAndClickToolMinusBtn"
            aria-label="SAM Hover and Click (Minus)"
            class="sam-toolbar__button"
            :class="{active: pointType === 'minus'}"
            :icon="'cursor'"
            :showHover="true"
            :type="''"
            :width="20"
            :height="20"
            :title="'SAM Hover and Click (Minus)'"
            :disabled="!hasInitState"
            @click="handleSAMHoverAndClickMinus"
          />
          <SVGIcon
            id="SAMhoverAndClickToolBtn"
            class="sam-toolbar__button-pointer-overlay"
            :iconName="'minus_box_outlined'"
          />
        </div>
        <IconButton
          id="SAMhoverAndClickToolBtn"
          aria-label="SAM Box Tool"
          class="sam-toolbar__button"
          :class="{active: pointType === 'box'}"
          :icon="'highlight_alt'"
          :showHover="true"
          :type="''"
          :title="'SAM Box Tool'"
          :width="20"
          :height="20"
          :disabled="!hasInitState"
          @click="handleSAMAddBox"
        />
        <IconButton
          id="SAMClearAllBtn"
          aria-label="Clear All"
          class="sam-toolbar__button"
          :icon="'clear_all'"
          :showHover="true"
          :type="''"
          :title="'Clear All'"
          :width="20"
          :height="20"
          :disabled="!hasInitState"
          @click="handleSAMRemoveObject"
        />
      </div>
    </div> -->

    <!-- Objects -->
    <div v-if="sam2Server && hasInitState" class="sam-sidebar__section objects">
      <h2 class="sam-sidebar__title">
        Select Objects
        <IconButton
          id="SAMAddObjectBtn"
          aria-label="Add Object"
          class="sam-toolbar__button"
          :icon="'add'"
          :showHover="true"
          :type="''"
          :title="'Add Object'"
          :width="20"
          :height="20"
          @click="handleSAMAddObject"
        />
      </h2>
      <div class="objects__cards scrollbar">
        <div
          v-for="(obj_id, i) in objects"
          :key="obj_id"
          class="objects-card"
          :class="{_selected: selectedObject === obj_id}"
          @click="selectedObject = obj_id"
        >
          <h2 class="objects-card__title">Object {{ i + 1 }}</h2>
          <template v-if="selectedObject === obj_id">
            <div class="objects-card__buttons">
              <div class="sam-toolbar__button-container">
                <IconButton
                  id="SAMhoverAndClickToolAddBtn"
                  aria-label="SAM Hover and Click (Add)"
                  class="sam-toolbar__button"
                  :class="{active: pointType === 'add'}"
                  :icon="'cursor'"
                  :showHover="true"
                  :type="''"
                  :width="20"
                  :height="20"
                  :title="'SAM Hover and Click (Left Click: positive point, Right Click: negative point)'"
                  :disabled="!hasInitState"
                  @click="handleSAMHoverAndClickAdd"
                />
                <SVGIcon
                  id="SAMhoverAndClickToolBtn"
                  class="sam-toolbar__button-pointer-overlay"
                  :iconName="'add_box_outlined'"
                />
              </div>
              <div class="sam-toolbar__button-container">
                <IconButton
                  id="SAMhoverAndClickToolMinusBtn"
                  aria-label="SAM Hover and Click (Minus)"
                  class="sam-toolbar__button"
                  :class="{active: pointType === 'minus'}"
                  :icon="'cursor'"
                  :showHover="true"
                  :type="''"
                  :width="20"
                  :height="20"
                  :title="'SAM Hover and Click (Minus)'"
                  :disabled="!hasInitState"
                  @click="handleSAMHoverAndClickMinus"
                />
                <SVGIcon
                  id="SAMhoverAndClickToolBtn"
                  class="sam-toolbar__button-pointer-overlay"
                  :iconName="'minus_box_outlined'"
                />
              </div>
              <IconButton
                id="SAMhoverAndClickToolBtn"
                aria-label="SAM Box Tool"
                class="sam-toolbar__button"
                :class="{active: pointType === 'box'}"
                :icon="'highlight_alt'"
                :showHover="true"
                :type="''"
                :title="'SAM Box Tool'"
                :width="20"
                :height="20"
                :disabled="!hasInitState"
                @click="handleSAMAddBox"
              />
              <!-- <IconButton
                id="SAMUndoBtn"
                aria-label="Undo"
                class="sam-toolbar__button"
                :icon="'undo'"
                :showHover="true"
                :type="''"
                :title="'Undo'"
                :width="20"
                :height="20"
                :disabled="!hasInitState"
                @click="handleSAMUndo"
              /> -->
              <button
                v-if="hasInitState"
                class="button button-sm sam-toolbar__button _secondary flex-icon-button ms-auto"
                aria-label="Clear Object"
                :title="'Clear Object'"
                :disabled="!hasInitState"
                @click="handleSAMRemoveObject(obj_id)"
              >
                <SVGIcon
                  :iconName="'delete'"
                  :width="16"
                  :height="16"
                  class="me-1"
                />
                Clear
              </button>
            </div>
          </template>
          <template v-else>
            <div class="objects-card__buttons">
              <button
                class="button button-sm sam-toolbar__button _secondary flex-icon-button objects-card__edit-button"
                aria-label="Edit Object"
                :title="'Edit Object'"
                :disabled="!hasInitState"
                @click="selectedObject = obj_id"
              >
                <SVGIcon
                  :iconName="'edit'"
                  :width="16"
                  :height="16"
                  class="me-1"
                />
                Edit Object {{ i + 1 }}
              </button>
              <button
                class="button button-sm sam-toolbar__button _secondary flex-icon-button"
                aria-label="Clear Object"
                :title="'Clear Object'"
                :disabled="!hasInitState"
                @click="handleSAMRemoveObject(obj_id)"
              >
                <SVGIcon
                  :iconName="'delete'"
                  :width="16"
                  :height="16"
                  class="me-1"
                />
                Clear
              </button>
            </div>
          </template>
        </div>
      </div>
      <button
        v-if="hasInitState"
        class="button button-sm sam-toolbar__button _secondary flex-icon-button"
        :disabled="!hasInitState"
        @click="handleSAMReset"
      >
        <SVGIcon
          :iconName="'replay'"
          :width="16"
          :height="16"
          class="me-1"
        />
        Start Over
      </button>
    </div>

    <!-- Propagate -->
    <div v-if="sam2Server && hasInitState" class="sam-sidebar__section propagate">
      <h2 class="sam-sidebar__title">Propagate</h2>
      <div class="propagate__buttons">
        <button
          class="button button-sm sam-toolbar__button propagate__action-button"
          :class="{'button-spinner': isPropagating}"
          :disabled="!hasInitState || isPropagating || sam2Annotations.length === 0"
          :title="`Propagate`"
          @click="handlePropagate"
        >
          {{ isPropagating ? propagateDetail : "Propagate" }}
        </button>
        <IconButton
          id="SAMReversePropagateBtn"
          v-model="reversePropagateEnabled"
          aria-label="Reverse Propagate"
          class="sam-toolbar__button"
          :icon="'swap_horiz'"
          :showHover="true"
          :type="'toggle'"
          :title="'Reverse Propagate'"
          :width="20"
          :height="20"
          :disabled="!hasInitState"
        />
      </div>
    </div>

    <!-- Finish -->
    <div v-if="sam2Server && hasInitState" class="sam-sidebar__section propagate">
      <h2 class="sam-sidebar__title">Finish</h2>
      <button
        class="button button-sm sam-toolbar__button"
        :class="{'button-spinner': isCreatingAnnotations}"
        :disabled="!hasInitState || isPropagating || isCreatingAnnotations || sam2Annotations.length <= 0"
        :title="`Propagate`"
        @click="handleCreateSAM2Annotations"
      >
        Create Annotations
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  ref, watch, computed, onMounted, onUnmounted,
} from 'vue';
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import { useSAM2Store } from '@/stores/useSAM2Store.js';
import { storeToRefs } from 'pinia';
import { useEditorStore } from '@/stores/useEditorStore.js';
import { useEditorControlsStore } from '@/stores/useEditorControlsStore.js';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { v4 as uuidv4 } from 'uuid';
import { useTimeoutPoll } from '@vueuse/core';
import useTasks from '@/composables/annotationTool/useTasks.js';

// Props
const props = defineProps({

});

const emit = defineEmits([]);

const sam2Store = useSAM2Store();
const {
  sam2Server,
  sam2Options,
  sam2Active,
  sam2Annotations,
  isInitializingState,
  hasInitState,
  initStatus,
  initDetail,
  initProgress,
  initTotal,
  initError,
  initStartFrame,
  initEndFrame,
  isPropagating,
  propagateStatus,
  propagateDetail,
  propagateProgress,
  propagateTotal,
  propagateLastAnnotationIndex,
  shouldClearNextPoints,
  isCreatingAnnotations,
  reversePropagateEnabled,
  objects,
  selectedObject,
  clicksByObjId,
  boxByObjId,
} = storeToRefs(sam2Store);

const pointType = computed(() => sam2Options.value.params.pointType);

const servers = ref(null);
const fetchingCloudInstances = ref(false);

const clicks = computed(() => [...sam2Options.value.params.clicks]);
watch(clicks, (newClicks, oldClicks) => {
  const clicksToAdd = newClicks.filter((click) => !oldClicks.includes(click));
  if (clicksToAdd.length > 0) {
    handleAddNewPointsOrBox({ clicks: clicksToAdd });
  }
}, { deep: true });

const box = computed(() => [...sam2Options.value.params.box]);
watch(box, async (newBox) => {
  if (newBox && newBox.length > 0) {
    await handleAddNewPointsOrBox({ box: newBox });
    sam2Options.value.params.box = [];
  }
}, { deep: true });

const editorStore = useEditorStore();
const {
  sequenceId,
  labels,
} = storeToRefs(editorStore);

const { selectedLabel } = storeToRefs(useEditorControlsStore());

const visualizationStore = useViewerVisualizationsStore();
const {
  samples,
  frame,
} = storeToRefs(visualizationStore);
const { addNewAnnotationsArrayToCache } = visualizationStore;
const numFrames = computed(() => samples.value.length);
watch(numFrames, () => {
  if (numFrames.value && numFrames.value > 0) {
    initEndFrame.value = numFrames.value;
  }
}, { immediate: true });
watch(frame, () => {
  if (!hasInitState.value) {
    initStartFrame.value = frame.value;
  }
}, { immediate: true });

const {
  destinationAnnotationSet,
} = useTasks();

const { isActive: isFetchInitStatusActive, pause: pauseFetchInitStatus, resume: resumeFetchInitStatus } = useTimeoutPoll(fetchInitStatus, 500);
const { isActive: isFetchPropagateActive, pause: pauseFetchPropagateStatus, resume: resumeFetchPropagateStatus } = useTimeoutPoll(fetchPropagateStatus, 500);
const { isActive: isFetchAnnotationsActive, pause: pauseFetchAnnotationsStatus, resume: resumeFetchAnnotationsStatus } = useTimeoutPoll(fetchAnnotationsStatus, 1000);

onMounted(async () => {
  servers.value = await listCloudInstances();
  if (servers.value.length > 0) {
    sam2Server.value = servers.value[0].ip;
  }

  if (objects.value.length === 0) {
    const uuid = uuidv4();
    objects.value.push(uuid);
    selectedObject.value = uuid;
  }
});

onUnmounted(async () => {
  pauseFetchInitStatus();
  pauseFetchPropagateStatus();
  pauseFetchAnnotationsStatus();
});

async function handleSAMReset() {
  propagateStatus.value = '';
  propagateDetail.value = '';
  propagateProgress.value = 0;
  propagateTotal.value = 0;

  // Reset SAM2 Controls
  sam2Options.value.params.pointType = null;
  sam2Options.value.params.clicks = [];
  sam2Options.value.params.box = [];
  sam2Options.value.params.boxes = [];
  objects.value = [];
  selectedObject.value = null;
  handleSAMAddObject();
  // Reset SAM2 annotations
  sam2Annotations.value = [];
  // Reset SAM2 State
  const isResetSuccessful = await handleResetSAMState();
  console.log("reset successful:", isResetSuccessful);
}

async function handleSAMInitReset() {
  initStatus.value = '';
  initDetail.value = '';
  initProgress.value = 0;
  initTotal.value = 0;
  initError.value = '';
  propagateStatus.value = '';
  propagateDetail.value = '';
  propagateProgress.value = 0;
  propagateTotal.value = 0;

  // Reset SAM2 Controls
  sam2Options.value.params.pointType = null;
  sam2Options.value.params.clicks = [];
  sam2Options.value.params.box = [];
  sam2Options.value.params.boxes = [];
  objects.value = [];
  selectedObject.value = null;
  handleSAMAddObject();
  // Reset SAM2 annotations
  sam2Annotations.value = [];
  // Reset SAM2 State
  const isResetSuccessful = await handleResetSAMState();
  console.log("reset successful:", isResetSuccessful);

  if (isResetSuccessful) {
    hasInitState.value = false;
  }
}

function handleSAMHoverAndClickAdd() {
  if (sam2Options.value.mode === 'hover_and_click' && sam2Options.value.params.pointType === 'add') {
    sam2Options.value.mode = null;
    sam2Options.value.params.pointType = null;
  } else {
    sam2Options.value.mode = 'hover_and_click';
    sam2Options.value.params.pointType = 'add';
  }
}

function handleSAMHoverAndClickMinus() {
  if (sam2Options.value.mode === 'hover_and_click' && sam2Options.value.params.pointType === 'minus') {
    sam2Options.value.mode = null;
    sam2Options.value.params.pointType = null;
  } else {
    sam2Options.value.mode = 'hover_and_click';
    sam2Options.value.params.pointType = 'minus';
  }
}

function handleSAMAddBox() {
  if (sam2Options.value.mode === 'drag_box' && sam2Options.value.params.pointType === 'box') {
    sam2Options.value.mode = null;
    sam2Options.value.params.pointType = null;
  } else {
    sam2Options.value.mode = 'drag_box';
    sam2Options.value.params.pointType = 'box';
  }
}

async function handleInitStateWs() {
  isInitializingState.value = true;

  const socket = new WebSocket(`ws://${sam2Server.value}:80/ws/aigt/init_state?sequence_id=${sequenceId.value}&start_frame=${initStartFrame.value - 1}&end_frame=${initEndFrame.value - 1}`);

  socket.onopen = () => {
    console.log('WebSocket connection established');
  };

  socket.onmessage = (event) => {
    try {
      const message = JSON.parse(event.data);
      console.log('Received:', message);

      if (message?.progress) {
        initProgress.value = message.progress;
      }
      if (message?.total) {
        initTotal.value = message.total;
      }
      if (message?.status) {
        initStatus.value = message.status;
      }
      if (message?.detail) {
        initDetail.value = message.detail;
      }
      if (message.status === "complete") {
        isInitializingState.value = false;
        hasInitState.value = true;
      }
      if (message.status === "error") {
        isInitializingState.value = false;
        hasInitState.value = false;
        initError.value = message.detail;
      }
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
    isInitializingState.value = false;
    initError.value = error;
  };

  socket.onclose = (event) => {
    isInitializingState.value = false;
    if (event.wasClean) {
      console.log(`WebSocket closed cleanly, code=${event.code}, reason=${event.reason}`);
    } else {
      console.error('WebSocket connection closed unexpectedly');
    }
  };
}

async function handleInitState({ clicks = null, box = null }) {
  console.log('handleInitState');
  isInitializingState.value = true;

  // Request payload
  const requestData = {
    sequence_id: sequenceId.value,
    start_frame: initStartFrame.value - 1,
    end_frame: initEndFrame.value - 1,
  };

  // Sending a POST request
  const result = await fetch(`aigt/init_state?ip=${sam2Server.value}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error starting init state`);
      }
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      isInitializingState.value = false;
      initError.value = error;
      return null;
    });

  // Parse the JSON response
  console.log("result:", result);

  if (result && !isFetchInitStatusActive.value) {
    resumeFetchInitStatus();
  }
}

async function handlePropagate() {
  console.log('handlePropagate');
  isPropagating.value = true;
  propagateLastAnnotationIndex.value = 0;
  propagateStatus.value = '';
  propagateDetail.value = '';
  propagateProgress.value = 0;
  propagateTotal.value = 0;

  // Request payload
  const requestData = {
    start_frame_idx: 0,
    max_frame_num_to_track: (initEndFrame.value - initStartFrame.value) - 1,
    reverse: reversePropagateEnabled.value,
  };

  // Sending a POST request
  const result = await fetch(`aigt/propagate?ip=${sam2Server.value}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error starting propagation`);
      }
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      isPropagating.value = false;
      return null;
    });

  // Parse the JSON response
  console.log("result:", result);

  if (result && !isFetchPropagateActive.value) {
    resumeFetchPropagateStatus();
    resumeFetchAnnotationsStatus();
  }
}

async function handleAddNewPointsOrBox({ clicks = null, box = null }) {
  console.log('handleAddNewPointsOrBox');

  let points;
  let labels;
  if (clicks) {
    points = clicks.map((click) => [click.x, click.y]);
    labels = clicks.map((click) => click.clickType);
  }

  const obj_id = selectedObject.value;

  // Request payload
  const requestData = {
    ann_frame_idx: (frame.value - initStartFrame.value),
    ann_obj_id: obj_id,
    points: clicks ? points : undefined,
    box: box || undefined,
    labels: clicks ? labels : undefined,
    clear_old_points: shouldClearNextPoints.value || box !== null,
    normalize_coords: true,
  };

  // Sending a POST request
  const result = await fetch(`aigt/add_new_points_or_box?ip=${sam2Server.value}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error adding new point or box`);
      }
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      alert(err);
      return null;
    });

  if (result && shouldClearNextPoints.value) {
    shouldClearNextPoints.value = false;
  }

  // console.log(result);
  if (!result || result.length === 0) {
    return null;
  }

  // Clear any points if a box was added
  if (box !== null) {
    sam2Options.value.params.clicks = sam2Options.value.params.clicks.filter((click) => click.obj_id !== obj_id);
  }

  result.forEach((resultAnno) => {
    console.log(resultAnno);
    if (resultAnno?.polygon && resultAnno?.obj_id && resultAnno?.frame_idx >= 0) {
    // console.log(result.polygon);
      const newAnnotationPolygon = {
        id: uuidv4(),
        label_index: selectedLabel.value.index,
        label_id: selectedLabel.value.id,
        label_name: selectedLabel.value.name,
        type: "seg",
        polygon: JSON.stringify(resultAnno.polygon),
        score: 1,
        reviewStatus: 'verified',
        image_id: samples.value[(initStartFrame.value - 1) + resultAnno.frame_idx],
        annotation_set_id: destinationAnnotationSet.value?.id,
        obj_id: resultAnno.obj_id,
        pending: true,
      };
      console.log(newAnnotationPolygon);

      // Add the new annotation. Merge to existing annotation of the same object if it exists
      sam2Annotations.value = [
        ...sam2Annotations.value.filter((anno) => {
          const isSameImage = anno.image_id === samples.value[(initStartFrame.value - 1) + resultAnno.frame_idx];
          const isSameObject = anno.obj_id === resultAnno.obj_id;
          return !(isSameImage && isSameObject);
        }),
        newAnnotationPolygon,
      ];
    }
  });

  // Parse the JSON response
  console.log("result:", result);
  return result;
}

async function handlePropagateWs() {
  isPropagating.value = true;

  const socket = new WebSocket(`ws://${sam2Server.value}:80/ws/aigt/propagate?start_frame_idx=${0}&max_frame_num_to_track=${samples.value.length}&reverse=${false}`);

  socket.onopen = () => {
    console.log('WebSocket connection established');
  };

  socket.onmessage = (event) => {
    try {
      const message = JSON.parse(event.data);
      console.log('Received:', message);

      // if (message.status === "complete") {
      //   isInitializingState.value = false;
      //   hasInitState.value = true;
      // }
      if (message?.progress) {
        propagateProgress.value = message.progress;
      }
      if (message?.total) {
        propagateTotal.value = message.total;
      }
      if (message?.status) {
        propagateStatus.value = message.status;
      }
      if (message?.detail) {
        propagateDetail.value = message.detail;
      }
      if (message?.polygon && message?.frame_idx >= 0) {
        const newAnnotationPolygon = {
          id: uuidv4(),
          label_index: selectedLabel.value.index,
          label_id: selectedLabel.value.id,
          label_name: selectedLabel.value.name,
          type: "seg",
          polygon: JSON.stringify(message.polygon),
          score: 1,
          reviewStatus: 'verified',
          image_id: samples.value[(initStartFrame.value - 1) + message.frame_idx],
          annotation_set_id: destinationAnnotationSet.value?.id,
          pending: true,
        };

        sam2Annotations.value = [...sam2Annotations.value, newAnnotationPolygon];
      }
      if (message.status === "error") {
        isPropagating.value = false;
        // initError.value = message.detail;
      }
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
    isPropagating.value = false;
    // initError.value = error;
  };

  socket.onclose = (event) => {
    isPropagating.value = false;
    if (event.wasClean) {
      console.log(`WebSocket closed cleanly, code=${event.code}, reason=${event.reason}`);
    } else {
      console.error('WebSocket connection closed unexpectedly');
    }
  };
}

async function handleResetSAMState() {
  // Sending a POST request
  return fetch(`aigt/reset_state?ip=${sam2Server.value}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error adding new point or box`);
      }
      return true;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
}

async function handleRemoveObject(obj_id) {
  const requestData = {
    obj_id,
  };

  // Sending a POST request
  return fetch(`aigt/remove_object?ip=${sam2Server.value}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error adding new point or box`);
      }
      return true;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
}

async function listCloudInstances() {
  fetchingCloudInstances.value = true;
  const dataConnect = new DatastoreConnect();
  return dataConnect.listCloudInstances({ filter: "" })
    .then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error);
      }
      fetchingCloudInstances.value = false;
      return resp.result;
    })
    .catch((error) => {
      fetchingCloudInstances.value = false;
      throw error;
    });
}

async function handleSAMRemoveObject(clicked_obj_id) {
  // Reset SAM2 Controls
  sam2Options.value.params.clicks = sam2Options.value.params.clicks.filter((click) => click.obj_id !== clicked_obj_id);
  sam2Options.value.params.boxes = sam2Options.value.params.boxes.filter((box) => box.obj_id !== clicked_obj_id);
  // Reset SAM2 annotations
  const sam2AnnotationsWithoutObjId = sam2Annotations.value.filter((sam2anno) => sam2anno.obj_id !== clicked_obj_id);
  sam2Annotations.value = [...sam2AnnotationsWithoutObjId];
  // Remove object
  objects.value = objects.value.filter((obj) => obj !== clicked_obj_id);
  if (clicked_obj_id === selectedObject.value) {
    selectedObject.value = null;
  }
  // Disable clicks mode if no object remaining
  if (objects.value.length === 0) {
    sam2Options.value.mode = null;
    sam2Options.value.params.pointType = null;
  }
  handleRemoveObject(clicked_obj_id);
}

async function handleCreateSAM2Annotations() {
  isCreatingAnnotations.value = true;
  const dataConnect = new DatastoreConnect();
  return dataConnect.addAnnotationsBulk({ annotations: sam2Annotations.value })
    .then((resp) => {
      if (resp.error !== undefined) {
        throw Error(resp.error);
      }

      return resp.result;
    })
    .then((newAnnotations) => {
      console.log(newAnnotations);
      // Set label_index and label_name for new annotations
      // TODO: remove the necessity to do this
      newAnnotations.forEach((anno) => {
        const label = labels.value.find((e) => e.id === anno.label_id);
        anno.label_index = label.index;
        anno.label_name = label.name;
      });

      // Update cache with new annotations
      addNewAnnotationsArrayToCache(newAnnotations);

      // Reset SAM2 annotations
      sam2Annotations.value = [];

      // Reset SAM2 Controls
      sam2Options.value.params.clicks = [];
      sam2Options.value.params.box = [];
      sam2Options.value.params.boxes = [];

      shouldClearNextPoints.value = true;

      isCreatingAnnotations.value = false;
    })
    .catch((error) => {
      isCreatingAnnotations.value = false;
      throw error;
    });
}

async function fetchInitStatus() {
  console.log('fetchInitStatus');

  // Sending a POST request
  const result = await fetch(`aigt/status?ip=${sam2Server.value}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error fetching init state status`);
      }
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return null;
    });

  // Parse the JSON response
  console.log("result:", result);

  if (result?.progress) {
    initProgress.value = result.progress;
  }
  if (result?.total) {
    initTotal.value = result.total;
  }
  if (result?.status) {
    initStatus.value = result.status;
  }
  if (result?.detail) {
    initDetail.value = result.detail;
  }
  if (result && result.status === 'complete') {
    isInitializingState.value = false;
    hasInitState.value = true;
    pauseFetchInitStatus();
  }
  if (result && result.status === 'error') {
    isInitializingState.value = false;
    hasInitState.value = false;
    initError.value = result.detail;
    pauseFetchInitStatus();
  }
}

async function fetchPropagateStatus() {
  console.log('fetchPropagateStatus');

  // Sending a POST request
  const result = await fetch(`aigt/status?ip=${sam2Server.value}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error fetching propagation status`);
      }
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return null;
    });

  // Parse the JSON response
  console.log("result:", result);

  if (result?.progress) {
    propagateProgress.value = result.progress;
  }
  if (result?.total) {
    propagateTotal.value = result.total;
  }
  if (result?.status) {
    propagateStatus.value = result.status;
  }
  if (result?.detail) {
    propagateDetail.value = result.detail;
  }
  // if (result?.polygon && result?.frame_idx >= 0) {
  //   const newAnnotationPolygon = {
  //     id: uuidv4(),
  //     label_index: selectedLabel.value.index,
  //     label_id: selectedLabel.value.id,
  //     label_name: selectedLabel.value.name,
  //     type: "seg",
  //     polygon: JSON.stringify(result.polygon),
  //     score: 1,
  //     reviewStatus: 'verified',
  //     image_id: samples.value[(initStartFrame.value - 1) + result.frame_idx],
  //     pending: true,
  //   };

  //   sam2Annotations.value = [...sam2Annotations.value, newAnnotationPolygon];
  // }
  if (result && result.status === 'complete') {
    isPropagating.value = false;
    pauseFetchPropagateStatus();
    pauseFetchAnnotationsStatus();
    // Check for annotations one more time
    fetchAnnotationsStatus();
  }
  if (result && result.status === 'error') {
    isPropagating.value = false;
    pauseFetchPropagateStatus();
    pauseFetchAnnotationsStatus();
    // Check for annotations one more time
    fetchAnnotationsStatus();
  }
}

async function fetchAnnotationsStatus() {
  console.log('fetchAnnotationsStatus');

  // Sending a POST request
  const result = await fetch(`aigt/annotations?ip=${sam2Server.value}&last_index=${propagateLastAnnotationIndex.value}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error fetching propagation status`);
      }
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return null;
    });

  // Parse the JSON response
  console.log("result:", result);

  if (result?.last_index) {
    propagateLastAnnotationIndex.value = result.last_index;
  }
  if (result?.annotations) {
    result.annotations.forEach((resultAnno) => {
      if (resultAnno?.polygon && resultAnno?.frame_idx >= 0) {
        const newAnnotationPolygon = {
          id: uuidv4(),
          label_index: selectedLabel.value.index,
          label_id: selectedLabel.value.id,
          label_name: selectedLabel.value.name,
          type: "seg",
          polygon: JSON.stringify(resultAnno.polygon),
          score: 1,
          reviewStatus: 'verified',
          image_id: samples.value[(initStartFrame.value - 1) + resultAnno.frame_idx],
          annotation_set_id: destinationAnnotationSet.value?.id,
          obj_id: resultAnno.obj_id,
          pending: true,
        };

        // Add the new annotation. Merge to existing annotation of the same object if it exists
        sam2Annotations.value = [
          ...sam2Annotations.value.filter((anno) => {
            const isSameImage = anno.image_id === samples.value[(initStartFrame.value - 1) + resultAnno.frame_idx];
            const isSameObject = anno.obj_id === resultAnno.obj_id;
            return !(isSameImage && isSameObject);
          }),
          newAnnotationPolygon,
        ];
      }
    });
  }
}

function handleSAMAddObject() {
  const uuid = uuidv4();
  objects.value.push(uuid);
  selectedObject.value = uuid;
}

</script>

<style lang="scss" scoped>
.sam-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__group + &__group {
    margin-left: 12px;
  }

  &__vr {
    display: flex;
    width: 1px;
    height: 60%;
    margin: 0 8px;
  }

  &__title {
    font-weight: 700;
    font-size: 0.9rem;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__button-container {
    position: relative;
  }

  &__button {
    border-radius: 4px !important;

    &._secondary {
      color: inherit;
      background: none;
      border: none;
      box-shadow: none;

      &:hover:not(:disabled), &.active:not(:disabled) {
        background-color: var(--icon-hover-color);
        // box-shadow: 0 0 0 4px rgba(140, 140, 140, 0.2);
      }

      &:disabled {
        box-shadow: none !important;
        background-color: transparent !important;
        cursor: default;
        color: var(--color-disabled) !important;
      }
    }

  }

  &__button-pointer-overlay {
    display: flex;
    position: absolute;
    width: 14px;
    height: 14px;
    top: -3px;
    right: -2px;
    pointer-events: none;
    @include themify() {
      color: themed('color-primary');
    }
  }

  &__labeled_button {
    width: auto;
  }
}

.sam-loader {
  animation: rotation 1s linear infinite;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  border-right: 3px solid transparent;
  @include themify() {
    border-top: 3px solid themed('color-accent-500');
  }

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-left: 3px solid transparent;
    @include themify() {
      border-bottom: 3px solid themed('color-primary-500');
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.sam-ready {
  box-sizing: border-box;
  width: 18px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    @include themify() {
      background: themed('color-success');
    }
  }
}

.sam-error {
  box-sizing: border-box;
  width: 18px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    @include themify() {
      background: themed('color-error');
    }
  }
}

#servers-select {
  border-radius: 3px;
  height: 28px;
}

.servers {
  &__loading {
    display: flex;
    align-items: center;
    font-style: italic;
    height: 100%;

    span {
      margin-left: 8px;
    }
  }

  &__not-found {
    display: flex;
    align-items: center;
    font-style: italic;
  }
}

.v-number-input * {
  --v-input-control-height: 28px;
  --v-input-padding-top: 0px;
  --v-field-input-padding-top: 0px;
  --v-field-input-padding-bottom: 0px;
  --v-field-padding-bottom: 0px;
  --v-icon-size-multiplier: 0.5;
}

.v-text-field * {
  --v-input-control-height: 28px;
  --v-input-padding-top: 0px;
  --v-field-input-padding-top: 0px;
  --v-field-input-padding-bottom: 0px;
  --v-field-padding-bottom: 0px;
  --v-icon-size-multiplier: 0.5;
  // --v-field-padding-start: 0;
  // --v-field-padding-end: 0;
}

.sam-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  min-height: 0;

  &__section {
    display: flex;
    flex-direction: column; /* Stack title and list vertically */
    // margin-bottom: 8px;
    border-bottom: 1px solid #ced4da;
    width: 100%;
    padding: 12px 12px 12px 12px;
    flex: 0 1 auto;
    min-height: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    color: var(--body-text-color-secondary);
    padding-bottom: 12px;
    letter-spacing: 0.025em;
    gap: 6px;
  }
}

.init-state {
  h2 {
    display: flex;
    flex-direction: row;
  }

  &__inputs {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-bottom: 8px;
  }
}

.controls {
  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}

.propagate {
  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    width: 100%;
  }

  &__action-button {
    flex: 1 1 auto;
  }
}

.objects {
  flex: 1 1 auto;

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    padding: 4px;
    flex: 1 1 auto;
    margin-bottom: 12px;
  }
}

.objects-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-white-600);
  border-radius: 4px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 8px;
  cursor: pointer;

  &._selected {
    outline: 3px solid var(--color-primary);
  }

  &__title {
    text-align: left;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--color-primary);
    padding-bottom: 12px;
    letter-spacing: 0.025em;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  &__edit-button {
    flex: 1 1 auto;
  }
}
</style>
