<template>
  <BaseMenu
    :placement="'bottom'"
    :offset="[0,0]"
    :appendToBody="true"
  >
    <button class="help-button">
      <SVGIcon
        :height="'32px'"
        :width="'32px'"
        :iconName="'help'"
        :showHover="false"
        :showActive="true"
      />
    </button>
    <template #menu="{closeMenu}">
      <div class="help-menu">
        <ul>
          <li>
            <BaseMenuButton @click="closeMenu(), open('https://help.dveml.com')">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'chat'"
              />
              <span>
                Help Center
              </span>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton @click="closeMenu(), openFeedbackModal()">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'feedback'"
              />
              <span>
                Feedback
              </span>
            </BaseMenuButton>
          </li>
          <li>
            <BaseMenuButton @click="closeMenu(), open('https://help.dveml.com/releases/releases/')">
              <SVGIcon
                :width="'24px'"
                :height="'24px'"
                :iconName="'description'"
              />
              <span>
                Release Notes
              </span>
            </BaseMenuButton>
          </li>
          <hr>
          <!-- NOT SURE WHY THE WINDOWS DOWNLOAD SHOWS VIRUS-->
          <!-- <li>
            <a href="/downloader-zip/downloader_windows.zip" download="downloader_windows.zip">
              <div class="li-row">
                <BaseMenuButton @click="closeMenu()">
                  <SVGIcon
                    :width="'24px'"
                    :height="'24px'"
                    :iconName="'windows_os'"
                  />
                  <div class="li-text">
                    <div class="li-text__title">Image Downloader Tool</div>
                    <div class="li-text__description">Install for Windows</div>
                  </div>
                </BaseMenuButton>
              </div>
            </a>
          </li> -->
          <li>
            <a href="/downloader-zip/downloader_linux.zip" download="downloader_linux.zip">
              <div class="li-row">
                <BaseMenuButton @click="closeMenu()">
                  <SVGIcon
                    :width="'24px'"
                    :height="'24px'"
                    :iconName="'linux_os'"
                  />
                  <div class="li-text">
                    <div class="li-text__title">Image Downloader Tool</div>
                    <div class="li-text__description">Install for Linux</div>
                  </div>
                </BaseMenuButton>
              </div>
            </a>
          </li>
          <li>
            <a href="/downloader-zip/downloader_python.zip" download="downloader_python.zip">
              <div class="li-row">
                <BaseMenuButton @click="closeMenu()">
                  <SVGIcon
                    :width="'24px'"
                    :height="'24px'"
                    :iconName="'python'"
                  />
                  <div class="li-text">
                    <div class="li-text__title">Image Downloader Tool</div>
                    <div class="li-text__description">Python Script</div>
                  </div>
                </BaseMenuButton>
              </div>
            </a>
          </li>
          <hr>
          <li>
            <span class="version-text">Version: {{ version }} - {{ frontendVersion }}</span>
          </li>
        </ul>
      </div>
    </template>
  </BaseMenu>
  <FeedbackModal ref="feedbackModal" />
</template>

<script>
import BaseMenu from '@/components/BaseMenu.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import FeedbackModal from '@/components/FeedbackModal.vue';

export default {
  name: 'HelpMenu',
  components: {
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
    FeedbackModal,
  },
  computed: {
    version() {
      return this.$store.state.appVersion.version;
    },
    frontendVersion() {
      return this.$store.state.appVersion.frontendVersion;
    },
  },
  methods: {
    open(page) {
      window.open(page);
    },
    openFeedbackModal() {
      this.$refs.feedbackModal.showModal();
    },
  },
};
</script>

<style lang="scss" scoped>

.help-button {
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  background: none;
  border: none;
  cursor: pointer;
  @include themify() {
    color: themed('header-dot-menu');
  }

  &:hover {
    @include themify() {
      background: themed('header-button-background-hover');
      color: themed('header-button-color-hover');
    }
  }

  &._active {
    @include themify() {
      background: themed('header-button-background-hover');
      color: themed('header-button-color-hover');
    }
  }
}

.help-menu:deep {
  width: 240px;
  .switch {
    margin-left: auto;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a > span {
    font: inherit;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 12px;
    white-space: nowrap;
  }
}

.version-text {
  display: block;
  width: inherit;
  align-items: center;
  padding: 4px 4px;
  text-align: left;
  font: inherit;
  font-size: 0.7rem;
  font-weight: 600;
  color: rgba(92, 91, 91, 0.5);
  word-wrap: break-word;
}

.li-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.li-text {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__title{
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 12px;
  }

  &__description{
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 12px;
    margin-top: 5px;
    @include themify() {
      color: rgba(61, 60, 60, 0.5);
    }
  }
}

</style>
