<template>
  <Modal
    ref="createServerModal"
    :title="'Launch a Server'"
    width="650px"
    @closed="resetData"
  >
    <!-- <template #modal-header /> -->
    <template #modal-main>
      <div class="settings container scrollbar">
        <v-expansion-panels
          v-model="panels"
          multiple
          class="mt-2"
        >
          <v-expansion-panel
            value="information"
          >
            <v-expansion-panel-title>
              <h3>Information</h3>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="row mt-3">
                <div class="col-12 settings__control-group">
                  <label class="required">Name</label>
                  <input
                    v-model="name"
                    class="select"
                    type="text"
                  >
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 settings__control-group">
                  <label class="required">Type</label>
                  <select v-model="type" class="select">
                    <option :value="''" disabled>Select a Server Type</option>
                    <option value="aigt">AI Assited Ground Truth</option>
                  </select>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="message" class="row mt-3">
          <div class="col-12 settings__control-group">
            <div class="result error">
              <span>{{ message }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <div class="footer">
        <button
          class="button modal-action-button"
          :class="{'button-spinner': startingInstance}"
          :disabled="startingInstance"
          @click="startInstance"
        >
          Start Server
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import Modal from '@/components/Modal.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits([
  'instance-created',
]);

const store = useStore();

const dataConnect = new DatastoreConnect();
const message = ref('');
const name = ref('');
const type = ref('');
const startingInstance = ref(false);
const createServerModal = ref(null);
const panels = ref(['information']);

const currentProject = computed(() => store.state.projects.currentProject);

function resetData() {
  name.value = '';
  type.value = '';
  message.value = '';
}

async function startInstance() {
  message.value = '';

  if (!name.value) {
    message.value = `Missing Name`;
    return;
  }
  const params = {
    project_id: currentProject.value.id,
    name: name.value,
    type: type.value,
  };
  await dataConnect.startCloudInstance(params)
    .then((data) => {
      if (data.result && !data.error) {
        emit('instance-created');
      }
      closeModal();
    })
    .catch((e) => {
      message.value = e.message;
      console.log(e);
    });
}

function showModal() {
  createServerModal.value.showModal();
}

function closeModal() {
  createServerModal.value.closeModal();
}

defineExpose({
  showModal,
});

</script>

<style lang="scss" scoped>
.settings {
  padding: 0.75rem 3rem;
  min-width: 540px;
  overflow-y: scroll;

  span {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    @include themify() {
      color: themed('color-primary');
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 3rem;
  justify-content: flex-end;
}

</style>
