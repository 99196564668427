<template>
  <Modal
    id="annotation-editor-modal"
    ref="imageModal"
    :fullScreen="true"
    :closeWithEscapeKey="false"
    @keydown.tab.stop.prevent=""
    @closed="handleModalClosed"
  >
    <template #modal-header-prepend>
      <div class="modal-prepend-header__logo" v-html="auzone_log_svg" />
    </template>
    <template #modal-header-title-description>
      <div class="image-title-description">
        <span class="mt-0 mb-0 title" :title="title">{{ title }}</span>
        <span class="mt-0 mb-0 subtitle" :title="subtitle">{{ subtitle }}</span>
      </div>
    </template>
    <template #modal-header>
      <div class="header">
        <div class="review-task-header">
          <AuditToolSelect v-if="hasReviewTask && sourceAnnotationSet" v-model="auditorType" />
          <div class="review-task-header-info scrollbar">
            <TaskDatasetAndDestinationCard :reviewSettings="reviewSettings" />
            <TaskProgressCard v-if="hasReviewTask" />
            <TaskActiveTimeCard v-if="hasReviewTask" />
          </div>
        </div>
      </div>
    </template>
    <template #modal-main>
      <template v-if="auditorType === 'image'">
        <v-layout :full-height="true" style="z-index: 9;" class="flex-column">
          <div
            ref="modalDisplay"
            class="display"
            :tabindex="selectedAnnotations.length > 0"
          >
            <BaseSidebar :collapsible="false">
              <div class="annotation-editor-modal__sidebar">
                <JsonRawViewer
                  v-model:selectedAnnotationSets="annotationDisplaySettings.displayAnnotationSets.annotation_sets"
                  v-model:selectedLabels="annotationDisplaySettings.displayLabels.labels"
                  v-model:selectedLabel="selectedLabel"
                  :labels="labels"
                  :imageObj="displayImageObj"
                  :annotations="displayImageObj?.annotations"
                  :annotationSets="annotationSets"
                  :fixedAnnotationSet="annotationSetToEdit"
                  :groupedAnnotations="groupedAnnotations"
                  :selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
                  :isEditing="true"
                  @remove-annotation="handleAnnotationDeleteClicked"
                  @select-annotations="handleSelectAnnotationFromSidebar"
                  @new-color="(params)=>$emit('new-color', params)"
                >
                  <template #utilities-slot>
                    <AddVisualizationMenu @layout-selected="handleAddVisualization" />
                    <SelectLayoutMenu @layout-selected="handleLayoutSelected" />
                  </template>
                </JsonRawViewer>
                <template v-if="groups.length > 0">
                  <div class="image-group">
                    <label class="image-group__label">Group</label>
                    <ImageGroupSelector :imageObj="displayImageObj" :groups="groups" @change="handleImageGroupChanged" />
                  </div>
                </template>
              </div>
            </BaseSidebar>

            <div class="visualization">
              <AnnotationToolSequenceRibbon
                v-if="internalEditAsSequence && isSequence"
                :zoom="zoom"
                :canUndo="prevInternalAnnotations !== null"
                :hasSelection="selectedAnnotationIdentifiers.length > 0"
                :annotationSets="annotationSets"
                :labels="labels"
                :annotationDisplaySettings="annotationDisplaySettings"
                :isReviewMode="isAuditMode"
                :frameImageObj="displayImageObj"
                :reviewSettings="reviewSettings"
                :selectedAnnotations="selectedAnnotations"
                :copiedAnnotations="copiedAnnotations"
                @zoom-to-fit="handleZoomToFit"
                @zoom-to-selection="handleZoomToSelection"
                @undo="handleUndo"
                @revert-changes="handleRevertChanges"
                @show-annotation-info="showAnnotationInfo = true"
                @submit="handleSequenceSubmit"
                @copy="handleCopyAnnotations"
                @paste="handlePasteAnnotations"
                @bulk-del-ann="handleDeleteAnnotationBulk"
                @exit-editor="handleModalClosed"
              />
              <AnnotationToolRibbon
                v-else
                :zoom="zoom"
                :canUndo="prevInternalAnnotations !== null"
                :hasSelection="selectedAnnotationIdentifiers.length > 0"
                :annotationSets="annotationSets"
                :labels="labels"
                :annotationDisplaySettings="annotationDisplaySettings"
                :isReviewMode="isAuditMode"
                @zoom-to-fit="handleZoomToFit"
                @zoom-to-selection="handleZoomToSelection"
                @undo="handleUndo"
                @revert-changes="handleRevertChanges"
                @show-annotation-info="showAnnotationInfo = true"
                @submit="handleSubmit"
                @bulk-del-ann="handleDeleteAnnotationBulk"
                @exit-editor="handleModalClosed"
              />
              <VisualizationDisplays
                ref="visualizationDisplays"
                v-model:layout="layout"
                :loading="isInitializingAnimation || (internalEditAsSequence && isSequence && isLoadingSample)"
                @drop="handleVisualizationContainerDrop"
                @visualization-selected="handleVisualizationSelected"
              />
              <div v-if="internalEditAsSequence && isSequence" class="visualization__horizontal-divider" />
              <AnimationControls
                v-if="internalEditAsSequence && isSequence && internalImageObj"
                ref="animationControls"
                :currentImage="internalImageObj"
                :isTask="true"
                :reviewSettings="reviewSettings"
                :labels="labels"
                :selectedAnnotationSets="annotationDisplaySettings.displayAnnotationSets.annotation_sets"
                @frame-count="(count) => {frameCount = count}"
              />
            </div>

            <BaseSidebar v-if="drawMode === 'sam2'" :collapsible="false">
              <div class="annotation-editor-modal__sidebar">
                <SAM2Sidebar />
              </div>
            </BaseSidebar>
            <BaseSidebar v-if="current3DCanvasTool === 'aigt-3d-annotator'" :collapsible="false">
              <div class="annotation-editor-modal__sidebar">
                <Aigt3dSidebar :destinationAnnotationSet="destinationAnnotationSet" />
              </div>
            </BaseSidebar>

            <BaseSidebar
              :title="'Annotation Info'"
              :closeable="true"
              :collapsible="false"
              :placement="'right'"
              :show="showAnnotationInfo"
              @closed="showAnnotationInfo = false"
            >
              <AnnotationDetails :annotationObj="selectedAnnotationInfo" />
            </BaseSidebar>
            <BaseSidebar
              v-if="isAuditMode && reviewSettings && submitType === 'submit-accepted-annotations'"
              class="annotation-review-sidebar"
              :title="'Annotation Review'"
              :closeable="false"
              :collapsible="false"
              :placement="'right'"
              :show="true"
            >
              <div class="annotation-review-sidebar__buttons">
                <button
                  class="button button-sm button-secondary"
                  :title="'Accept All Annotations'"
                  @click="acceptAllAnnotations"
                >
                  Accept All Annotations
                </button>
              </div>
              <div class="annotation-review-sidebar__annotations scrollbar" @scroll="handleAnnotationScroll">
                <div
                  v-for="(annotationObj, index) in internalAnnotations"
                  :key="index"
                  class="annotation-review-sidebar__snap-container"
                >
                  <GalleryGridAnnotationReviewItem
                    v-model:reviewStatus="annotationObj.reviewStatus"
                    :imageObj="displayImageObj"
                    :annotationSets="annotationSets"
                    :annotationSet="annotationSetToEdit"
                    :labels="labels"
                    :annotation="annotationObj"
                    @edit-clicked="handleAnnotationReviewItemEdit"
                  />
                </div>
              </div>
            </BaseSidebar>
          </div>
          <AnnotationEditorHistoryDrawer
            v-if="hasReviewTask && reviewSettings !== null && reviewSettings !== undefined"
            :active="true"
            :images="doneImages"
            :currentImage="currentTaskImage"
            :pendingImages="pendingTaskImages"
            :activeIndex="activeImageIndex"
            :component="'footer'"
            :height="56"
            :reviewSettings="reviewSettings"
            :sequences="sequences"
            @image-clicked="handleTaskImageClicked"
            @update:selected-sequence="handleSelectedSequenceChanged"
          />
        </v-layout>
      </template>
      <template v-else-if="auditorType === 'annotation'">
        <AnnotationReview
          v-if="hasSourceAnnotationSet && reviewSettings"
          ref="annotationReview"
          :reviewSettings="reviewSettings"
          :activeImageIndex="activeImageIndex"
          :currentTaskImage="currentTaskImage"
          :pendingImages="pendingTaskImages"
          :annotations="internalAnnotations"
          :labels="labels"
          @image-clicked="handleTaskImageClicked"
        />
      </template>

      <template v-if="showLayouts">
        <VisualizationPane v-if="visualizationLocations?.canvas" :paneType="'canvas'" :to="getLocationId(visualizationLocations.canvas)">
          <AnnotationToolViewingModeControls
            :zoom="zoom"
            :hasSelection="selectedAnnotationIdentifiers.length > 0"
            :annotationSets="annotationSets"
            :labels="labels"
            :annotationDisplaySettings="annotationDisplaySettings"
            :imageObj="displayImageObj"
            @zoom-to-fit="handleZoomToFit"
            @zoom-to-selection="handleZoomToSelection"
          />
          <DrawingToolModeControls
            v-model:mode="drawMode"
            v-model:size="drawCursorSize"
            v-model:samActive="samOptions.enabled"
            :shouldEnableEraser="selectedAnnotationIdentifiers.length === 1"
            :imageDimensions="imageDimensions"
            :zoom="zoom"
            :hasSAMImageEmbedding="hasSAMImageEmbedding"
            :samOptions="samOptions"
            :sam2Options="sam2Options"
            :imageObj="internalImageObj"
            @update-cursor-size="handleCursorSizeUpdated"
          />
          <div
            ref="annotationTool"
            class="annotation-tool"
          >
            <AnnotationCanvas
              v-if="displayImageObj"
              ref="annotationCanvas"
              v-model:updatedAnnotations="internalAnnotations"
              v-model:scale="annotationToolScale"
              v-model:selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
              v-model:hasSAMImageEmbedding="hasSAMImageEmbedding"
              :selectedAnnotationSets="annotationDisplaySettings.displayAnnotationSets.annotation_sets"
              :selectedLabels="annotationDisplaySettings.displayLabels.labels"
              :currentTool="currentCanvasTool"
              :annotations="internalAnnotations"
              :annotationSets="annotationSets"
              :imageObj="displayImageObj"
              :labels="labels"
              :selectedLabel="selectedLabel"
              :colorBy="colorBy"
              :showAnnotations="showAnnotations"
              :editingEnabled="true"
              :samOptions="samOptions"
              :useImageCacheOnly="internalEditAsSequence && isSequence ? true : false"
              :imageCacheElem="currentAnimationSample?.image"
              :highlightedAnnotationIdentifier="hoveredAnnotationListIdentifier"
              :isAuditMode="isAuditMode"
              :enableSAM="true"
              :show3DTo2DProjection="show3DTo2DProjection"
              :showTypes="showTypes"
              :drawMode="drawMode"
              :drawCursorSize="drawCursorSize"
              :doneVertexDraw="doneVertexDraw"
              @create-annotation="createNewInternalAnnotation"
              @image-loaded="handleImageLoaded"
              @remove-annotation="handleAnnotationDeleteClicked"
            />
            <div v-else-if="isMounted && !internalEditAsSequence" id="no-image-remaining">No Images Remaining</div>
          </div>
          <VideoControls v-if="isVideo" />
        </VisualizationPane>

        <VisualizationPane v-if="visualizationLocations?.['3d-canvas']" :to="getLocationId(visualizationLocations['3d-canvas'])">
          <PointCloudViewer
            v-model:updatedAnnotations="internalAnnotations"
            v-model:selectedAnnotationIdentifiers="selectedAnnotationIdentifiers"
            v-model:show3DTo2DProjection="show3DTo2DProjection"
            :imageObj="displayImageObj"
            :annotations="internalAnnotations"
            :pendingAnnotations="aigt3dAnnotations"
            :labels="labels"
            :selectedLabel="selectedLabel"
            :currentTool="current3DCanvasTool"
            :showTypes="showTypes"
            :points="currentAnimationSample?.pcd?.[0]"
            @create-annotation="createNewInternalAnnotation"
          />
          <DrawingToolModeControls3d
            v-model:current3DCanvasTool="current3DCanvasTool"
          />
        </VisualizationPane>

        <VisualizationPane v-if="visualizationLocations?.['depth-map']" :to="getLocationId(visualizationLocations['depth-map'])">
          <DepthMapViewer
            :depthMapFile="currentAnimationSample?.depthmap?.[0]"
            :useImageCacheOnly="internalEditAsSequence && isSequence ? true : false"
            :imageCacheElem="currentAnimationSample?.depthmap?.[0]"
            :imageObj="displayImageObj"
            :showDepthMap="showTypes?.depthmap"
          />
        </VisualizationPane>
      </template>
    </template>
  </Modal>
  <BulkDeleteAnnotationModal
    ref="bulkDeleteModal"
    :frameCount="frameCount"
    :imageObj="internalImageObj"
    :annotationSets="annotationSets"
    @deleted="handleAnnotationBulkDeleteComplete"
  />
</template>

<script setup>
import Modal from '@/components/Modal.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseSidebar from '@/components/BaseSidebar.vue';
import AnnotationCanvas from '@/components/DatasetComponent/AnnotationTool/AnnotationCanvas.vue';
import AnnotationToolRibbon from '@/components/DatasetComponent/AnnotationTool/AnnotationToolRibbon.vue';
import AnnotationToolSequenceRibbon from '@/components/DatasetComponent/AnnotationTool/AnnotationToolSequenceRibbon.vue';
import AnnotationDetails from '@/components/DatasetComponent/AnnotationTool/AnnotationDetails.vue';
import GalleryGridAnnotationReviewItem from '@/components/DatasetComponent/GalleryComponent/GalleryGridAnnotationReviewItem.vue';
import AnnotationEditorHistoryDrawer from '@/components/DatasetComponent/AnnotationTool/AnnotationEditorHistoryDrawer.vue';
import AnnotationReview from '@/components/DatasetComponent/AnnotationTool/AnnotationReview.vue';
import TaskProgressCard from '@/components/DatasetComponent/AnnotationTool/TaskProgressCard.vue';
import TaskActiveTimeCard from '@/components/DatasetComponent/AnnotationTool/TaskActiveTimeCard.vue';
import TaskDatasetAndDestinationCard from '@/components/DatasetComponent/AnnotationTool/TaskDatasetAndDestinationCard.vue';
import AuditToolSelect from '@/components/DatasetComponent/AnnotationTool/AuditToolSelect.vue';
import ImageGroupSelector from '@/components/ImageGroupSelector.vue';
import { roundNumber } from '@/assets/js/utils.js';
import {
  ref, toRefs, watch, computed, onMounted, onUnmounted, nextTick, toRaw,
} from 'vue';
import useTasks from '@/composables/annotationTool/useTasks.js';
import useAnnotationEditorModalHotkeys from '@/composables/annotationTool/useAnnotationEditorModalHotkeys.js';
import useImageViewerHotkeys from '@/composables/annotationTool/useImageViewerHotkeys.js';
import { auzone_logo } from "@/assets/js/icons";
import VideoControls from '@/components/DatasetComponent/AnnotationTool/VideoControls/VideoControls.vue';
import ToolsSidebar from '@/components/DatasetComponent/AnnotationTool/ToolsSidebar.vue';
import { useViewerVisualizationsStore } from '@/stores/useViewerVisualizationsStore.js';
import { useEditorControlsStore } from '@/stores/useEditorControlsStore.js';
import { useEditorStore } from '@/stores/useEditorStore.js';
import { storeToRefs } from 'pinia';
import PointCloudViewer from '@/components/DatasetComponent/AnnotationTool/PointCloudViewer.vue';
import JsonRawViewer from '@/components/DatasetComponent/AnnotationTool/JsonRawViewer.vue';
import { useStore } from 'vuex';
import AnnotationToolViewingModeControls from '@/components/DatasetComponent/AnnotationTool/AnnotationToolViewingModeControls.vue';
import useSAMCanvas from '@/composables/canvas/useSAMCanvas.js';
import BulkDeleteAnnotationModal from '@/components/DatasetComponent/AnnotationTool/BulkDeleteAnnotationModal.vue';
import { v4 as uuidv4 } from 'uuid';
import DepthMapViewer from '@/components/DatasetComponent/AnnotationTool/DepthMapViewer.vue';
import DrawingToolModeControls from '@/components/DatasetComponent/AnnotationTool/DrawingToolModeControls.vue';
import DrawingToolModeControls3d from '@/components/DatasetComponent/AnnotationTool/DrawingToolModeControls3d.vue';
import VisualizationPane from '@/components/DatasetComponent/AnnotationTool/VisualizationPane.vue';
import VisualizationDisplays from '@/components/DatasetComponent/AnnotationTool/VisualizationDisplays.vue';
import SelectLayoutMenu from '@/components/DatasetComponent/AnnotationTool/SelectLayoutMenu.vue';
import AddVisualizationMenu from '@/components/DatasetComponent/AnnotationTool/AddVisualizationMenu.vue';
import useVisualizationDisplays from '@/composables/annotationTool/useVisualizationDisplays.js';
import AnimationControls from '@/components/DatasetComponent/AnnotationTool/AnimationControlsV2.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import { useSAM2Store } from '@/stores/useSAM2Store.js';
import SAM2Sidebar from '@/components/DatasetComponent/AnnotationTool/SAM2Sidebar.vue';
import Aigt3dSidebar from '@/components/DatasetComponent/AnnotationTool/Aigt3dSidebar.vue';
import useAnnotationType from '@/composables/useAnnotationType.js';
import { useAIGTStore } from '@/stores/useAIGTStore.js';

const props = defineProps({
  initialLabels: {
    type: Array,
    default: () => [],
  },
  reviewSettings: {
    type: Object,
    default: null,
  },
  initialAnnotationDisplaySettings: {
    type: Object,
    default: () => (
      {
        displayAnnotationSets: { annotation_sets: [] },
        displayLabels: { labels: [] },
        colorAnnotationsBy: { type: "label" },
      }
    ),
  },
  initialAuditorType: {
    type: String,
    default: 'image',
  },
  editAsSequence: {
    type: Boolean,
    default: true,
  },
});
const {
  reviewSettings,
  initialAnnotationDisplaySettings,
  initialAuditorType,
  editAsSequence,
} = toRefs(props);

const store = useStore();

const emit = defineEmits(['closed', 'new-color']);

const editorStore = useEditorStore();
const {
  internalImageObj,
  internalAnnotations,
  sequences,
  labels,
} = storeToRefs(editorStore);
onUnmounted(() => {
  editorStore.$dispose();
});
labels.value = props.initialLabels;

const {
  jsonViewerShowTypes: showTypes,
} = useAnnotationType();

const auzone_log_svg = ref(auzone_logo);
const imageModal = ref(null);
const annotationCanvas = ref(null);
const prevInternalAnnotations = ref(null);
const annotationSetToEdit = ref(null);
const selectedAnnotationIdentifiers = ref([]);
const bulkDeleteModal = ref(null);
const frameCount = ref(0);

const annotationDisplaySettings = ref({
  displayAnnotationSets: { annotation_sets: initialAnnotationDisplaySettings.value.displayAnnotationSets.annotation_sets },
  displayLabels: { labels: initialAnnotationDisplaySettings.value.displayLabels.labels },
  colorAnnotationsBy: { type: initialAnnotationDisplaySettings.value.colorAnnotationsBy.type },
});
const annotationToolScale = ref(1);
const showAnnotations = ref(true);
const showMetadata = ref(false);
const showAnnotationInfo = ref(false);
const hoveredAnnotationListIdentifier = ref(null);
const activePane = ref(null);
const isMounted = ref(false);
const internalEditAsSequence = ref(true);

// Draw mode
const drawMode = ref('pointer');
const drawCursorSize = ref(0);
const doneVertexDraw = ref(false);

// SAM
const samOptions = ref({
  enabled: false,
  mode: null,
  params: {
    pointType: null,
    clicks: [],
    box: [],
  },
});
const hasSAMImageEmbedding = ref(false);
function handleEnableSAMToggled(isEnabled) {
  samOptions.value.enabled = isEnabled;
}
watch(samOptions, (newSamOptions) => {
  store.commit('annotationTool/toggleSAMEnabled', newSamOptions.enabled);
}, { deep: true });

// SAM2
const sam2Store = useSAM2Store();
const { getFrameSAM2Annotations } = sam2Store;
const {
  sam2Server,
  sam2Options,
  sam2Active,
} = storeToRefs(sam2Store);
const isSAM2Selected = computed(() => drawMode.value === 'sam2');
watch(isSAM2Selected, (_isSAM2Selected) => {
  sam2Options.value.enabled = _isSAM2Selected;
});

// AIGT
const aigtStore = useAIGTStore();
const {
  aigt3dAnnotations,
} = storeToRefs(aigtStore);

const initialEditingAnnotations = computed(() => {
  const initialImageAnnotations = internalImageObj.value.annotations;
  if (annotationSetToEdit.value && initialImageAnnotations) {
    return filterAnnotationsBySet(JSON.parse(JSON.stringify(initialImageAnnotations)), [annotationSetToEdit.value.id]);
  }
  return [];
});

const annotationIdentifierMap = computed(() => {
  const newAnnotationIdentifierMap = {};
  internalAnnotations.value.forEach((anno, i) => {
    newAnnotationIdentifierMap[anno.id] = anno;
  });
  return newAnnotationIdentifierMap;
});

// Setup Tasks
const {
  startTask,
  currentTaskImage,
  pendingTaskImages,
  sourceDataset,
  sourceAnnotationSet,
  hasSourceAnnotationSet,
  destinationAnnotationSet,
  isAuditMode,
  groups,
  auditorType,
  hasReviewTask,
  setupTaskImages,
  handleImageGroupChanged,
  handleLabellingSubmitAnnotations,
  acceptAllAnnotations,
  activeImageIndex,
  doneImages,
  switchToTaskImage,
  submitType,
  handleAuditSubmitAllAnnotations,
  handleAuditSubmitAcceptedAnnotations,
  handleFrameAuditSubmitAllAnnotations,
  handleFrameAuditSubmitAcceptedAnnotations,
  skipTaskImage,
  getTaskSequences,
} = useTasks({ reviewSettings });
startTask();

// Controls
const { selectedLabel, currentCanvasTool, current3DCanvasTool } = storeToRefs(useEditorControlsStore());
onUnmounted(() => {
  const { $reset } = useEditorControlsStore();
  $reset();
});

// Create hotkeys
const canvasHotkeys = {
  'Escape': () => {
    if (activePane.value === 'canvas') {
      selectedAnnotationIdentifiers.value = [];
      drawMode.value = 'pointer';
      nextTick(() => {
        imageModal.value.focus();
      });
    } else if (activePane.value === '3d-canvas') {
      selectedAnnotationIdentifiers.value = [];
      selectedLabel.value = labels.value[0];
      current3DCanvasTool.value = '3d-pointer';
      nextTick(() => {
        imageModal.value.focus();
      });
    }
  },
  'c': async () => {
    if (!samOptions.value.enabled || !SAMImageEmbedding.value || !SAMSession.value) return;
    await handleCreateSAMPolygon();
    SAMEventBus.value.emit('reset');
  },
  'z': () => {
    handleZKey();
  },
};
const canvas3DHotkeys = {
  'q': () => { current3DCanvasTool.value = '3d-pointer'; },
  'b': () => { current3DCanvasTool.value = '3d-box'; },
  'l': () => { current3DCanvasTool.value = '3d-edit-label'; },
  'g': () => { current3DCanvasTool.value = 'translate-object'; },
  's': () => { current3DCanvasTool.value = 'scale-object'; },
  'Escape': async () => {
    selectedAnnotationIdentifiers.value = [];
    selectedLabel.value = labels.value[0];
    current3DCanvasTool.value = '3d-pointer';
    nextTick(() => {
      imageModal.value.focus();
    });
  },
};
const toolHotkeys = ref({
  ...canvasHotkeys,
});
const auditHotkeys = ref({
  'v': () => {
    selectedAnnotations.value.forEach((anno) => {
      anno.reviewStatus = 'verified';
    });
  },
  'V': () => {
    selectedAnnotations.value.forEach((anno) => {
      anno.reviewStatus = 'unreviewed';
    });
  },
  ' ': () => {
    selectedAnnotations.value.forEach((anno) => {
      if (anno.reviewStatus === 'verified') {
        anno.reviewStatus = 'unreviewed';
      } else {
        anno.reviewStatus = 'verified';
      }
    });
  },
  'Enter': async () => {
    if (submitType.value === 'submit-all-annotations') {
      if (internalEditAsSequence.value && isSequence.value) {
        handleFrameAuditSubmitAllAnnotations(displayImageObj.value);
        currentAnimationSample.value.imageObj.annotations = internalAnnotations.value;
        handleSequenceSubmit();
      } else {
        handleAuditSubmitAllAnnotations();
      }
    } else if (submitType.value === 'submit-accepted-annotations') {
      if (internalEditAsSequence.value && isSequence.value) {
        handleFrameAuditSubmitAcceptedAnnotations(displayImageObj.value);
        currentAnimationSample.value.imageObj.annotations = internalAnnotations.value;
        handleSequenceSubmit();
      } else {
        handleAuditSubmitAcceptedAnnotations();
      }
    }
  },
});
const drawToolHotKeys = ref({
  'q': () => {
    if (activePane.value === 'canvas') {
      drawMode.value = 'pointer';
    } else if (activePane.value === '3d-canvas') {
      current3DCanvasTool.value = '3d-pointer';
    }
  },
  'b': () => {
    if (activePane.value === 'canvas') {
      drawMode.value = "box";
    } else if (activePane.value === '3d-canvas') {
      current3DCanvasTool.value = '3d-box';
    }
  },
  'a': () => {
    if (activePane.value === 'canvas') {
      drawMode.value = "sam";
    }
  },
  'w': () => {
    if (activePane.value === 'canvas') {
      drawMode.value = "brush";
    }
  },
  'l': () => {
    if (activePane.value === 'canvas') {
      drawMode.value = 'color';
    } else if (activePane.value === '3d-canvas') {
      current3DCanvasTool.value = '3d-edit-label';
    }
  },
  'p': () => {
    if (activePane.value === 'canvas') {
      drawMode.value = 'line';
    }
  },
  'e': () => {
    if (activePane.value === 'canvas') {
      if (selectedAnnotationIdentifiers.value.length === 1) {
        drawMode.value = "eraser";
      }
    }
  },
  '=': () => {
    if (activePane.value === 'canvas') {
      if (drawCursorSize.value < (0.2 * imageDimensions.value.width)) {
        drawCursorSize.value *= 1.3;
      }
    }
  },
  '-': () => {
    if (activePane.value === 'canvas') {
      if (drawCursorSize.value > (0.001 * imageDimensions.value.width)) {
        drawCursorSize.value /= 1.3;
      }
    }
  },
});

watch(drawMode, () => {
  if (drawMode.value !== "line") {
    nextTick(() => { doneVertexDraw.value = true; });
  } else {
    nextTick(() => { doneVertexDraw.value = false; });
  }
  if (drawMode.value === "sam") {
    selectedAnnotationIdentifiers.value = [];
  }
});

function handleCursorSizeUpdated(v) {
  drawCursorSize.value = v;
}

const imageAuditHotkeys = ref({
  'ArrowRight': async () => {
    await skipTaskImage();
  },
});
const sequenceAuditHotkeys = ref({
  'ArrowLeft': async () => {
    animationControls.value.handleSkipPrevious();
  },
  'ArrowRight': async () => {
    animationControls.value.handleSkipNext();
  },
});
const imageEditorHotkeys = computed(() => ({
  'Delete': async () => {
    if (selectedAnnotationIdentifiers.value.length > 0) {
      const annotationsToDelete = selectedAnnotationIdentifiers.value.map((identifier) => annotationIdentifierMap.value[identifier]);
      internalAnnotations.value = [...internalAnnotations.value.filter((anno) => !annotationsToDelete.includes(anno))];
      selectedAnnotationIdentifiers.value = [];
    }
  },
  'ctrl,c': () => {
    handleCopyAnnotations();
  },
  'ctrl,v': () => {
    handlePasteAnnotations();
  },
  'ctrl,x': () => {
    handleDeleteAnnotationBulk();
  },
  ...auditHotkeys.value,
  ...(internalEditAsSequence.value && isSequence.value ? sequenceAuditHotkeys.value : imageAuditHotkeys.value),
  ...toolHotkeys.value,
  ...drawToolHotKeys.value,
}));

const {
  areListenersActive,
  activateListeners: activateImageAuditListeners,
  deactivateListeners: deactivateImageAuditListeners,
} = useAnnotationEditorModalHotkeys(imageEditorHotkeys);
watch(auditorType, (auditorTypeVal) => {
  if (auditorTypeVal !== 'image') {
    deactivateImageAuditListeners();
  } else {
    if (!areListenersActive.value) {
      activateImageAuditListeners();
    }
  }

  // Toggle showing the visualizations
  if (auditorTypeVal === 'annotation') {
    showLayouts.value = false;
  } else {
    nextTick(() => {
      showLayouts.value = true;
    });
  }
});

const imageDimensions = ref({});

// const {
//   activateListeners: activateImageViewerListeners,
//   deactivateListeners: deactivateImageViewerListeners,
// } = useImageViewerHotkeys(ctx);

if (reviewSettings.value?.initialAuditorType === 'image') {
  activateImageAuditListeners();
}
// else if (!isTask.value) {
//   activateImageViewerListeners();
// }

// Zoom
function handleZoomToFit() {
  annotationCanvas.value.zoomToFitImage();
}
function handleZoomToSelection() {
  annotationCanvas.value.zoomToSelection();
}
function handleZKey() {
  if (selectedAnnotationIdentifiers.value.length > 0) {
    annotationCanvas.value.zoomToSelection();
  } else {
    annotationCanvas.value.zoomToFitImage();
  }
}
const zoom = computed(() => roundNumber(annotationToolScale.value * 100, 0));

function filterAnnotationsBySet(annotations, sets) {
  if (sets) {
    // should return all those with the current annotation set and those without
    // the ones without annotation set are recently created
    return annotations.filter((anno) => sets.includes(anno.annotation_set_id) || !anno.annotation_set_id);
  }
  return annotations;
}
function filterAnnotationsByLabelIndexes(annotations, labelIndexes) {
  if (labelIndexes) {
    return annotations.filter((anno) => labelIndexes.includes(anno.label_index));
  }
  return annotations;
}
function filterAnnotations(annotations) {
  if (!annotations) {
    return [];
  }
  const filteredAnnotations = JSON.parse(JSON.stringify(annotations));
  return filteredAnnotations;
}

// Computed
const title = computed(() => (internalImageObj.value?.name ? internalImageObj.value.name : ''));
const subtitle = computed(() => (internalImageObj.value?.type === 'sequence' && internalImageObj.value?.uuid ? internalImageObj.value.uuid : ''));
const isVideo = computed(() => internalImageObj.value?.type === 'video');
const isSequence = computed(() => Boolean(internalImageObj.value?.sequence_id));
const colorBy = computed(() => annotationDisplaySettings.value?.colorAnnotationsBy?.type);
const displayImageObj = computed(() => {
  if (internalEditAsSequence.value && isSequence.value && currentAnimationSample.value) {
    return currentAnimationSample.value.imageObj;
  }
  return internalImageObj.value;
});
const selectedAnnotations = computed(() => selectedAnnotationIdentifiers.value.map((identifier) => annotationIdentifierMap.value[identifier]));
const selectedAnnotationInfo = computed(() => {
  if (selectedAnnotationIdentifiers.value.length > 0) {
    return annotationIdentifierMap.value[selectedAnnotationIdentifiers.value[0]];
  }
  return null;
});
const showPanes = computed(() => {
  const showCanvasTypes = ['image', 'box', 'seg'];
  const showDepthTypes = ['depthmap'];
  return {
    showCanvas: showCanvasTypes.some((key) => showTypes.value[key]),
    showDepthMap: showDepthTypes.some((key) => showTypes.value[key]),
  };
});
// const showPanesCount = computed(() => Object.values(showPanes.value).filter(Boolean).length);
const annotationSets = computed(() => sourceDataset.value.annotation_sets);

const groupedAnnotations = computed(() => {
  if (internalEditAsSequence.value && isSequence.value && currentAnimationSample.value) {
    // For animation
    const groupedData = {};
    topics.value.forEach((key) => {
      groupedData[key] = [];
    });

    const filteredAnnotations = filterAnnotationsBySet(internalAnnotations.value, annotationDisplaySettings.value.displayAnnotationSets.annotation_sets);
    filteredAnnotations.forEach((item) => {
      const itemType = item.type;
      if (!groupedData[itemType]) {
        groupedData[itemType] = [];
      }
      groupedData[itemType].push(item);
    });
    return groupedData;
  } else {
    // For non-animation
    if (!internalAnnotations.value) return {};

    const groupedData = {};
    const filteredAnnotations = filterAnnotationsBySet(internalAnnotations.value, annotationDisplaySettings.value.displayAnnotationSets.annotation_sets);
    filteredAnnotations.forEach((item) => {
      const itemType = item.type;
      if (!groupedData[itemType]) {
        groupedData[itemType] = [];
      }
      groupedData[itemType].push(item);
    });
    return groupedData;
  }
});

// Watchers

watch(internalImageObj, async () => {
  internalAnnotations.value = filterAnnotations(internalImageObj.value?.annotations);
  nextTick(() => { prevInternalAnnotations.value = null; });

  if (isSequence.value) {
    topics.value = await getTopics();
  }
});

watch(internalAnnotations, (newAnnotations, prevAnnotations) => {
  prevInternalAnnotations.value = JSON.parse(JSON.stringify(prevAnnotations || []));
}, { deep: true });

watch(annotationSetToEdit, () => {
  internalAnnotations.value = filterAnnotations(internalAnnotations.value);
}, { deep: true });

watch(annotationDisplaySettings, () => {
  internalAnnotations.value = filterAnnotations(internalAnnotations.value);
}, { deep: true });

watch(imageDimensions, () => {
  if (imageDimensions.value) {
    drawCursorSize.value = 0.04 * imageDimensions.value.width;
  }
});

watch(showTypes, () => {
  if (showTypes.value.pcd || showTypes.value['3dbox']) {
    handleAddVisualization("3d-canvas");
  }
  if (showTypes.value.depthmap) {
    handleAddVisualization("depth-map");
  }
}, { deep: true });

onMounted(async () => {
  selectedLabel.value = labels.value[0];

  imageModal.value.showModal();
  if (showPanes.value.showCanvas) {
    activePane.value = 'canvas';
  }

  if (hasSourceAnnotationSet.value) {
    annotationSetToEdit.value = sourceAnnotationSet.value;
  } else {
    annotationSetToEdit.value = destinationAnnotationSet.value;
  }

  // Initial SAM options
  if (store.state.annotationTool && reviewSettings.value?.reviewTask.type === 'label') {
    samOptions.value.enabled = store.state.annotationTool.samEnabled;
  } else {
    samOptions.value.enabled = false;
  }

  internalEditAsSequence.value = editAsSequence.value;

  // Get all sequences for the dataset
  if (hasReviewTask.value) {
    sequences.value = await getTaskSequences();
  }

  // Setup image and annotations
  await setupTaskImages();

  isMounted.value = true;
});

// Methods
async function createNewInternalAnnotation(annotation) {
  if (isAuditMode.value) {
    annotation.reviewStatus = 'verified';
  }

  annotation.annotation_set_id = destinationAnnotationSet.value.id;

  internalAnnotations.value = [...toRaw(internalAnnotations.value), annotation];
}

function handleTaskImageClicked(imageIndex) {
  switchToTaskImage(imageIndex);
  // if (isAuditMode.value) {
  //   if (imageIndex >= 0) {
  //     annotationSetToEdit.value = destinationAnnotationSet.value;
  //   } else {
  //     annotationSetToEdit.value = sourceAnnotationSet.value;
  //   }
  // }
}

function handleAnnotationBulkDeleteComplete() {
  animationControls.value.handleClearCache();
}

function handleModalClosed() {
  selectedAnnotationIdentifiers.value = [];
  emit('closed');
}

function handleUndo() {
  if (prevInternalAnnotations.value) {
    internalAnnotations.value = JSON.parse(JSON.stringify(prevInternalAnnotations.value || []));
    nextTick(() => { prevInternalAnnotations.value = null; });
  }
}

function handleRevertChanges() {
  if (currentAnimationSample.value) {
    internalAnnotations.value = filterAnnotations(currentAnimationSample.value.imageObj?.annotations);
  } else {
    internalAnnotations.value = filterAnnotations(internalImageObj.value?.annotations);
  }
}

// Copy and paste annotation
const copiedAnnotations = ref([]);
function handleCopyAnnotations() {
  copiedAnnotations.value = [...JSON.parse(JSON.stringify(selectedAnnotations.value)).map((item) => {
    item.id = uuidv4();
    item.reviewStatus = 'verified';
    return item;
  })];
}
function handlePasteAnnotations() {
  internalAnnotations.value = [...internalAnnotations.value, ...copiedAnnotations.value];
}

function handleDeleteAnnotationBulk() {
  bulkDeleteModal.value.showModal();
}

async function handleAnnotationReviewItemEdit(annotation) {
  const annotationIdentifier = Object.keys(annotationIdentifierMap.value).find((key) => annotationIdentifierMap.value[key] === annotation);
  selectedAnnotationIdentifiers.value = [annotationIdentifier];
  await nextTick();
  annotationCanvas.value.zoomToSelection();
}

async function handleSubmit() {
  // Editing through labelling task
  await handleLabellingSubmitAnnotations();
}

function handleSequenceSubmit() {
  // Go to next frame
  if (frame.value + 1 <= animationImageCache.value.length) {
    frame.value += 1;
  }
}

// function handleToggleTool() {
//   if (hasSourceAnnotationSet.value) {
//     if (auditorType.value === 'annotation') {
//       auditorType.value = 'image';
//     } else {
//       auditorType.value = 'annotation';
//     }
//   }
// }

function handleHoveredAnnotation(identifier) {
  hoveredAnnotationListIdentifier.value = identifier;
}

function handleDeleteAnnotationFromList(annotationToDelete) {
  internalAnnotations.value = [...internalAnnotations.value.filter((anno) => anno.id !== annotationToDelete.id)];
}

function handlePaneClick(event, pane) {
  activePane.value = pane;

  if (pane === 'canvas') {
    toolHotkeys.value = canvasHotkeys;
  } else if (pane === '3d-canvas') {
    toolHotkeys.value = canvas3DHotkeys;
  }

  if (pane === '3d-canvas' && current3DCanvasTool.value === '3d-box') {
    event.stopPropagation();
  }
}

function handleSelectedSequenceChanged(selectedSequence) {
  currentTaskImage.value = selectedSequence;
}

function handleSelectAnnotationFromSidebar(anns) {
  if (anns) {
    selectedAnnotationIdentifiers.value = [anns.id];
  }
}

function handleImageLoaded(dimensions) {
  imageDimensions.value = dimensions;
}

// Visualizations
const animationControls = ref(null);
const {
  currentAnimationSample,
  keyframeAnnotations,
  topics,
  isInitializingAnimation,
  frame,
  animationImageCache,
} = storeToRefs(useViewerVisualizationsStore());
const showLayouts = ref(true);
if (auditorType.value === 'annotation') {
  showLayouts.value = false;
}
onUnmounted(() => {
  const { $reset } = useViewerVisualizationsStore();
  $reset();
});
watch(currentAnimationSample, () => {
  if (currentAnimationSample.value) {
    internalAnnotations.value = [
      ...filterAnnotations(currentAnimationSample.value.imageObj?.annotations),
      ...keyframeAnnotations.value,
      ...getFrameSAM2Annotations(currentAnimationSample.value?.imageObj?.id),
    ];
  }
}, { deep: true });
const isLoadingSample = computed(() => {
  if (currentAnimationSample.value) {
    return !currentAnimationSample.value.loaded;
  } else if (internalEditAsSequence.value && isSequence.value) {
    return true;
  }
  return false;
});
watch(displayImageObj, () => {
  if (drawMode.value === 'sam') {
    drawMode.value = 'pointer';
  }
  nextTick(() => {
    selectedAnnotationIdentifiers.value = [];
    if (hasSourceAnnotationSet.value) {
      if (displayImageObj.value?.review_status === "Done") {
        annotationSetToEdit.value = destinationAnnotationSet.value;
      } else {
        annotationSetToEdit.value = sourceAnnotationSet.value;
      }
    } else {
      annotationSetToEdit.value = destinationAnnotationSet.value;
    }
  });
});

// Setup all topics in sequence
async function getTopics() {
  const dataConnect = new DatastoreConnect();
  const topicsResp = await dataConnect.getDatasetTopics({ dataset_id: internalImageObj.value.dataset_id });
  if (!topicsResp.result || topicsResp.error) {
    return null;
  }
  return topicsResp.result;
}

// SAM
const {
  SAMEventBus, handleCreateSAMPolygon, SAMImageEmbedding, SAMSession,
} = useSAMCanvas();

const show3DTo2DProjection = ref(false);

function handleAnnotationDeleteClicked(identifier) {
  internalAnnotations.value = [...internalAnnotations.value.filter((anno) => anno.id !== identifier)];
  selectedAnnotationIdentifiers.value = [];
}

const {
  handleAddVisualization,
  handleVisualizationSelected,
  handleVisualizationContainerDrop,
  handleLayoutSelected,
  visualizationLocations,
  getLocationId,
  layout,
} = useVisualizationDisplays();

</script>

<style lang="scss" scoped>
.display {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  flex: 1 1 auto;
}

.image-title-description{
  display: flex;
  flex-direction: column;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 100px;
  overflow: hidden;
  flex: 1 1 fit-content;
  max-width: fit-content;

  .title {
    font-size: 1rem;
    font-weight: 600;
  }
  .subtitle {
    font-size: 0.8rem;
    font-weight: 500;
  }
}

.visualization {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  min-width: 0;

  &__horizontal-divider {
    outline: solid 2px gray;
    z-index: 2;
  }

  &__vertical-divider {
    min-width: 3px;
    max-width: 3px;
    background: var(--color-primary-400);
    z-index: 2;
  }
}

.annotation-editor-modal {
  &__sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 300px;
    min-width: 300px;
    padding: 0;
    z-index: 2;
    @include themify() {
      background: themed('color-white-100');
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 40px;
      padding: 4px 8px;
      margin: 0;
      text-align: left;
      box-shadow: 0px 1px 3px grey;
      @include themify() {
        background: themed('color-white-900');
      }
    }
  }
}

.annotation-tool {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  @include themify() {
    background: themed('image-background-fill');
  }
}

.annotation-review-sidebar {
  @include themify() {
    background: themed('color-white-100');
  }

  &__buttons {
    width: 100%;
    padding: 0 16px;

    button {
      width: 100%;
    }
  }

  &__annotations {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
  }

  &__snap-container {
    scroll-snap-align: center;
    min-height: calc(100% / 3);
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding: 16px;

    &._active {
      background: rgb(0,0,0,0.125);
    }
  }
}

.modal-prepend-header {
  &__logo {
    min-width: 56px;
    height: 36px;
  }
}

.review-task-header {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
}

.review-task-header-info {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex: 1 1 auto;
  min-width: 100px;
  overflow-x: auto;
  max-width: fit-content;
}

#no-image-remaining {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-primary);
}

.image-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  &__label {
    color: var(--body-text-color-secondary);
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 8px 12px 16px;
}
:deep(.v-expansion-panel-title__overlay) {
  pointer-events: none;
}

</style>
