<template>
  <div
    id="konva-container"
    ref="konvaContainer"
    class="konva-container"
    @mouseout="handleCanvasMouseout"
  >
    <v-stage
      ref="stage"
      :config="configKonva"
      @wheel="handleCanvasZoomScroll"
      @pointerclick="handleStageClick($event)"
      @pointermove="debounceHandleMouseover"
      @dragstart="handleDragStart"
      @dragend="handleDragEnd"
    >
      <v-layer>
        <!-- KonvaImage or KonvaVideo component -->
        <component
          :is="imageComponent"
          ref="konvaImage"
          :imagePath="imagePath"
          :imageId="imageObj.id"
          v-bind="{
            ...(imageComponent === 'KonvaVideo' ? {stageReady} : {}),
            ...(imageComponent === 'KonvaImage' ? {useImageCacheOnly, imageCacheElem, showPointerAxes} : {})
          }"
          @new-coordinates="handleMouseCoordinates"
          @image-loaded="handleImageLoaded"
          @pointermove="(isSAMToolSelected && samActive && samMaskImg) ? debouncedHandleSAMMousemove(stageNode) : ''"
        />
      </v-layer>
      <v-layer>
        <SAMSelectBox
          v-if="stageReady && isSAMToolSelected && samActive && samBoxActive"
          ref="samSelectBox"
          :stageNode="stageNode"
          :stageScale="stageScale"
          :imageNode="imageNode"
          :samBoxActive="samBoxActive"
          @sam-box-dimensions-changed="handleSAMBoxDimensionChanged"
        />
        <SAM2SelectBox
          v-else-if="stageReady && isSAM2ToolSelected && isSAM2BoxMode"
          ref="samSelectBox"
          :stageNode="stageNode"
          :stageScale="stageScale"
          :imageNode="imageNode"
          :imageId="imageObj.id"
        />
        <AnnotationBoxTool
          v-else-if="stageReady && isBoxAnnotating"
          :stageNode="stageNode"
          :stageScale="stageScale"
          :selectedLabel="selectedLabel"
          :imageDimensions="imageDimensions"
          :editingEnabled="editingEnabled"
          :labelColorMap="labelColorMap"
          @created-annotation-box="handleAnnotationBoxCreated"
        />
        <CanvasSelectBox
          v-else-if="stageReady"
          ref="selectRect"
          v-model:selectedObjects="selectedObjects"
          :stageNode="stageNode"
          :stageScale="stageScale"
        />

        <SAM2Boxes
          v-if="sam2Options && isSAM2ToolSelected"
          :stageScale="stageScale"
          :imageId="imageObj.id"
        />
        <SAMClickPoint
          v-if="samOptions && isSAMToolSelected && samActive"
          :clicks="samClicks"
          :stageScale="stageScale"
        />
        <SAM2ClickPoint
          v-if="sam2Options && isSAM2ToolSelected"
          :stageScale="stageScale"
          :imageId="imageObj.id"
        />
      </v-layer>

      <v-layer>
        <template v-if="imageIdDisplayed === imageObj.id">
          <template v-if="stageReady && showAnnotations && showTypes?.mask">
            <KonvaSegmentationMask
              v-for="(anno) in maskAnnotations"
              :ref="el => { canvasObjects[anno.id] = el }"
              :key="anno.name"
              :imageDimensions="imageDimensions"
              :annotation="anno"
              :labels="labels"
            />
          </template>
          <template v-if="showAnnotations && showTypes?.seg">
            <KonvaSegmentationPolygon
              v-for="(anno, i) in segmentationPolygonAnnotations"
              :ref="anno.id"
              :key="i"
              v-model:polygon="anno.polygon"
              :identifier="anno.id"
              :imageDimensions="imageDimensions"
              :isSelected="selectedObjects.includes(anno.id)"
              :isAnnotating="isAnnotating"
              :pointerCoordinates="imagePointerCoordinates"
              :fill="getAnnotationColor(anno)"
              :stageNode="stageNode"
              :stageScale="stageScale"
              :imageNode="imageNode"
              :editingEnabled="editingEnabled"
              :pending="anno?.pending"
              @shape-selected="handleShapeSelected(anno.id)"
              @polygon-drag-end="() => {}"
            />
          </template>
          <template v-if="sam2AnnotationsForImage?.length > 0">
            <KonvaSegmentationPolygon
              v-for="(anno, i) in sam2AnnotationsForImage"
              :ref="anno.id"
              :key="i"
              v-model:polygon="anno.polygon"
              :identifier="anno.id"
              :imageDimensions="imageDimensions"
              :isSelected="selectedObjects.includes(anno.id)"
              :isAnnotating="isAnnotating"
              :pointerCoordinates="imagePointerCoordinates"
              :fill="getAnnotationColor(anno)"
              :stageNode="stageNode"
              :stageScale="stageScale"
              :imageNode="imageNode"
              :editingEnabled="editingEnabled"
              :pending="anno?.pending"
              :pendingSelected="sam2SelectedObject === anno.obj_id"
              @shape-selected="handleShapeSelected(anno.id)"
              @polygon-drag-end="() => {}"
            />
          </template>
          <template v-if="stageReady && showAnnotations && showTypes?.box">
            <AnnotationBox
              v-for="(anno) in boxAnnotations"
              :ref="el => { canvasObjects[anno.id] = el }"
              :key="anno.name"
              v-model:x="anno.x"
              v-model:y="anno.y"
              v-model:width="anno.w"
              v-model:height="anno.h"
              :identifier="anno.id"
              :imageDimensions="imageDimensions"
              :stageNode="stageNode"
              :stageScale="stageScale"
              :imageNode="imageNode"
              :displayLabelTag="displayLabelTags?.box"
              :name="getAnnotationName(anno)"
              :fillColor="''"
              :color="getAnnotationColor(anno)"
              :isSelected="selectedObjects.includes(anno.id)"
              :isHighlighting="highlightedAnnotationIdentifier === anno.id"
              :isAnnotating="isAnnotating"
              :dashEnabled="isAuditMode && anno.reviewStatus !== 'verified'"
              :editingEnabled="editingEnabled"
              @shape-selected="handleShapeSelected(anno.id)"
              @transformed="handleAnnotationTransformed"
              @box-clicked-while-annotating="handleBoxClickWhileAnnotating($event, anno)"
              @box-mouseover-while-annotating="handleBoxMouseoverWhileAnnotating($event, anno)"
              @box-mouseout-while-annotating="handleBoxMouseoutWhileAnnotating($event, anno)"
            />
          </template>
          <Project3Dto2D
            v-if="show3DTo2DProjection"
            :boxes3D="box3DAnnotations"
            :imageDimensions="imageDimensions"
            :stageScale="stageScale"
            :labels="labels"
          />
        </template>
      </v-layer>
      <v-layer ref="brushLayer">
        <v-image
          v-if="stageReady && drawMode === 'sam' && (isSAMToolSelected && samActive && samMaskImg)"
          ref="samMask"
          :config="configSAMMaskImg"
          @pointermove="debouncedHandleSAMMousemove(stageNode)"
          @pointerout="handleSAMMouseout"
          @pointerclick="handleSAMMaskClick($event, stageNode)"
        />
        <v-image
          v-else-if="stageReady && isSAM2ToolSelected"
          ref="sam2Mask"
          :config="{
            x: 0,
            y: 0,
            image: null,
            width: imageDimensions.width,
            height: imageDimensions.height,
            draggable: false,
            opacity: 0.5,
          }"
          @pointerclick="handleSAM2ImageClick(
            $event,
            stageNode.getRelativePointerPosition().x,
            stageNode.getRelativePointerPosition().y,
            imageObj.id
          )"
        />
        <KonvaBrush
          v-else-if="stageReady && (drawMode === 'brush' || drawMode === 'eraser' || drawMode === 'line')"
          :stageNode="stageNode"
          :imageDimensions="imageDimensions"
          :drawMode="drawMode"
          :drawCursorSize="drawCursorSize"
          :fill="drawToolColor"
          :polygon="editingSegmentationPolygonAnnotation?.polygon"
          :enableBrush="enableBrush"
          :doneVertexDraw="doneVertexDraw"
          :selectedLabel="selectedLabel"
          :labels="labels"
          :labelColorMap="labelColorMap"
          :scale="stageScale.x"
          @edit-polygon="handleBrushEditPolygon"
          @new-polygon="handleNewBrushPolygon"
        />
      </v-layer>
      <!-- <v-layer v-if="stageReady && (drawMode === 'brush' || drawMode === 'eraser' || drawMode === 'line')" ref="brushLayer">
        <KonvaBrush
          :stageNode="stageNode"
          :imageDimensions="imageDimensions"
          :drawMode="drawMode"
          :drawCursorSize="drawCursorSize"
          :fill="drawToolColor"
          :polygon="editingSegmentationPolygonAnnotation?.polygon"
          :enableBrush="enableBrush"
          :doneVertexDraw="doneVertexDraw"
          :selectedLabel="selectedLabel"
          :labels="labels"
          :labelColorMap="labelColorMap"
          :scale="stageScale.x"
          @edit-polygon="handleBrushEditPolygon"
          @new-polygon="handleNewBrushPolygon"
        />
      </v-layer> -->
    </v-stage>
  </div>
</template>

<script>
import { debounce } from '@/assets/js/utils.js';
import Konva from 'konva';
import {
  ref, toRefs, onMounted, computed,
} from 'vue';
import { useResizeObserver, useDebounceFn } from '@vueuse/core';
import useZoom from '@/composables/canvas/useZoom.js';
import useSAMCanvas from '@/composables/canvas/useSAMCanvas.js';
import useAnnotationColorMap from '@/composables/useAnnotationColorMap.js';
import useColorParser from '@/composables/useColorParser.js';
// import useBrushToPolygon from '@/composables/annotationTool/useBrushToPolygon.js';
import { v4 as uuidv4 } from 'uuid';
import { useSAM2Store } from '@/stores/useSAM2Store.js';
import { storeToRefs } from 'pinia';
import KonvaImage from './KonvaImage.vue';
import KonvaVideo from './KonvaVideo.vue';
import KonvaBrush from './KonvaBrush.vue';
import KonvaSegmentationMask from './KonvaSegmentationMask.vue';
import KonvaSegmentationPolygon from './KonvaSegmentationPolygon.vue';
import AnnotationBox from './AnnotationBox.vue';
import SAMClickPoint from './SAMClickPoint.vue';
import SAMSelectBox from './SAMSelectBox.vue';
import SAM2ClickPoint from './SAM2ClickPoint.vue';
import SAM2SelectBox from './SAM2SelectBox.vue';
import SAM2Boxes from './SAM2Boxes.vue';
import CanvasSelectBox from './CanvasSelectBox.vue';
import AnnotationBoxTool from './AnnotationBoxTool.vue';

import Project3Dto2D from './Project3Dto2D.vue';

Konva.dragButtons = [0, 1];

export default {
  name: "AnnotationCanvas",
  components: {
    KonvaImage,
    KonvaVideo,
    KonvaSegmentationMask,
    KonvaSegmentationPolygon,
    CanvasSelectBox,
    AnnotationBoxTool,
    AnnotationBox,
    SAMClickPoint,
    SAMSelectBox,
    SAM2ClickPoint,
    SAM2SelectBox,
    SAM2Boxes,
    Project3Dto2D,
    KonvaBrush,
  },
  props: {
    imageObj: {
      type: Object,
      default: () => {},
    },
    useImageCacheOnly: {
      type: Boolean,
      default: false,
    },
    imageCacheElem: {
      type: HTMLElement,
      default: null,
    },
    annotations: {
      type: Array,
      default: () => [],
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    selectedAnnotationSets: {
      type: Array,
      default: () => [],
    },
    updatedAnnotations: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    selectedLabel: {
      type: Object,
      default: null,
    },
    scale: {
      type: Number,
      default: 1,
    },
    selectedAnnotationIdentifiers: {
      type: Array,
      default: () => [],
    },
    colorBy: {
      type: String,
      default: 'label_index',
    },
    showAnnotations: {
      type: Boolean,
      default: true,
    },
    displayLabelTags: {
      type: Object,
      default: () => {},
    },
    editingEnabled: {
      type: Boolean,
      default: false,
    },
    enableSAM: {
      type: Boolean,
      default: false,
    },
    hasSAMImageEmbedding: {
      type: Boolean,
      default: false,
    },
    samOptions: {
      type: Object,
      default: null,
    },
    shouldZoomToFit: {
      type: Boolean,
      default: true,
    },
    highlightedAnnotationIdentifier: {
      type: [String, Number],
      default: null,
    },
    isAuditMode: {
      type: Boolean,
      default: false,
    },
    show3DTo2DProjection: {
      type: Boolean,
      default: false,
    },
    showTypes: {
      type: Object,
      default: () => ({
        box: true,
        seg: true,
      }),
    },
    selectedLabels: {
      type: Object,
      default: null,
    },
    drawMode: {
      type: String,
      default: 'pointer',
    },
    drawCursorSize: {
      type: Number,
      default: 0,
    },
    doneVertexDraw: {
      type: Boolean,
      default: false,
    },
    showPointerAxes: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'new-coordinates',
    'update:detectedObject',
    'image-loaded',
    'update:zonePosition',
    'update:zoneSize',
    'update:updatedAnnotations',
    'create-annotation',
    'update:scale',
    'update:selectedAnnotationIdentifiers',
    'update:hasSAMImageEmbedding',
    'update:samOptions',
    'update:samMask', 'remove-annotation',
  ],
  setup(props, ctx) {
    const {
      imageObj, annotations, selectedLabel, enableSAM, samOptions, labels, annotationSets, highlightedAnnotationIdentifier, drawMode,
    } = toRefs(props);
    let expose = {};

    // const {
    //   processImage,
    // } = useBrushToPolygon();

    // Setup canvas
    const konvaContainer = ref(null);
    const imageDimensions = ref(null);
    const stage = ref(null);
    const stageNode = ref(null);
    const stageScale = ref({ x: 1, y: 1 });
    const configKonva = ref({
      width: 100,
      height: 100,
      draggable: true,
      dragBoundFunc: null,
    });

    onMounted(() => {
      stageNode.value = stage.value.getStage();
    });
    const imagePath = computed(() => {
      if (imageObj.value.id && (imageObj.value.type === 'image' || imageObj.value.type === '')) {
        return `v3/enterprise/image/${imageObj.value.id}`;
      } else if (imageObj.value.id && imageObj.value.type === 'video') {
        return `video/${imageObj.value.id}`;
      }
      return null;
    });
    const imageComponent = computed(() => {
      if (imageObj.value.type === 'image') {
        return 'KonvaImage';
      } else if (imageObj.value.type === 'video') {
        return 'KonvaVideo';
      }
      return 'KonvaImage';
    });

    // Setup canvas objects
    const canvasObjects = ref({});
    const selectedObjects = ref([]);
    const selectedNodes = computed(() => Object.entries(canvasObjects.value)
      .map(([key, val]) => { if ((selectedObjects.value.map((v) => v.toString())).includes(key)) return val.node; })
      .filter((node) => node));

    // Setup canvas zoom and resize
    const {
      zoomToFitImage, zoomToSelection: baseZoomToSelection, zoomToBox: baseZoomToBox, handleCanvasZoomScroll,
    } = useZoom(konvaContainer, imageDimensions, stageNode, stageScale);
    function zoomToSelection() {
      baseZoomToSelection(selectedNodes);
    }
    function zoomToBox({
      x1, x2, y1, y2,
    }) {
      baseZoomToBox({
        x1, x2, y1, y2,
      });
    }
    function handleCanvasResize() {
      configKonva.value.width = konvaContainer.value.clientWidth;
      configKonva.value.height = konvaContainer.value.clientHeight;
      zoomToFitImage();
    }
    useResizeObserver(konvaContainer, useDebounceFn(handleCanvasResize, 15));
    // useResizeObserver(konvaContainer, () => setTimeout(handleCanvasResize, 0));
    expose = {
      ...expose,
      zoomToFitImage,
      zoomToSelection,
      zoomToBox,
    };

    // Setup Annotation Colors
    const { map: labelColorMap } = useAnnotationColorMap({ items: labels, key: 'index' });
    const { map: annotationSetColorMap } = useAnnotationColorMap({ items: annotationSets, key: 'id' });

    // Setup SAM
    let SAM = {
      isSAMToolSelected: null,
      samMaskImg: null,
      samActive: null,
      samClicks: null,
      samBox: null,
      samBoxActive: null,
      configSAMMaskImg: null,
      handleSAMMaskClick: null,
      handleSAMMousemove: null,
      debouncedHandleSAMMousemove: null,
      debouncedHandleSAMMouseout: null,
      handleSAMMouseout: null,
      handleSAMBoxDimensionChanged: null,
    };
    const isSAMToolSelected = computed(() => {
      if (drawMode.value === 'sam') {
        return true;
      }
      return false;
    });
    if (enableSAM.value) {
      const useSAM = useSAMCanvas(ctx, samOptions, {
        imageObj, imageDimensions, annotations, selectedLabel, isSAMToolSelected,
      });
      useSAM.startSAMCanvas();
      SAM = { ...useSAM };
    }

    // Setup SAM2
    const sam2Store = useSAM2Store();
    const { handleSAM2ImageClick } = sam2Store;
    const {
      sam2Options,
      sam2Annotations,
      selectedObject: sam2SelectedObject,
      isSAM2ClickMode,
      isSAM2BoxMode,
    } = storeToRefs(sam2Store);
    const isSAM2ToolSelected = computed(() => {
      if (drawMode.value === 'sam2') {
        return true;
      }
      return false;
    });

    const {
      parseColor,
    } = useColorParser();

    ctx.expose(expose);
    return {
      konvaContainer,
      stage,
      imageDimensions,
      stageNode,
      stageScale,
      configKonva,
      imagePath,
      imageComponent,
      canvasObjects,
      selectedObjects,
      selectedNodes,
      zoomToFitImage,
      zoomToSelection,
      zoomToBox,
      handleCanvasZoomScroll,
      handleCanvasResize,
      labelColorMap,
      annotationSetColorMap,
      samMaskImg: SAM.samMaskImg,
      samActive: SAM.samActive,
      samClicks: SAM.samClicks,
      samBox: SAM.samBox,
      samBoxActive: SAM.samBoxActive,
      isSAMAnnotating: SAM.isAnnotating,
      configSAMMaskImg: SAM.configSAMMaskImg,
      handleSAMMaskClick: SAM.handleSAMMaskClick,
      handleSAMMousemove: SAM.handleSAMMousemove,
      debouncedHandleSAMMousemove: SAM.debouncedHandleSAMMousemove,
      debouncedHandleSAMMouseout: SAM.debouncedHandleSAMMouseout,
      handleSAMMouseout: SAM.handleSAMMouseout,
      handleSAMBoxDimensionChanged: SAM.handleSAMBoxDimensionChanged,
      isSAMToolSelected,
      // processImage,
      parseColor,
      sam2Options,
      sam2Annotations,
      sam2SelectedObject,
      isSAM2ClickMode,
      isSAM2BoxMode,
      handleSAM2ImageClick,
      isSAM2ToolSelected,
    };
  },
  data() {
    return {
      isSelectionMousedown: false,
      isSelectionDragging: false,
      isStageDragging: false,
      isAnnotationBoxMousedown: false,
      isAnnotationBoxDragging: false,
      imageNode: null,
      imagePointerCoordinates: null,
      internalAnnotations: [],
      mouseoverObjectWhileAnnotating: null,
      imageIdDisplayed: null,
    };
  },
  computed: {
    debounceHandleMouseover() {
      return debounce(() => { this.handleMouseover(); }, 2);
    },
    dragBoundFunc() {
      const dragBoundFunc = (pos) => {
        const maxX = this.configKonva.width - 20;
        const minX = -(this.imageDimensions.width * this.stageScale.x) + 20;
        const maxY = this.configKonva.height - 20;
        const minY = -(this.imageDimensions.height * this.stageScale.y) + 20;
        const x = Math.max(minX, Math.min(pos.x, maxX));
        const y = Math.max(minY, Math.min(pos.y, maxY));
        return {
          x,
          y,
        };
      };
      return dragBoundFunc;
    },
    stageReady() {
      return Boolean(this.stageNode && this.imageNode && this.imageDimensions && this.stageScale);
    },
    isBox() {
      if (this.drawMode === 'box') {
        return true;
      }
      return false;
    },
    isBoxAnnotating() {
      if (this.isBox && this.selectedLabel) {
        return true;
      }
      return false;
    },
    isAnnotating() {
      if (this.isBoxAnnotating || this.isSAMAnnotating) {
        return true;
      }
      return false;
    },
    isColoring() {
      if (this.drawMode === 'color') {
        return true;
      }
      return false;
    },
    annotationIdentifierMap() {
      const annotationIdentifierMap = {};
      this.internalAnnotations.forEach((anno, i) => {
        annotationIdentifierMap[anno.id] = anno;
      });
      return annotationIdentifierMap;
    },
    boxAnnotations() {
      return this.internalAnnotations.filter((anno) => {
        const isLabelSelected = !this.selectedLabels || this.selectedLabels?.includes(anno.label_index);
        const isAnnotationSetSelected = !this.selectedAnnotationSets || this.selectedAnnotationSets?.includes(anno.annotation_set_id);
        return (anno.type === "box" || anno.type === "") && isLabelSelected && isAnnotationSetSelected;
      });
    },
    editingBoxAnnotation() {
      if (this.selectedObjects.length === 1) {
        return this.internalAnnotations.find((anno) => anno.type === "box" && this.selectedLabels?.includes(anno.label_index) && this.selectedObjects[0] === anno.id);
      } else {
        return null;
      }
    },
    box3DAnnotations() {
      return this.internalAnnotations.filter((anno) => {
        const isLabelSelected = !this.selectedLabels || this.selectedLabels?.includes(anno.label_index);
        const isAnnotationSetSelected = !this.selectedAnnotationSets || this.selectedAnnotationSets?.includes(anno.annotation_set_id);
        return anno.type === "3dbox" && isLabelSelected && isAnnotationSetSelected;
      });
    },
    segmentationPolygonAnnotations() {
      return this.internalAnnotations.filter((anno) => {
        const isLabelSelected = !this.selectedLabels || this.selectedLabels?.includes(anno.label_index);
        const isAnnotationSetSelected = !this.selectedAnnotationSets || this.selectedAnnotationSets?.includes(anno.annotation_set_id);
        return anno.type === "seg" && isLabelSelected && isAnnotationSetSelected;
      });
    },
    sam2AnnotationsForImage() {
      return this.sam2Annotations.filter((anno) => anno.image_id === this.imageObj?.id);
    },
    editingSegmentationPolygonAnnotation() {
      if (this.selectedObjects.length === 1) {
        return this.internalAnnotations.find((anno) => anno.type === "seg" && (!this.selectedLabels || this.selectedLabels?.includes(anno.label_index)) && this.selectedObjects[0] === anno.id);
      } else {
        return null;
      }
    },
    maskAnnotations() {
      return this.internalAnnotations.filter((anno) => anno.type === "mask");
    },
    labelIndexMap() {
      return this.labels.reduce((acc, item) => {
        acc[item.index] = item;
        return acc;
      }, {});
    },
    drawToolColor() {
      if (this.selectedObjects.length === 1 && this.segmentationPolygonAnnotations.length > 0) {
        const ann = this.segmentationPolygonAnnotations.find((e) => e.id === this.selectedObjects[0]);
        if (ann) {
          return this.getAnnotationColor(ann);
        }
      }
      return null;
    },
    enableBrush() {
      return !this.editingBoxAnnotation && (this.selectedAnnotationIdentifiers.length === 0
      || this.editingSegmentationPolygonAnnotation !== undefined || this.editingSegmentationPolygonAnnotation !== null);
    },
  },
  watch: {
    dragBoundFunc: {
      immediate: true,
      handler(newFunc) {
        this.configKonva.dragBoundFunc = newFunc;
      },
    },
    annotations: {
      deep: true,
      handler(updatedAnnotations) {
        this.internalAnnotations = JSON.parse(JSON.stringify(updatedAnnotations));
      },
    },
    stageScale() {
      this.$emit('update:scale', this.stageScale.x);
    },
    selectedAnnotationIdentifiers: {
      deep: true,
      handler(selectedAnnotationIdentifiers) {
        this.selectedObjects = selectedAnnotationIdentifiers;
      },
    },
    selectedObjects() {
      this.$emit('update:selectedAnnotationIdentifiers', this.selectedObjects);
    },
    isAnnotating() {
      if (this.isAnnotating) {
        this.stageNode.container().style.cursor = 'crosshair';
      }
    },
  },
  mounted() {
    this.imageNode = this.$refs.konvaImage.getImageNode();
    this.internalAnnotations = JSON.parse(JSON.stringify(this.annotations));
  },
  methods: {
    async handleImageLoaded(imageInfo) {
      this.imageDimensions = { width: imageInfo.width, height: imageInfo.height };
      this.imageIdDisplayed = imageInfo.imageId;
      await this.$nextTick();
      this.stageNode.scale(
        { x: 1, y: 1 },
      );
      if (this.shouldZoomToFit) { await this.$nextTick(this.zoomToFitImage); }
      this.$emit('image-loaded', imageInfo);
    },
    handleStageClick(e) {
      if (!this.isSelectionDragging && !this.isStageDragging) {
        this.selectedObjects = [];
      }
    },
    handleMouseCoordinates(mouse) {
      this.imagePointerCoordinates = this.imageNode.getRelativePointerPosition();
      this.$emit('new-coordinates', mouse);
    },
    handleShapeSelected(identifier) {
      if (this.isColoring) {
        const index = this.internalAnnotations.map((e) => e.id).indexOf(identifier);
        this.internalAnnotations[index].label_index = this.selectedLabel.index;
        this.internalAnnotations[index].label_id = this.selectedLabel.id;
        this.internalAnnotations[index].label_name = this.selectedLabel.name;
        this.handleAnnotationTransformed();

        return;
      }
      if (!this.isSelectionDragging) {
        this.selectedObjects = [];
        this.selectedObjects.push(identifier);
      }
    },
    handleMouseover() {
      // Tell image to get new pointer position now that it has been registered
      this.$refs.konvaImage?.handleImagePointerMove();
    },
    handleDragStart() {
      this.isStageDragging = true;
      this.stageNode.container().style.cursor = "all-scroll";
    },
    handleDragEnd() {
      this.isStageDragging = false;
      this.stageNode.container().style.cursor = null;
    },
    handleAnnotationTransformed() {
      this.$emit('update:updatedAnnotations', JSON.parse(JSON.stringify(this.internalAnnotations)));
    },
    getAnnotationColor(annotation) {
      if (this.colorBy === 'set') {
        return this.annotationSetColorMap[annotation.annotation_set_id];
      } else {
        const targetLabel = this.labels.find((e) => e.id === annotation.label_id);

        if (targetLabel && targetLabel.color) {
          return this.parseColor(targetLabel.color);
        } else {
          return this.labelColorMap[annotation.label_index];
        }
      }
    },
    handleBoxClickWhileAnnotating(e, annotation) {
      if (this.mouseoverObjectWhileAnnotating) {
        annotation.label_index = this.selectedLabel.index;
        this.$emit('update:updatedAnnotations', JSON.parse(JSON.stringify(this.internalAnnotations)));
      }
    },
    handleBoxMouseoverWhileAnnotating(e, annotation) {
      this.mouseoverObjectWhileAnnotating = annotation;
    },
    handleBoxMouseoutWhileAnnotating(e, annotation) {
      this.mouseoverObjectWhileAnnotating = null;
    },
    handleAnnotationBoxCreated(newAnnotationBox) {
      newAnnotationBox.image_id = this.imageObj.id;
      this.$emit('create-annotation', newAnnotationBox);
    },
    getAnnotationName(anno) {
      if (this.labelIndexMap[anno.label_index]?.name) {
        return this.labelIndexMap[anno.label_index]?.name;
      }
      return anno.label_index;
    },
    handleBrushEditPolygon(polygon) {
      const index = this.internalAnnotations.map((e) => e.id).indexOf(this.editingSegmentationPolygonAnnotation.id);
      if (!polygon || polygon.length === 0) {
        this.$emit('remove-annotation', this.internalAnnotations[index].id);
        return;
      }
      this.internalAnnotations[index].polygon = JSON.stringify(polygon);
      this.handleAnnotationTransformed();
    },
    handleNewBrushPolygon(polygon) {
      const newAnnotationPolygon = {
        id: uuidv4(),
        label_index: this.selectedLabel.index,
        label_id: this.selectedLabel.id,
        label_name: this.selectedLabel.name,
        type: "seg",
        polygon: JSON.stringify(polygon),
        score: 1,
        reviewStatus: 'verified',
        image_id: this.imageObj.id,
        // annotation_set_id: this.selectedAnnotationSets.length > 0 ? this.selectedAnnotationSets[0] : 0,
      };

      this.internalAnnotations.push(newAnnotationPolygon);
      this.selectedObjects = [newAnnotationPolygon.id];
      this.handleAnnotationTransformed();
    },
    // handleSegmentationTransformed() {
    //   const canvas = this.$refs.brushLayer.getNode().getCanvas();
    //   const processed = this.processImage(
    //     canvas,
    //     0,
    //     0,
    //     0,
    //     0,
    //     this.editingSegmentationPolygonAnnotation.polygon,
    //     'brush',
    //   );
    //   const index = this.internalAnnotations.map((e) => e.id).indexOf(this.editingSegmentationPolygonAnnotation.id);
    //   this.internalAnnotations[index].polygon = JSON.stringify(processed);
    //   this.handleAnnotationTransformed();
    // },
    startPointDrawing() {

    },
    handleCanvasMouseout() {
      if (this.stageReady && (this.isSAMToolSelected && this.samActive && this.samMaskImg)) {
        this.debouncedHandleSAMMouseout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.konva-container {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' %3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.125'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat;
  background-color: var(--canvas-background);

  &._isAnnotating {
    cursor: crosshair;
  }

}
</style>
