<template>
  <!-- <Polygon
    :identifier="identifier"
    :imageDimensions="imageDimensions"
    :points="points"
    :fill="fill"
    :stroke="stroke"
    :opacity="displayOpacity"
    :strokeWidth="strokeWidth"
    :draggable="draggable"
    :closed="true"
    :scaleX="1"
    :scaleY="1"
    :nodeRadius="nodeRadius"
    :nodeFill="nodeFill"
    :nodeStroke="nodeStroke"
    :nodeStrokeWidth="nodeStrokeWidth"
    :dashEnabled="dashEnabled"
    :dashSize="dashSize"
    :isFocused="isFocused"
    :nodeDragBoundFunc="nodeDragBoundFunc"
    :polygonDragBoundFunc="polygonDragBoundFunc"
    @update:points="updatePoints"
    @pointerclick="handleShapeClick($event)"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
    @node-mouseover="handleNodeMouseover"
    @node-mouseout="handleNodeMouseout"
    @polygon-drag-end="$emit('polygon-drag-end')"
  /> -->
  <Polygon
    v-for="pts, index in multiPolygonPoints"
    :key="index"
    :identifier="identifier"
    :imageDimensions="imageDimensions"
    :points="pts"
    :fill="fill"
    :stroke="stroke"
    :opacity="displayOpacity"
    :strokeWidth="strokeWidth"
    :draggable="draggable"
    :closed="true"
    :scaleX="1"
    :scaleY="1"
    :nodeRadius="nodeRadius"
    :nodeFill="nodeFill"
    :nodeStroke="nodeStroke"
    :nodeStrokeWidth="nodeStrokeWidth"
    :dashEnabled="dashEnabled"
    :dashSize="dashSize"
    :isFocused="isFocused"
    :pending="pending"
    :pendingSelected="pendingSelected"
    @update:points="updatePointsV2($event, index)"
    @pointerclick="handleShapeClick($event)"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
    @node-mouseover="handleNodeMouseover"
    @node-mouseout="handleNodeMouseout"
  />
</template>

<script>
import Polygon from './Polygon.vue';

export default {
  name: 'KonvaSegmentationPolygon',
  components: {
    Polygon,
  },
  props: {
    identifier: {
      type: [String, Number],
      default: '',
    },
    imageDimensions: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isAnnotating: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Number,
      default: 1,
    },
    pointerCoordinates: {
      type: Object,
      default: null,
    },
    fill: {
      type: String,
      default: 'blue',
    },
    polygon: {
      type: String,
      default: null,
    },
    stageNode: {
      type: Object,
      default: () => {},
    },
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
    imageNode: {
      type: Object,
      default: () => {},
    },
    editingEnabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    pendingSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'shape-selected',
    'update:polygon',
    'transformed',
    'polygon-clicked-while-annotating',
    'polygon-mouseover-while-annotating',
    'polygon-mouseout-while-annotating', 'polygon-drag-end',
  ],
  data() {
    return {
      image: null,
      mask: null,
      maskCtx: null,
      width: 0,
      height: 0,
      isMouseOver: false,
      internalPolygon: null,
    };
  },
  computed: {
    draggable() {
      if (!this.editingEnabled || this.isAnnotating) {
        return false;
      }
      return true;
    },
    displayOpacity() {
      if (this.isSelected) {
        return 0.75;
      } else if (this.isMouseOver) {
        return 0.75;
      }
      return 0.5;
    },
    // points() {
    //   if (this.polygon && this.imageDimensions) {
    //     return this.convertRatioArrayToPixelArray(JSON.parse(this.polygon).flat().flat());
    //   }
    //   return [];
    // },
    multiPolygonPoints() {
      if (this.internalPolygon && this.imageDimensions) {
        return this.convertRatioArrayToPixelArrayV2(JSON.parse(this.internalPolygon));
      }
      return [];
    },
    xPoints() {
      return this.multiPolygonPoints.flat().filter((p, i) => i % 2 === 0);
    },
    yPoints() {
      return this.multiPolygonPoints.flat().filter((p, i) => i % 2 === 1);
    },
    minXPoint() {
      return Math.min(...this.xPoints);
    },
    maxXPoint() {
      return Math.max(...this.xPoints);
    },
    minYPoint() {
      return Math.min(...this.yPoints);
    },
    maxYPoint() {
      return Math.max(...this.yPoints);
    },
    nodeRadius() {
      return 5 / this.stageScale.x;
    },
    stroke() {
      if (this.isSelected) {
        return 'black';
      }
      return this.fill;
    },
    strokeWidth() {
      if (this.isSelected) {
        return 2 / this.stageScale.x;
      }
      return 1 / this.stageScale.x;
    },
    nodeFill() {
      return 'white';
    },
    nodeStroke() {
      return 'blue';
    },
    nodeStrokeWidth() {
      return 1 / this.stageScale.x;
    },
    dashEnabled() {
      if (this.isSelected) {
        return true;
      }
      return false;
    },
    dashSize() {
      const dashBaseSize = 3;
      return dashBaseSize / this.stageScale.x;
    },
    isFocused() {
      if (this.isSelected && this.editingEnabled) {
        return true;
      }
      return false;
    },
    getNodeDragBoundFunc() {
      const dragBoundFunc = (pos) => {
        const imagePos = this.imageNode.absolutePosition();
        const imageAttr = this.imageNode.getAttrs();
        // Get drag bounds with image node
        const minX = imagePos.x;
        const maxX = (imagePos.x + (imageAttr.width * this.stageScale.x));
        const minY = imagePos.y;
        const maxY = (imagePos.y + (imageAttr.height * this.stageScale.y));
        // Override box position
        const x = Math.max(minX, Math.min(pos.x, maxX));
        const y = Math.max(minY, Math.min(pos.y, maxY));
        return {
          x,
          y,
        };
      };
      return dragBoundFunc;
    },
    getPolygonDragBoundFunc() {
      const dragBoundFunc = (pos) => {
        // TODO: sometimes this sets points to floats or negative
        const imagePos = this.imageNode.absolutePosition();
        const imageAttr = this.imageNode.getAttrs();
        // Get drag bounds with image node
        const minX = imagePos.x;
        const maxX = (imagePos.x + (imageAttr.width * this.stageScale.x));
        const minY = imagePos.y;
        const maxY = (imagePos.y + (imageAttr.height * this.stageScale.y));
        // Override polygon position
        let x = parseInt(pos.x);
        let y = parseInt(pos.y);
        if (minX - pos.x > (this.minXPoint * this.stageScale.x)) {
          x = parseInt(minX - this.minXPoint * this.stageScale.x);
        }
        if (maxX - pos.x < (this.maxXPoint * this.stageScale.x)) {
          x = parseInt(maxX - (this.maxXPoint * this.stageScale.x));
        }
        if (minY - pos.y > (this.minYPoint * this.stageScale.y)) {
          y = parseInt(minY - this.minYPoint * this.stageScale.y);
        }
        if (maxY - pos.y < (this.maxYPoint * this.stageScale.y)) {
          y = parseInt(maxY - (this.maxYPoint * this.stageScale.y));
        }
        return {
          x,
          y,
        };
      };
      return dragBoundFunc;
    },
  },
  watch: {
    getNodeDragBoundFunc: {
      immediate: true,
      handler(newFunc) {
        this.nodeDragBoundFunc = newFunc;
      },
    },
    getPolygonDragBoundFunc: {
      immediate: true,
      handler(newFunc) {
        this.polygonDragBoundFunc = newFunc;
      },
    },
    polygon: {
      deep: true,
      immediate: true,
      handler() {
        this.internalPolygon = this.polygon;
      },
    },
  },
  methods: {
    updatePoints(points) {
      const polygon = JSON.stringify([[this.convertPixelArrayToRatioArray(points)]]);
      this.$emit('update:polygon', polygon);
      this.$emit('transformed');
    },
    updatePointsV2(points, index) {
      const parsedInteralPolygon = JSON.parse(this.internalPolygon);
      parsedInteralPolygon[index] = this.convertPixelArrayToRatioArrayV2(points);
      this.internalPolygon = JSON.stringify(parsedInteralPolygon);
      this.$emit('update:polygon', this.internalPolygon);
      this.$emit('transformed');
    },
    convertPixelArrayToRatioArray(pixelArray) {
      const ratioArray = new Array(pixelArray.length);
      for (let i = 0; i < ratioArray.length; i += 1) {
        if (i % 2 === 0) {
          ratioArray[i] = pixelArray[i] / this.imageDimensions.width;
        } else {
          ratioArray[i] = pixelArray[i] / this.imageDimensions.height;
        }
      }
      return ratioArray;
    },
    convertPixelArrayToRatioArrayV2(pixelArray) {
      const ratioArray = [];
      for (let i = 0; i < pixelArray.length; i += 2) {
        ratioArray.push([pixelArray[i] / this.imageDimensions.width, pixelArray[i + 1] / this.imageDimensions.height]);
      }
      return ratioArray;
    },
    convertRatioArrayToPixelArray(ratioArray) {
      if (!this.imageDimensions || !ratioArray) return null;

      const pixelArray = new Array(ratioArray.length);
      for (let i = 0; i < ratioArray.length; i += 1) {
        if (i % 2 === 0) {
          pixelArray[i] = ratioArray[i] * this.imageDimensions.width;
        } else {
          pixelArray[i] = ratioArray[i] * this.imageDimensions.height;
        }
      }
      return pixelArray;
    },
    convertRatioArrayToPixelArrayV2(ratioArray2D) {
      if (!this.imageDimensions || !ratioArray2D) return null;
      const pixelArray2D = new Array(ratioArray2D.length);
      for (let i = 0; i < pixelArray2D.length; i += 1) {
        const flatten = ratioArray2D[i].flat();
        const pixelArray = new Array(flatten.length);
        for (let j = 0; j < flatten.length; j += 1) {
          if (j % 2 === 0) {
            pixelArray[j] = flatten[j] * this.imageDimensions.width;
          } else {
            pixelArray[j] = flatten[j] * this.imageDimensions.height;
          }
        }
        pixelArray2D[i] = pixelArray;
      }
      return pixelArray2D;
    },
    handleMouseover(e) {
      if (this.isAnnotating) { this.$emit('polygon-mouseover-while-annotating', e); return; }
      this.isMouseOver = true;

      if (!this.isSelected) {
        this.stageNode.container().style.cursor = "pointer";
      } else {
        if (!this.editingEnabled) {
          this.stageNode.container().style.cursor = "pointer";
        }
        // else {
        //   this.stageNode.container().style.cursor = "pointer";
        // }
      }
    },
    handleMouseout(e) {
      if (this.isAnnotating) { this.$emit('polygon-mouseout-while-annotating', e); return; }
      this.isMouseOver = false;
      this.stageNode.container().style.cursor = null;
    },
    handleNodeMouseover() {
      this.stageNode.container().style.cursor = "move";
    },
    handleNodeMouseout() {
      this.stageNode.container().style.cursor = null;
    },
    handleShapeClick(e) {
      if (this.isAnnotating) { this.$emit('polygon-clicked-while-annotating', e); return; }
      e.cancelBubble = true;
      this.$emit('shape-selected');
    },
  },
};
</script>

<style>

</style>
