<template>
  <div class="app-page">
    <Header ref="header">
      <template #header-slot>
        <BreadcrumbNavigation />
      </template>
    </Header>
    <div class="main-page-div">
      <main class="main-display-area">
        <CloudInstanceFilters
          class="filters"
          :instanceCount="instanceCount"
          :selectedInstances="selectedInstances"
          @start-clicked="handleStartClicked"
          @stop-clicked="openConfirmDeleteImageModal"
          @refresh-clicked="handleRefresh"
        />
        <CloudInstancesList
          ref="cloudInstanceList"
          class="table"
          @update:total-instance-count="handleUpdateInstanceCount"
          @update:selected-instances="handleSelectedInstances"
        />
      </main>
    </div>
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
  <NewCloudInstanceModal
    ref="createInstanceModal"
    @instance-created="handleRefresh"
  />
</template>

<script setup>
import Header from '@/components/Header.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import BreadcrumbNavigation from '@/components/BreadcrumbNavigation.vue';
import CloudInstancesList from '@/components/CloudInstances/CloudInstancesList.vue';
import CloudInstanceFilters from '@/components/CloudInstances/CloudInstanceFilters.vue';
import NewCloudInstanceModal from '@/components/CloudInstances/NewCloudInstanceModal.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { ref } from 'vue';

const dataConnect = new DatastoreConnect();
const instanceCount = ref(0);
const selectedInstances = ref([]);
const confirmMessage = ref('');
const confirmMessageHeader = ref('');
const buttonText = ref('');
const confirmFunction = ref(null);
const confirmModal = ref(null);
const cloudInstanceList = ref(null);
const createInstanceModal = ref(null);

function handleUpdateInstanceCount(count) {
  instanceCount.value = count;
}

function handleSelectedInstances(instances) {
  selectedInstances.value = instances;
}

function openConfirmDeleteImageModal() {
  confirmMessage.value = `This action will permanently stop the selected servers. Are you sure you want to terminate ${selectedInstances.value.length} server(s)?`;
  confirmMessageHeader.value = `Terminate Servers`;
  buttonText.value = 'Delete';
  confirmModal.value.showModal();
  confirmFunction.value = () => deleteImageFromDataset();
}
async function deleteImageFromDataset() {
  const selected_ids = selectedInstances.value.map((e) => e.id);
  const params = {
    cloud_instance_ids: selected_ids,
  };
  dataConnect.stopMultipleCloudInstance(params)
    .then((data) => {
      if (!data || data.error || !data.result) {
        alert("Instance failed to terminate");
      } else {
        cloudInstanceList.value.getInstances();
      }
    });
}

function handleRefresh() {
  cloudInstanceList.value.getInstances();
}

function handleStartClicked() {
  createInstanceModal.value.showModal();
}

</script>

<style lang="scss" scoped>
.filters {
  margin: 10px 20px;

}
</style>
