<template>
  <v-rect
    ref="samSelectBox"
    :config="{
      fill: 'transparent',
      stroke: 'white',
      strokeWidth: strokeWidth,
      visible: false
    }"
  />
</template>

<script setup>
import {
  ref, computed, watch, onMounted, onUnmounted,
} from 'vue';
import { debounce } from '@/assets/js/utils.js';
import useSAMCanvas from '@/composables/canvas/useSAMCanvas.js';
import { useSAM2Store } from '@/stores/useSAM2Store.js';
import { storeToRefs } from 'pinia';

// Props
const props = defineProps({
  stageScale: {
    type: Object,
    default: () => ({ x: 1, y: 1 }),
  },
  stageNode: {
    type: Object,
    default: () => {},
  },
  imageNode: {
    type: Object,
    default: () => {},
  },
  imageId: {
    type: Number,
    default: null,
  },
});

// Composables
const { SAMEventBus } = useSAMCanvas();
const sam2Store = useSAM2Store();
const {
  sam2Options,
  selectedObject,
} = storeToRefs(sam2Store);

// Refs
const samSelectBox = ref(null);
const samSelectBoxNode = ref(null);

// Computed properties
const strokeWidth = computed(() => 2 / props.stageScale.x);

const boxDimensions = computed(() => {
  if (samSelectBoxNode.value && samSelectBoxNode.value.getAttrs().visible) {
    return {
      x1: samSelectBoxNode.value.x(),
      y1: samSelectBoxNode.value.y(),
      x2: samSelectBoxNode.value.x() + samSelectBoxNode.value.width(),
      y2: samSelectBoxNode.value.y() + samSelectBoxNode.value.height(),
    };
  }
  return null;
});

const boxObjDimensions = computed(() => sam2Options.value.params.box);
watch(boxObjDimensions, () => {
  if (boxObjDimensions.value.length === 0) {
    const selectionRectangle = samSelectBoxNode.value;
    selectionRectangle.visible(false);
  }
});

// Lifecycle hooks
onMounted(() => {
  SAMEventBus.value.on(SAMEventListener);
  samSelectBoxNode.value = samSelectBox.value.getNode();
  setUpStageMouseEvents();
});

onUnmounted(() => {
  const stage = props.stageNode;
  stage.off('mousedown touchstart');
  stage.off('mousemove touchmove');
  stage.off('mouseup touchend');
});

// Methods
function SAMEventListener(event) {
  if (event === 'reset') {
    reset();
  }
}

function handleBoxDimensionsChanged() {
  if (boxDimensions.value) {
    sam2Options.value.params.box = [
      boxDimensions.value.x1,
      boxDimensions.value.y1,
      boxDimensions.value.x2,
      boxDimensions.value.y2,
    ];

    sam2Options.value.params.boxes = sam2Options.value.params.boxes.filter((box) => box.obj_id !== selectedObject.value);
    sam2Options.value.params.boxes.push({
      obj_id: selectedObject.value,
      imageId: props.imageId,
      box: [
        boxDimensions.value.x1,
        boxDimensions.value.y1,
        boxDimensions.value.x2,
        boxDimensions.value.y2,
      ],
    });
  }
}

function setUpStageMouseEvents() {
  const stage = props.stageNode;
  const konvaImage = props.imageNode;
  const selectionRectangle = samSelectBoxNode.value;
  let mousedown = false;
  let x1; let y1; let x2; let
    y2;

  stage.on('mousedown touchstart', (e) => {
    if (e.evt.button === 0 && !e.evt.ctrlKey) {
      props.stageNode.setAttr('draggable', false);
    } else {
      return;
    }

    e.evt.preventDefault();
    mousedown = true;
    x1 = stage.getRelativePointerPosition().x;
    y1 = stage.getRelativePointerPosition().y;
    x2 = x1;
    y2 = y1;

    selectionRectangle.visible(true);
    selectionRectangle.width(0);
    selectionRectangle.height(0);
  });

  stage.on('mousemove touchmove', (e) => {
    if (!mousedown) return;

    e.evt.preventDefault();
    x2 = stage.getRelativePointerPosition().x;
    y2 = stage.getRelativePointerPosition().y;
    selectionRectangle.setAttrs({
      x: Math.min(x1, x2),
      y: Math.min(y1, y2),
      width: Math.abs(x2 - x1),
      height: Math.abs(y2 - y1),
    });
  });

  stage.on('mouseup touchend', () => {
    mousedown = false;
    props.stageNode.setAttr('draggable', true);

    handleBoxDimensionsChanged();
  });
}

function reset() {
  if (samSelectBoxNode.value) {
    samSelectBoxNode.value.visible(false);
    samSelectBoxNode.value.width(0);
    samSelectBoxNode.value.height(0);
  }
}
</script>

<style>

</style>
