<template>
  <v-circle
    v-for="(c, index) in clicks"
    :key="index"
    :config="{
      x: c.x,
      y: c.y,
      radius: nodeRadius,
      fill: getNodeFill(c),
      stroke: nodeStroke,
      strokeWidth: nodeStrokeWidth,
      draggable: false,
    }"
    :opacity="1"
  />
</template>

<script>

export default {
  name: 'SAMClickPoint',
  props: {
    clicks: {
      type: Array,
      default: () => [],
    },
    stageScale: {
      type: Object,
      default: () => ({ x: 1, y: 1 }),
    },
  },
  emits: [],
  data() {
    return {
    };
  },
  computed: {
    nodeRadius() {
      return 5 / this.stageScale.x;
    },
    nodeStroke() {
      return 'blue';
    },
    nodeStrokeWidth() {
      return 1 / this.stageScale.x;
    },
  },
  methods: {
    getNodeFill(click) {
      if (click.clickType === 0) {
        return '#FF1744';
      }
      return '#00E676';
    },
  },
};
</script>

<style>

</style>
